import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { useAnalytics } from '../../analytics';
import { Role } from '../../constants/auth';
import { ANOMALIES_KEY } from '../../hooks/anomalies';
import { useDeleteData } from '../../hooks/batch';
import { FOLDER_KEY, useFlatFolders, FolderSort } from '../../hooks/folders';
import { TAG_DATA_KEY } from '../../hooks/tags';
import { AuthRender } from '../auth/auth-render';
import { DeleteDataModal, FooterInfo } from '../delete-data-modal';

import { Button, Modal, ModalContents, ModalOpenButton } from '@controlrooms/components';
import { ConfirmationBannerType } from '@controlrooms/components/src/modal/modal';
import { DateFormats, Fields, TimeRanges } from '@controlrooms/models';

const MyUploadsContainer = styled.div`
  width: 100%;
  display: flex;

  .delete-data {
    margin-top: 30px;
    margin-left: auto;
  }
`;

export const MyUploads: React.FC<{
  onDeleteStatusChange: (status: ConfirmationBannerType | null) => void;
}> = ({ onDeleteStatusChange }) => {
  const { track } = useAnalytics();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      folder: 'all',
      type: undefined,
      [Fields.TIMERANGE]: TimeRanges.CUSTOM,
      [Fields.CUSTOM_TIMEFRAME]: '',
    },
  });

  const { mutateAsync: deleteData } = useDeleteData();
  const queryClient = useQueryClient();

  const { data: folders } = useFlatFolders(FolderSort.ALPHA_ASC_DESCRIPTION);

  const onConfirm = () => {
    const errors = methods.formState.errors;
    if (Object.values(errors).length) return false;

    if (methods.getValues('type') === undefined) {
      methods.setError('type', { type: 'required' });
      return false;
    }

    if (methods.getValues('type') === 'timeframe') {
      const _timeframe = methods.getValues(Fields.CUSTOM_TIMEFRAME);

      if (!_timeframe || _timeframe.length === 0) {
        methods.setError(Fields.CUSTOM_TIMEFRAME, { type: 'validate' });
        return false;
      }
      const _tokens = _timeframe.split(' - ');
      if (!_timeframe.match(/^[^a-zA-Z]+$/) || _tokens.length !== 2) {
        methods.setError(Fields.CUSTOM_TIMEFRAME, { type: 'validate' });
        return false;
      }

      const _start = dayjs(_tokens[0], DateFormats.DATETIME_ALT);
      if (!_start.isValid()) {
        methods.setError(Fields.CUSTOM_TIMEFRAME, {
          type: 'custom',
          message: 'Invalid start time',
        });
        return false;
      }

      const _end = dayjs(_tokens[1], DateFormats.DATETIME_ALT);
      if (!_end.isValid()) {
        methods.setError(Fields.CUSTOM_TIMEFRAME, {
          type: 'custom',
          message: 'Invalid end time',
        });
        return false;
      }

      deleteData({
        startTime: _start.toISOString(),
        endTime: _end.toISOString(),
      })
        .then(() => {
          track('Delete Upload Files', {
            deletedFilesStartTime: _start.toISOString(),
            deletedFilesEndTime: _end.toISOString(),
          });
          onDeleteStatusChange(ConfirmationBannerType.SUCCESS);
          queryClient.invalidateQueries(FOLDER_KEY);
          queryClient.invalidateQueries(ANOMALIES_KEY);
          queryClient.invalidateQueries(TAG_DATA_KEY);
        })
        .catch(() => {
          onDeleteStatusChange(ConfirmationBannerType.ERROR);
        });

      return true;
    }

    // all
    deleteData(undefined)
      .then(() => {
        track('Delete Upload Files', {
          deleteAllFiles: 'clicked',
        });
        onDeleteStatusChange(ConfirmationBannerType.SUCCESS);
        queryClient.invalidateQueries(FOLDER_KEY);
        queryClient.invalidateQueries(ANOMALIES_KEY);
        queryClient.invalidateQueries(TAG_DATA_KEY);
      })
      .catch(() => {
        onDeleteStatusChange(ConfirmationBannerType.ERROR);
      });

    return true;
  };

  const [hasFolders, setHasFolders] = useState(false);
  useEffect(() => {
    if (
      !folders?.length ||
      (folders?.length === 1 &&
        folders[0].name.toUpperCase() === 'UNIT' &&
        !folders[0].tags?.length)
    ) {
      setHasFolders(false);
    } else {
      setHasFolders(true);
    }
  }, [folders]);

  return (
    <MyUploadsContainer>
      <AuthRender role={Role.DATA}>
        <FormProvider {...methods}>
          <Modal>
            <ModalOpenButton>
              <Button
                data-testid="select-data-to-delete"
                className="delete-data"
                buttonType="link"
                buttonSize="small"
                onClick={() => {
                  track('Upload Files', {
                    selectDataToDelete: 'clicked',
                  });
                }}
              >
                Select data to delete
              </Button>
            </ModalOpenButton>
            <ModalContents
              title="Delete Data"
              subTitle="Delete systems/folders or specific timeframes"
              footerPrompt={<FooterInfo />}
              confirmButtonText="Delete Selected Data"
              confirmButtonCallback={onConfirm}
              confirmButtonDisabled={!hasFolders}
              dataTestIdCancel="delete-data-close-button"
              styles={{ content: { maxWidth: '480px' } }}
            >
              <FormProvider {...methods}>
                <DeleteDataModal />
              </FormProvider>
            </ModalContents>
          </Modal>
        </FormProvider>
      </AuthRender>
    </MyUploadsContainer>
  );
};
