import { yupResolver } from '@hookform/resolvers/yup';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form';
import { UseFormClearErrors } from 'react-hook-form/dist/types/form';
import { Popover, PopoverAlign, PopoverPosition } from 'react-tiny-popover';
import * as yup from 'yup';

import { Button } from '../button';

import 'react-datepicker/dist/react-datepicker.css';
import './date-time-range-popover.css';

import {
  CalendarErrorText,
  DateTimeRangePickerContainer,
  InputGroup,
  OpenContainer,
  TimeInputContainer,
  TimeRangeButtonGroup,
} from './styles';
import { DateTimeInputMask } from './time-input-mask';

import { FunctionUtils } from '@controlrooms/utils';
import { TimeUtils } from '@controlrooms/utils';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const dateRegx = new RegExp('(0[1-9]|1[0-2])/(0[1-9]|[1-2][0-9]|3[0-1])/[0-9]{4}');

const timeRegx = new RegExp('(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]');

interface ContextProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  initialValues?: {
    start: Dayjs;
    end?: Dayjs;
  };
  onApply: (dates: [Dayjs, Dayjs]) => void;
  minimumDurationSeconds: number;
  positions?: PopoverPosition[];
  align?: PopoverAlign;
}

const defaultState = {
  isOpen: false,
  setIsOpen: (): boolean => false,
  onApply: () => undefined,
  minimumDurationSeconds: 0,
  positions: ['bottom' as PopoverPosition] as PopoverPosition[],
  align: 'center' as PopoverAlign,
};

const DateTimeRangePickerContext = createContext<ContextProps>(defaultState);

interface ChildElement {
  children: JSX.Element;
}

interface DateTimeRangePickerProps extends ChildElement {
  onApply: (dates: [Dayjs, Dayjs]) => void;
  initialValues?: {
    start: Dayjs;
    end: Dayjs;
  };
  minimumDurationSeconds?: number;
  positions?: PopoverPosition[];
  align?: PopoverAlign;
}

const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({
  children,
  onApply,
  minimumDurationSeconds = defaultState.minimumDurationSeconds,
  positions = defaultState.positions,
  align = defaultState.align,
  initialValues,
}) => {
  const opts = useMemo(
    () => ({
      ...defaultState,
      ...(initialValues && { initialValues }),
    }),
    [initialValues],
  );

  const [isOpen, setIsOpen] = useState(opts.isOpen);

  const value = useMemo(
    () => ({
      initialValues: opts.initialValues,
      isOpen,
      minimumDurationSeconds,
      positions,
      align,
      setIsOpen,
      onApply,
    }),
    [opts.initialValues, isOpen, minimumDurationSeconds, positions, align, onApply],
  );
  return (
    <DateTimeRangePickerContext.Provider value={value}>
      <DateTimeRangePickerContent>{children}</DateTimeRangePickerContent>
    </DateTimeRangePickerContext.Provider>
  );
};

interface FormProps {
  datePicker: [Date, Date | null];
  startTime: string;
  startDate: string;
  endTime: string;
  endDate: string;
}

const defaultFormState = {
  datePicker: [new Date(), null],
  startTime: '',
  endTime: '',
  startDate: '',
  endDate: '',
} as FormProps;

const DateTimeRangePickerContent: React.FC<ChildElement> = ({ children }) => {
  const { initialValues, isOpen, setIsOpen, onApply, minimumDurationSeconds, positions, align } =
    useContext(DateTimeRangePickerContext);

  const defaultValues = useMemo(() => {
    if (initialValues) {
      return {
        datePicker: [initialValues.start.toDate(), initialValues.end?.toDate() ?? null],
        startDate: initialValues.start.format('MM/DD/YYYY'),
        endDate: initialValues.end?.format('MM/DD/YYYY') ?? '',
        startTime: initialValues.start.format('HH:mm:ss'),
        endTime: initialValues.end?.format('HH:mm:ss') ?? '',
      } as FormProps;
    }
    return defaultFormState;
  }, [initialValues]);

  const getSchema = (clearErrors: UseFormClearErrors<FormProps> | null) =>
    yup.object().shape({
      datePicker: yup.array().of(yup.date().required('Start and End dates are required')),
      startDate: yup
        .string()
        .required('Start date is required')
        .matches(dateRegx, 'Start time is invalid'),
      startTime: yup
        .string()
        .required('Start time is required')
        .matches(timeRegx, 'Start time is invalid'),
      endDate: yup
        .string()
        .required('End date is required')
        .matches(dateRegx, 'End time is invalid')
        .test('is-greater', 'Must be after start time', function () {
          const { datePicker, startDate, startTime, endDate, endTime } = this.parent;
          if (dayjs(`${startDate}`, 'MM/DD/YYYY').isAfter(dayjs(`${endDate}`, 'MM/DD/YYYY'))) {
            return false;
          }
          if (
            datePicker.filter(Boolean).length !== 2 ||
            datePicker[0].toDateString() !== datePicker[1].toDateString()
          ) {
            clearErrors && clearErrors(this.path as 'datePicker' | 'startDate' | 'endDate');
            return true;
          }
          if (
            dayjs(`${endDate} ${endTime}`, 'MM/DD/YYYY HH:mm:ss').isAfter(
              dayjs(`${startDate} ${startTime}`, 'MM/DD/YYYY HH:mm:ss'),
            )
          ) {
            clearErrors && clearErrors(this.path as 'datePicker' | 'startDate' | 'endDate');
            return true;
          }
          return false;
        }),
      endTime: yup
        .string()
        .required('End time is required')
        .matches(timeRegx, 'End time is invalid')
        .test(
          'duration',
          `Time range must be > ${
            minimumDurationSeconds === 1
              ? `${minimumDurationSeconds} second`
              : dayjs.duration(minimumDurationSeconds, 'seconds').humanize()
          }`,
          function () {
            if (minimumDurationSeconds < 1) {
              clearErrors && clearErrors(this.path as 'datePicker' | 'startDate' | 'endDate');
              return true;
            }
            const { datePicker, startTime, endTime } = this.parent;
            if (
              datePicker.filter(Boolean).length !== 2 ||
              datePicker[0].toDateString() !== datePicker[1].toDateString()
            ) {
              clearErrors && clearErrors(this.path as 'datePicker' | 'startDate' | 'endDate');
              return true;
            }
            if (
              TimeUtils.millisecondsToSeconds(
                dayjs(`${endTime}`, 'HH:mm:ss').diff(dayjs(`${startTime}`, 'HH:mm:ss')),
              ) >= minimumDurationSeconds
            ) {
              clearErrors && clearErrors(this.path as 'datePicker' | 'startDate' | 'endDate');
              return true;
            }
            return false;
          },
        ),
    });

  // Pass a clear errors ref as a work-around to clear the end validation on change
  const clearErrorsRef = useRef<UseFormClearErrors<FormProps> | null>(null);

  const methods = useForm<FormProps>({
    mode: 'onBlur',
    resolver: yupResolver(getSchema(clearErrorsRef.current)),
    defaultValues,
  });

  useEffect(() => {
    clearErrorsRef.current = methods.clearErrors;
  }, [methods.clearErrors]);

  const onSubmit = ({ datePicker, startTime, endTime }: FormProps) => {
    const _startTimeTokens = startTime.split(/[/\s:]+/);
    const _endTimeTokens = endTime.split(/[/\s:]+/);

    const _start = dayjs(datePicker[0])
      .set('hours', Number(_startTimeTokens[0]))
      .set('minutes', Number(_startTimeTokens[1]))
      .set('seconds', Number(_startTimeTokens[2]))
      .set('milliseconds', 0);
    const _end = dayjs(datePicker[1])
      .set('hours', Number(_endTimeTokens[0]))
      .set('minutes', Number(_endTimeTokens[1]))
      .set('seconds', Number(_endTimeTokens[2]))
      .set('milliseconds', 0);
    setIsOpen(false);
    onApply([_start, _end]);
  };

  return (
    <Popover
      containerClassName="date-time-range-popover"
      isOpen={isOpen}
      positions={positions}
      align={align}
      padding={-20}
      content={
        <DateTimeRangePickerContainer>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Controller
                name={'datePicker'}
                control={methods.control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <DatePicker
                      selected={value[0]}
                      onBlur={onBlur}
                      onChange={(dates) => {
                        if (
                          dayjs(dates[0]).format('MM/DD/YYYY') ===
                          dayjs(dates[1]).format('MM/DD/YYYY')
                        ) {
                          methods.setValue('startTime', '00:00:00', { shouldValidate: true });
                          methods.setValue('endTime', '23:59:59', { shouldValidate: true });
                        }
                        onChange(dates);
                      }}
                      startDate={value[0]}
                      endDate={value[1]}
                      selectsRange
                      inline
                    />
                    <CalendarErrorText>
                      {(
                        methods.getFieldState('datePicker')?.error as unknown as
                          | FieldError[]
                          | undefined
                      )?.filter(Boolean).length && 'Select an end date'}
                    </CalendarErrorText>
                    <TimeInputContainer>
                      <InputGroup>
                        <DateTimeInputMask
                          dataTestId="start-date"
                          name="startDate"
                          label="Start Date"
                          dateString={dayjs(value[0]).format('MM/DD/YYYY')}
                          type="date"
                          onChange={(newVal) => {
                            onChange([new Date(dayjs(newVal, 'MM/DD/YYYY')), value[1]]);
                          }}
                        />
                        <DateTimeInputMask
                          dataTestId="start-time"
                          name="startTime"
                          label="Start Time"
                          dateString={dayjs(value[0]).format('HH:mm:ss')}
                          type="time"
                        />
                      </InputGroup>
                      <InputGroup>
                        <DateTimeInputMask
                          dataTestId="end-date"
                          name="endDate"
                          label="End Date"
                          dateString={dayjs(value[1]).format('MM/DD/YYYY')}
                          type="date"
                          onChange={(newVal) => {
                            onChange([value[0], new Date(dayjs(newVal, 'MM/DD/YYYY'))]);
                          }}
                        />
                        <DateTimeInputMask
                          dataTestId="end-time"
                          name="endTime"
                          label="End Time"
                          dateString={dayjs(value[1]).format('HH:mm:ss')}
                          type="time"
                        />
                      </InputGroup>
                    </TimeInputContainer>
                  </>
                )}
              />
              <TimeRangeButtonGroup>
                <Button
                  data-testid="cancel-calendar-button"
                  id="cancel-btn"
                  buttonSize="large"
                  buttonType="secondary"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="update-calendar-button"
                  buttonSize="large"
                  buttonType="primary"
                  id="update-btn"
                  type="submit"
                >
                  Update
                </Button>
              </TimeRangeButtonGroup>
            </form>
          </FormProvider>
        </DateTimeRangePickerContainer>
      }
      onClickOutside={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpen(false);
      }}
    >
      <DateTimeRangePickerOpenButton>{children}</DateTimeRangePickerOpenButton>
    </Popover>
  );
};

const DateTimeRangePickerOpenButton = React.forwardRef<HTMLDivElement, ChildElement>(
  ({ children: child }, ref) => {
    return (
      <OpenContainer ref={ref}>
        <OpenButtonClone>{child}</OpenButtonClone>
      </OpenContainer>
    );
  },
);

const OpenButtonClone: React.FC = ({ children: child }) => {
  const { setIsOpen } = useContext(DateTimeRangePickerContext);
  if (React.isValidElement(child)) {
    return React.cloneElement(child, {
      onClick: FunctionUtils.callAll(() => setIsOpen(true), child.props.onClick),
    });
  }
  return <></>;
};

export default DateTimeRangePicker;
