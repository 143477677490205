import { User } from '@auth0/auth0-react';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { Paths } from '../constants/paths';
import { getAppRouteParams } from '../utils/app';
import { CR_USER_PROP } from '../utils/token';

import { usePrevious } from '@controlrooms/hooks';

interface ContextProps {
  tenant: number;
  updateTenant: (tenantId: number, params?: URLSearchParams, user?: User) => void;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <TenantContextProvider>.');
};

const defaultState = {
  tenant: 0,
  updateTenant: stub,
};

const TenantContext = createContext<ContextProps>(defaultState);

export const useTenant = () => useContext(TenantContext);

const TenantContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const tenantId = getAppRouteParams(location.pathname)?.params?.tenantId;
  const previousTenantId = usePrevious(tenantId);

  const [tenant, setTenant] = useState<number>(tenantId ? Number(tenantId) : defaultState.tenant);

  useEffect(() => {
    if (previousTenantId !== undefined && previousTenantId !== tenantId) {
      sessionStorage.clear();
      queryClient.resetQueries();
    }
  }, [previousTenantId, queryClient, tenantId]);

  useEffect(() => {
    const match = getAppRouteParams(location.pathname);
    if (match) {
      const tenantId = match.params.tenantId as string;
      if (!/\d+/.test(tenantId)) {
        navigate(Paths.NOT_FOUND);
      } else {
        setTenant(Number(tenantId));
      }
    }
  }, [location.pathname, navigate]);

  const updateTenant = useCallback(
    (tenantId: number, params?: URLSearchParams, user?: User) => {
      if (tenant === tenantId) return;
      if (!params) {
        const navPath = user?.[CR_USER_PROP].app_version === 2 ? Paths.INVESTIGATE : Paths.MONITOR;
        navigate(generatePath(navPath, { tenantId: tenantId.toString() }));
      } else {
        const current = getAppRouteParams(location.pathname);
        current &&
          navigate(
            `${generatePath(current.pattern.path, {
              ...current.params,
              tenantId: tenantId.toString(),
            })}${params ? `?${params}` : ''}`,
          );
      }
    },
    [location.pathname, navigate, tenant],
  );

  return (
    <TenantContext.Provider value={{ tenant, updateTenant }}>{children}</TenantContext.Provider>
  );
};

export default TenantContextProvider;
