import styled from 'styled-components';

import { ButtonGroup } from '../button';
import back from '../icon/icons/back.svg';

import { Size, colors, text } from '@controlrooms/design-tokens';

export const DateTimeRangePickerContainer = styled.div`
  background-color: ${({ theme }) => theme.dateTimeRangePicker.backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${({ theme }) => theme.dateTimeRangePicker.boxShadow};
  border-radius: 4px;
  width: 390px;
  @media (max-width: 480px) {
    width: auto;
  }

  .react-datepicker {
    border: none;
    width: 100%;
    color: ${({ theme }) => theme.dateTimeRangePicker.textColor};
    background-color: ${({ theme }) => theme.dateTimeRangePicker.backgroundColor};
  }

  .react-datepicker__day-names {
    display: none;
  }

  .react-datepicker__day {
    color: ${({ theme }) => theme.dateTimeRangePicker.textColor};
    font-size: ${text.size[Size.DATA_11]};
    line-height: 30px;
    width: 36px;
    font-weight: 400;
    border: ${({ theme }) => theme.dateTimeRangePicker.day.border};
    border-radius: 3px;

    &:hover {
      background: ${({ theme }) => theme.dateTimeRangePicker.day.hover.background};
      border: ${({ theme }) => theme.dateTimeRangePicker.day.hover.border};
      box-shadow: ${({ theme }) => theme.dateTimeRangePicker.day.hover.boxShadow};
    }
  }

  .react-datepicker__day--in-selecting-range {
    background: ${({ theme }) => theme.dateTimeRangePicker.day.inRange.background};
    border: ${({ theme }) => theme.dateTimeRangePicker.day.inRange.border};
  }

  .react-datepicker__day--in-range {
    background: ${({ theme }) => theme.dateTimeRangePicker.day.inRange.background};
    border: ${({ theme }) => theme.dateTimeRangePicker.day.inRange.border};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background: ${({ theme }) => theme.dateTimeRangePicker.day.selected.background};
    border: ${({ theme }) => theme.dateTimeRangePicker.day.selected.border};
  }

  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.dateTimeRangePicker.day.outsideMonth.color};
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.dateTimeRangePicker.backgroundColor};
    border-bottom: ${({ theme }) => theme.dateTimeRangePicker.divider.border};
    margin: 0 10px 15px 10px;

    .react-datepicker__current-month {
      font-weight: 600;
      font-size: 13px;
      line-height: 19px;
    }
  }

  .react-datepicker__current-month {
    color: ${({ theme }) => theme.dateTimeRangePicker.header.monthColor};
  }

  .react-datepicker__month-container {
    background-color: ${({ theme }) => theme.dateTimeRangePicker.backgroundColor};
    float: initial;
  }

  // Remove the current arrows
  .react-datepicker__navigation-icon::before {
    border: none;
  }

  .react-datepicker__navigation-icon--previous {
    background: url(${back});
    background-size: 15px;
    width: 15px;
    height: 15px;
    border: none;
  }

  .react-datepicker__navigation-icon--next {
    background: url(${back});
    background-size: 15px;
    transform: rotate(180deg);
    width: 15px;
    height: 15px;
    border: none;
  }
`;

export const CalendarErrorText = styled.span`
  font-size: 11px;
  font-weight: 400;
  color: ${({ theme }) => theme.dateTimeRangePicker.error.color};
  margin: 0 10px;
  position: relative;
  top: -10px;
`;

export const TimeInputContainer = styled.div`
  border-bottom: ${({ theme }) => theme.dateTimeRangePicker.divider.border};
  display: flex;
  padding: 0 12px 16px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  > div:first-of-type {
    margin-right: 4px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  .date-input-mask,
  .time-input-mask {
    .label {
      color: ${colors.content['neutral-3']};
      font-family: Open Sans;
      font-size: 11px;
      font-style: normal;
      font-weight: 300;
      line-height: 13px;
    }
  }
`;

export const TimeRangeButtonGroup = styled(ButtonGroup)`
  width: calc(100% - 20px);
  justify-content: space-between;
  margin: 10px;
`;

export const OpenContainer = styled.div`
  display: flex;
  align-items: center;
`;
