import Request from './request';

import { ApiResult, CRChannels, TokenRequest } from '@controlrooms/models';

const SERVICE_ROOT = '/notification-manager';

export const getChannels = (): Promise<ApiResult<CRChannels>> => {
  return Request.get(`${SERVICE_ROOT}/notification/details`);
};

export const getAblyToken = (): Promise<ApiResult<TokenRequest>> => {
  return Request.get(
    `${process.env.REACT_APP_CONTROLROOMS_API}/notification-manager/notification/auth`,
  );
};
