import * as d3 from 'd3';
import dayjs from 'dayjs';

import { CRTagMinMaxTimeSeries, Line, SVGDefsSelection, TimeSeries } from '@controlrooms/models';

export const buildSpline = (
  chart: SVGDefsSelection,
  xScale: d3.ScaleTime<number, number, never>,
  yScale: d3.ScaleLinear<number, number, never>,
  seriesData: TimeSeries[] | CRTagMinMaxTimeSeries[] | undefined,
  // theme: DefaultTheme,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any, // TODO: Export styled components from shared package
) => {
  if (seriesData?.length === 1) {
    // Add single circle to chart
    const circleGenerator = chart
      .selectAll('.path')
      .data(seriesData as TimeSeries[])
      .enter()
      .append('g');
    circleGenerator
      .append('circle')
      .attr('r', 4)
      .attr('fill', theme.chart.highlightColor)
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .attr('cx', (d) => {
        return xScale(d.time);
      })
      .attr('cy', (d) => {
        return yScale(d.value);
      });
  } else {
    const lineGenerator: Line<TimeSeries> = d3
      .line<TimeSeries>()
      .defined((d) => d.value !== null && typeof d.value === 'number')
      .x((d) => {
        return xScale(dayjs(d.time).valueOf());
      })
      .y((d) => {
        return yScale(d.value);
      });

    // Add spline line to chart
    chart
      .append('path')
      .datum(seriesData)
      .attr('fill', 'none')
      .attr('stroke', theme.chart.lineColor)
      .attr('stroke-width', 1)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .attr('d', lineGenerator as any);
  }
};
