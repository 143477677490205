/*eslint-disable */
import { AnalyticsBrowser } from '@segment/analytics-next';
/*eslint-enable */
import React, { createContext, ReactNode, useContext } from 'react';
import { useLocation } from 'react-router-dom';

//Define analytics platform used
const segmentAnalytics = new AnalyticsBrowser();

//Define Types
interface AnalyticsProps {
  isAuthenticated?: boolean;
  platform: 'segment';
  writeKey: string;
  dataPlane?: string;
  userIdentification?: {
    userId?: number | string;
    userEmail?: string;
    userName?: string;
    userFirstName?: string;
    userLastName?: string;
    userLocale?: string;
  };
  userTheme?: string;
  children: ReactNode;
}
interface AnalyticsContextProps {
  analytics: typeof segmentAnalytics;
  // eslint-disable-next-line
  track: (eventName: string, options?: Record<string, any>, ...rest: any) => void;
  page: (path: string) => void;
}

//Create Analytics Context
export const AnalyticsContext = createContext<AnalyticsContextProps | undefined>(undefined);

//Create Analytics hook
export const useAnalytics = (): AnalyticsContextProps => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsContextProvider');
  }
  return context;
};

//Create hook to track page views
const usePageViews = (analytics: typeof segmentAnalytics) => {
  const location = useLocation();
  React.useEffect(() => {
    analytics.page(location.pathname);
  }, [analytics, location]);
};

const AnalyticsContextProvider = ({
  isAuthenticated,
  writeKey,
  dataPlane,
  platform,
  userIdentification,
  userTheme,
  children,
}: AnalyticsProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let analyticsUsed: any;

  if (platform === 'segment') analyticsUsed = segmentAnalytics;

  usePageViews(analyticsUsed);

  React.useMemo(() => {
    writeKey ? analyticsUsed.load({ writeKey: writeKey, dataPlane: dataPlane }) : analyticsUsed;
  }, [analyticsUsed, writeKey, dataPlane]);

  React.useEffect(() => {
    if (!platform) return;

    if (userIdentification && isAuthenticated && process.env.NODE_ENV !== 'development') {
      analyticsUsed.identify(userIdentification.userId, {
        email: userIdentification?.userEmail,
        name: userIdentification?.userName,
        firstName: userIdentification?.userFirstName,
        lastName: userIdentification?.userLastName,
        locale: userIdentification?.userLocale,
        mode: userTheme,
      });
    } else {
      analyticsUsed.setAnonymousId();
    }
  }, [analyticsUsed, isAuthenticated, platform, userIdentification, userTheme]);

  // eslint-disable-next-line
  const trackCall = (eventName: string, options: Record<string, any> = {}, ...rest: any) => {
    analyticsUsed.track(eventName, options, ...rest);
  };

  const pageCall = (path: string) => {
    analyticsUsed.page(path);
  };

  return (
    <AnalyticsContext.Provider
      value={{ analytics: analyticsUsed, track: trackCall, page: pageCall }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContextProvider;
