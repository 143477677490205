import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Popover, PopoverAlign, PopoverPosition } from 'react-tiny-popover';

import 'react-datepicker/dist/react-datepicker.css';
import './date-time-range-popover.css';

import { Button } from '../button';
import { TextInput } from '../text-input/text-input';

import {
  CalendarErrorText,
  DateTimeHeading,
  DateTimeRangePickerContainer,
  InputGroup,
  OpenContainer,
  TimeInputContainer,
  TimeRangeButtonGroup,
} from './styles';

import { FunctionUtils, isTimeAllowed, TimeUtils } from '@controlrooms/utils';
import { useTenants } from '@controlrooms/web/src/app/hooks/tenants';

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface ContextProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  initialValue?: Dayjs;
  onApply: (dates: Dayjs) => void;
  minimumDurationSeconds: number;
  positions?: PopoverPosition[];
  align?: PopoverAlign;
  dateValue?: Dayjs;
  heading?: string;
  startDateTime?: string;
  endDateTime?: string;
}

const defaultState = {
  isOpen: false,
  setIsOpen: (): boolean => false,
  onApply: () => undefined,
  minimumDurationSeconds: 0,
  positions: ['bottom' as PopoverPosition] as PopoverPosition[],
  align: 'center' as PopoverAlign,
};

const DateTimePickerContext = createContext<ContextProps>(defaultState);

interface ChildElement {
  children: JSX.Element;
}

interface DateTimePickerProps extends ChildElement {
  onApply: (dates: Dayjs) => void;
  initialValue?: Dayjs;
  minimumDurationSeconds?: number;
  positions?: PopoverPosition[];
  align?: PopoverAlign;
  dateValue?: Dayjs;
  heading?: string;
  startDateTime?: string;
  endDateTime?: string;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  children,
  onApply,
  minimumDurationSeconds = defaultState.minimumDurationSeconds,
  positions = defaultState.positions,
  align = defaultState.align,
  initialValue,
  dateValue,
  heading,
  startDateTime,
  endDateTime,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const value = useMemo(
    () => ({
      initialValue,
      isOpen,
      minimumDurationSeconds,
      positions,
      align,
      setIsOpen,
      onApply,
      dateValue,
      heading,
      startDateTime,
      endDateTime,
    }),
    [
      initialValue,
      isOpen,
      minimumDurationSeconds,
      positions,
      align,
      onApply,
      dateValue,
      heading,
      startDateTime,
      endDateTime,
    ],
  );
  return (
    <DateTimePickerContext.Provider value={value}>
      <DateTimeRangePickerContent>{children}</DateTimeRangePickerContent>
    </DateTimePickerContext.Provider>
  );
};

const DateTimeRangePickerContent: React.FC<ChildElement> = ({ children }) => {
  const { isOpen, setIsOpen, onApply, positions, align, dateValue, heading } =
    useContext(DateTimePickerContext);

  const methods = useFormContext();
  const { currentTenant } = useTenants();
  const plantTimezone = currentTenant?.timezone;
  const plantTzAbbr = useMemo(
    () => plantTimezone && TimeUtils.zoneFormat(plantTimezone, 'z'),
    [plantTimezone],
  );

  const updateDateandTime = () => {
    methods.clearErrors('stateError');
    if (methods.getValues(`${heading}_time_text`) === undefined) {
      methods.setValue(
        `${heading}_time_text`,
        `${dayjs(
          methods.getValues(`${heading === 'Start' ? 'EffectiveStart' : 'EffectiveEnd'}`),
          'MMM D, YYYY HH:mm',
        ).format('HH:mm:ss')}`,
      );
    }
    const dateTimeString = `${dayjs(methods.getValues(`${heading}_date_text`), 'MM-DD-YYYY').format(
      'MM-DD-YYYY',
    )} ${dayjs(methods.getValues(`${heading}_time_text`), 'HH:mm:ss').format('HH:mm:ss')}`;
    isValidDateAndTime(dateTimeString);
    const hasErrors = Object.keys(methods.formState.errors).length > 0;
    if (!hasErrors) {
      methods.setValue(`${heading}-date`, dayjs(dateTimeString, 'MM-DD-YYYY HH:mm:ss'));
      heading === 'Start'
        ? methods.setValue('EffectiveStart', dayjs(dateTimeString).format('MMM D, YYYY HH:mm'))
        : methods.setValue('EffectiveEnd', dayjs(dateTimeString).format('MMM D, YYYY HH:mm'));
      setIsOpen(false);
    }
  };

  const isValidDateAndTime = (dateTimeString: string) => {
    const providedDate = dayjs(dateTimeString, 'MM-DD-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    const effectiveStartDate = dayjs(
      methods.getValues('EffectiveStart'),
      'MMM D, YYYY HH:mm',
    ).format('YYYY-MM-DD HH:mm:ss');
    const effectiveEndDate = dayjs(methods.getValues('EffectiveEnd'), 'MMM D, YYYY HH:mm').format(
      'YYYY-MM-DD HH:mm:ss',
    );
    if (
      heading === 'Start' &&
      dayjs(providedDate, 'YYYY-MM-DD HH:mm:ss').isAfter(
        dayjs(effectiveEndDate, 'YYYY-MM-DD HH:mm:ss'),
      )
    ) {
      methods.setError(`Start_date_text`, {
        type: 'custom',
        message: 'Start date and time must be less than end date and time',
      });
    } else {
      methods.clearErrors(`Start_date_text`);
    }
    if (
      heading === 'End' &&
      dayjs(providedDate, 'YYYY-MM-DD HH:mm:ss').isBefore(
        dayjs(effectiveStartDate, 'YYYY-MM-DD HH:mm:ss'),
      )
    ) {
      methods.setError(`End_date_text`, {
        type: 'custom',
        message: 'End date and time must be less than start date and time',
      });
    } else {
      methods.clearErrors(`End_date_text`);
    }
    if (!dayjs(methods.getValues(`${heading}_date_text`), 'MM-DD-YYYY', true).isValid()) {
      methods.setError(`Invalid_date_text`, {
        type: 'custom',
        message: 'Invalid date',
      });
    } else {
      methods.clearErrors(`Invalid_date_text`);
    }
    if (!dayjs(methods.getValues(`${heading}_time_text`), 'HH:mm:ss').isValid()) {
      methods.setError(`${heading}_time_text`, {
        type: 'custom',
        message: 'Invalid time',
      });
    } else {
      methods.clearErrors(`${heading}_time_text`);
    }
  };

  return (
    <Popover
      containerClassName="date-time-range-popover"
      containerStyle={{ position: 'fixed', top: '40%', left: '40%' }}
      isOpen={isOpen}
      positions={positions}
      align={align}
      padding={-20}
      content={
        <DateTimeRangePickerContainer>
          <DatePicker
            selected={
              heading === 'Start'
                ? dayjs(methods.getValues('EffectiveStart'), 'MMM D, YYYY HH:mm').toDate() ||
                  dateValue.toDate()
                : dayjs(methods.getValues('EffectiveEnd'), 'MMM D, YYYY HH:mm').toDate() ||
                  dateValue.toDate()
            }
            {...methods.register(`${heading}-date`)}
            onChange={(dates) => {
              onApply(dayjs(dates));
              methods.setValue(`${heading}_date_text`, dayjs(dates).format('MM-DD-YYYY'));
            }}
            inline
          />
          <CalendarErrorText>
            {/* {(
              methods.getFieldState('datePicker')?.error as unknown as FieldError[] | undefined
            )?.filter(Boolean).length && 'Select an end date'} */}
          </CalendarErrorText>
          <DateTimeHeading className="date-heading">{`${heading} Day & Time`}</DateTimeHeading>
          <TimeInputContainer>
            <InputGroup>
              <TextInput
                placeholder="MM-DD-YYYY"
                defaultValue={
                  heading === 'Start'
                    ? dayjs(methods.getValues('EffectiveStart'), 'MMM D, YYYY HH:mm').format(
                        'MM-DD-YYYY',
                      )
                    : dayjs(methods.getValues('EffectiveEnd'), 'MMM D, YYYY HH:mm').format(
                        'MM-DD-YYYY',
                      )
                }
                {...methods.register(`${heading}_date_text`)}
                errorMessage={
                  methods.formState.errors?.Start_date_text?.message ||
                  methods.formState.errors?.End_date_text?.message ||
                  methods.formState.errors?.Invalid_date_text?.message ||
                  ''
                }
              ></TextInput>
              <Controller
                render={({ field }) => (
                  <NumberFormat
                    data-testid={'eff-start-date'}
                    className="time-input-mask"
                    defaultValue={`${
                      heading === 'Start'
                        ? dayjs(methods.getValues('EffectiveStart'), 'MMM D, YYYY HH:mm').format(
                            'HH:mm:ss',
                          )
                        : dayjs(methods.getValues('EffectiveEnd'), 'MMM D, YYYY HH:mm').format(
                            'HH:mm:ss',
                          )
                    }`}
                    // defaultValue={`${heading === 'Start' ? '00:00:00' : '12:00:00'}`}
                    customInput={TextInput}
                    format={'##:##:##'}
                    placeholder={'HH:mm:ss'}
                    mask={['H', 'H', 'm', 'm', 's', 's']}
                    isAllowed={isTimeAllowed}
                    errorMessage={
                      methods.formState.errors?.Start_time_text?.message ||
                      methods.formState.errors?.End_time_text?.message ||
                      ''
                    }
                    {...methods.register(`${heading}_time_text`)}
                    onValueChange={({ formattedValue }) => {
                      field.onChange(formattedValue);
                      methods.setValue(
                        `${heading}_time_text`,
                        dayjs(formattedValue, 'HH:mm:ss').format('HH:mm:ss'),
                      );
                    }}
                    {...field}
                  >
                    <span className="tz">{plantTzAbbr}</span>
                  </NumberFormat>
                )}
                name={`${heading}_time_text`}
                control={methods.control}
              />
            </InputGroup>
          </TimeInputContainer>
          <TimeRangeButtonGroup>
            <Button
              data-testid="cancel-calendar-button"
              id="cancel-btn"
              buttonSize="small"
              buttonType="secondary"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid="update-calendar-button"
              buttonSize="small"
              buttonType="primary"
              id="update-btn"
              type="submit"
              onClick={updateDateandTime}
            >
              Update
            </Button>
          </TimeRangeButtonGroup>
        </DateTimeRangePickerContainer>
      }
      onClickOutside={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpen(false);
      }}
    >
      <DateTimeRangePickerOpenButton>{children}</DateTimeRangePickerOpenButton>
    </Popover>
  );
};

const DateTimeRangePickerOpenButton = React.forwardRef<HTMLDivElement, ChildElement>(
  ({ children: child }, ref) => {
    return (
      <OpenContainer ref={ref}>
        <OpenButtonClone>{child}</OpenButtonClone>
      </OpenContainer>
    );
  },
);

const OpenButtonClone: React.FC = ({ children: child }) => {
  const { setIsOpen } = useContext(DateTimePickerContext);
  if (React.isValidElement(child)) {
    return React.cloneElement(child, {
      onClick: FunctionUtils.callAll(() => setIsOpen(true), child.props.onClick),
    });
  }
  return <></>;
};

export default DateTimePicker;
