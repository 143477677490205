import dayjs from 'dayjs';
import {
  useState,
  useMemo,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { matchPath, useLocation } from 'react-router-dom';

import { useAnalytics } from '../../../analytics';
import { Paths } from '../../../constants/paths';
import { AppContext } from '../../../context/app-context';
import { useTenants } from '../../../hooks/tenants';
import { Chevron } from '../../time-display/styles';

import {
  ChevronContainer,
  OtherTimezoneContainer,
  OtherTimezoneSelect,
  OptionsGroup,
  SearchInput,
  Ul,
  Li,
  TimezoneDisplayContainer,
  NoResults,
} from './styles';

import { RadioButton, RadioHeader } from '@controlrooms/components';
import { timezones, TimeZone } from '@controlrooms/constants';
import { Fields } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

interface Props {
  isSelectOpen: boolean;
  setIsSelectOpen: Dispatch<SetStateAction<boolean>>;
}

export const TimezoneSelector: React.FC<Props> = ({ isSelectOpen, setIsSelectOpen }) => {
  const [selected, setSelected] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [otherTimezone, setOtherTimezone] = useState<TimeZone>();
  const { currentTenant } = useTenants();

  const utcZone = useMemo(() => timezones.find((z) => z.value === 'UTC'), []) as TimeZone;

  const { pathname } = useLocation();
  const { analyzeTimeSelection, monitorTimeSelection } = useContext(AppContext);
  const isAnalyze =
    !!matchPath(Paths.ANALYZE, pathname) || !!matchPath(Paths.DEMO_ANALYZE, pathname);
  const { timezone } = isAnalyze ? analyzeTimeSelection : monitorTimeSelection;

  const { getValues, setValue } = useFormContext();
  const { track } = useAnalytics();

  useEffect(() => {
    setValue(Fields.TIMEZONE, timezone);
  }, [setValue, timezone]);

  const selectedTimezone = getValues(Fields.TIMEZONE);
  const setSelectedTimezone = (tz: string) => setValue(Fields.TIMEZONE, tz);

  const plantTimezone = currentTenant.timezone;

  useEffect(() => {
    if (selectedTimezone === plantTimezone && selectedTimezone !== dayjs.tz.guess()) {
      setSelected(PLANT_SELECTION);
    } else if (selectedTimezone === dayjs.tz.guess()) {
      setSelected(MYCOMPUTER_SELECTION);
    } else {
      setOtherTimezone(timezones.find((t) => t.utc.includes(selectedTimezone)));
      setSelected(TZ_OTHER);
    }
  }, [plantTimezone, selectedTimezone]);

  const PLANT_SELECTION = 'plant';
  const MYCOMPUTER_SELECTION = 'my-computer';
  const TZ_OTHER = 'other';

  const plantTzAbbr = useMemo(
    () => plantTimezone && TimeUtils.zoneFormat(plantTimezone, 'z'),
    [plantTimezone],
  );

  const computerTzAbbr = useMemo(() => {
    return TimeUtils.toLocal(dayjs()).format('z');
  }, []);

  const filteredTimezones = useMemo(
    () =>
      timezones.filter(
        (timezone) =>
          (timezone.value !== 'UTC' &&
            timezone.value
              .toLowerCase()
              .replace(/[-_[\]{}()*+?.,\\^$|#\s]/g, ' ')
              .includes(searchTerm.toLowerCase())) ||
          timezone.utc.some((u) => u.toLowerCase().includes(searchTerm.toLowerCase())),
      ),
    [searchTerm],
  );

  const offsetFormat = (offsetHours: number) => {
    if (offsetHours === 0) return '';
    if (offsetHours < 0) {
      return `${offsetHours.toFixed(2)}`.replace('.', ':');
    }
    if (offsetHours < 10) {
      return `+0${offsetHours.toFixed(2)}`.replace('.', ':');
    }
    return `+${offsetHours.toFixed(2)}`.replace('.', ':');
  };

  const selectFilterMapper = () => (
    <>
      {filteredTimezones.length > 0 ? (
        <Ul>
          <Li
            data-testid={`${utcZone.abbr}`}
            key={utcZone.abbr}
            onClick={(e) => {
              e.preventDefault();
              setOtherTimezone(utcZone);
              setSelectedTimezone(utcZone.utc[utcZone.utc.length - 1]);
              setIsSelectOpen(false);
            }}
          >
            <TimezoneDisplayContainer>
              <span>{utcZone.text}</span>
            </TimezoneDisplayContainer>
          </Li>
          {filteredTimezones.map((zone) => (
            <Li
              key={zone.text}
              data-testid={`${zone.value}`}
              onClick={(e) => {
                e.preventDefault();
                setOtherTimezone(zone);
                setSelectedTimezone(zone.utc[zone.utc.length - 1]);
                track('Timeframe Changes - Other Timezone', {
                  selectedTimeZoneText: zone.text,
                  selectedTimeZoneValue: zone.value,
                  selectedTimeZoneOffset: `UTC ${offsetFormat(zone.offset)}`,
                  currentPage: pathname,
                });
                setIsSelectOpen(false);
              }}
            >
              <TimezoneDisplayContainer>
                <span>{zone.value}</span>
                <span>{`UTC ${offsetFormat(zone.offset)}`}</span>
              </TimezoneDisplayContainer>
            </Li>
          ))}
        </Ul>
      ) : (
        <NoResults data-testid="time-zone-no-results">No results...</NoResults>
      )}
    </>
  );

  useEffect(() => {
    track('Timeframe Changes - Other Timezone', {
      selectedTimeZoneText: utcZone.text,
      selectedTimeZoneValue: utcZone.value,
      selectedTimeZoneOffset: `UTC ${offsetFormat(utcZone.offset)}`,
      currentPage: pathname,
    });
    // eslint-disable-next-line
  }, [utcZone, pathname]);

  const onChange = (selection: string) => {
    setSelected(selection);

    if (selection === PLANT_SELECTION) {
      setSelectedTimezone(plantTimezone);
      track('Timeframe Changes - Plant Timezone', {
        selectedTimeZone: plantTimezone,
        currentPage: pathname,
      });
    }
    if (selection === MYCOMPUTER_SELECTION) {
      setSelectedTimezone(dayjs.tz.guess());
      track('Timeframe Changes - Computer Timezone', {
        selectedTimeZone: dayjs.tz.guess(),
        currentPage: pathname,
      });
    }
    if (selection === TZ_OTHER) {
      otherTimezone && setSelectedTimezone(otherTimezone.utc[otherTimezone.utc.length - 1]);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <RadioHeader>Time Zone</RadioHeader>

      <RadioButton
        data-testid={`plant-${plantTzAbbr}-time-zone`}
        className="tz-radio"
        onChange={() => onChange(PLANT_SELECTION)}
        checked={selected === PLANT_SELECTION}
        disabled={!plantTimezone}
        id={PLANT_SELECTION}
        value={PLANT_SELECTION}
      >
        {`Plant (${plantTzAbbr})`}
        {!plantTimezone && <em>&nbsp; - loading</em>}
      </RadioButton>

      <RadioButton
        className="tz-radio"
        data-testid="my-computer-time-zone"
        onChange={() => onChange(MYCOMPUTER_SELECTION)}
        checked={selected === MYCOMPUTER_SELECTION}
        id={MYCOMPUTER_SELECTION}
        value={MYCOMPUTER_SELECTION}
      >
        {`My Computer (${computerTzAbbr})`}
      </RadioButton>

      <OtherTimezoneContainer>
        <RadioButton
          className="tz-radio"
          onChange={() => onChange(TZ_OTHER)}
          checked={selected === TZ_OTHER}
          id={TZ_OTHER}
          data-testid="other-time-zone"
          value={TZ_OTHER}
        >
          <div
            data-testid="other-label"
            className="other-label"
            onClick={(evt) => {
              evt.preventDefault();
              onChange(TZ_OTHER);
              evt.stopPropagation();
              setIsSelectOpen(!isSelectOpen);
            }}
          >
            {otherTimezone ? otherTimezone.value : 'Other'}
            <ChevronContainer>
              <Chevron />
            </ChevronContainer>
          </div>
        </RadioButton>
        <OtherTimezoneSelect
          isOpen={isSelectOpen}
          onClick={(evt) => {
            setSelected(TZ_OTHER);
            setSearchTerm('');
            evt.preventDefault();
            evt.stopPropagation();
          }}
        >
          <OptionsGroup>TIME ZONE</OptionsGroup>
          <SearchInput
            data-testid="search-time-zone-input"
            placeholder="Search country, city, abbreviation"
            type="text"
            value={searchTerm}
            onChange={handleChange}
          />
          {selectFilterMapper()}
        </OtherTimezoneSelect>
      </OtherTimezoneContainer>
    </div>
  );
};
