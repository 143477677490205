import { ChannelParameters, useChannel } from '@ably-labs/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { Types } from 'ably';
import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSearchParams, generatePath, NavLink } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import Logo from '../../../assets/cr_logo.svg';
import { useAnalytics } from '../../analytics';
import { QueryParams, Paths } from '../../constants/paths';
import { AblyChannelContext } from '../../context/ably-channel-context';
import { AppContext } from '../../context/app-context';
import { AuthorizationContext } from '../../context/authorization-context';
import { EnsembleContext } from '../../context/ensemble-context';
import { useTenant } from '../../context/tenant-context';
import { useResendEmailConfrimation, useSetUserTermsAccepted, useUser } from '../../hooks/accounts';
import { FOLDER_KEY } from '../../hooks/folders';
import {
  useTenants,
  useSwitchTenants,
  useTenantEnvironment,
  useGetTenantConfig,
} from '../../hooks/tenants';
import useHelpHero from '../../hooks/use-help-hero';
import { AlertPage, Analyze } from '../../pages/routes';
import { ConfirmEmailModal } from '../account/confirm-email-modal';
import { VerifyEmailModal } from '../account/verify-email-modal';
import { UploadModal, UploadInstructions } from '../file-upload-modal';
import { LegalModalContents, PrivacyPolicyModal, TermsConditionsModal } from '../legal';
import { Loading } from '../loading/loading';

import { NotificationBanner } from './banners/notifications/notification-banner';
import { Sandbox } from './banners/sandbox';
import { SandboxWithData } from './banners/sandbox-with-data';
import Burger from './burger-menu';
import { ExportSelect } from './export-select';
import MenuDrop from './menu-dropdown';
import {
  EnsembleErrorBanner,
  NotificationWrapper,
  StyledBanner,
  StyledEllipse,
  StyledHeader,
} from './styles';
import { UserSelect } from './user-select';

import {
  Button,
  ButtonGroup,
  Icon,
  Li,
  OptionsGroup,
  Ul,
  Modal,
  ModalContents,
  ModalOpenButton,
  Tooltip,
} from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside, usePrevious } from '@controlrooms/hooks';

enum BannerOptions {
  GO_REAL_TIME = 'GO_REAL_TIME',
  GET_STARTED = 'GET_STARTED',
  BLANK = 'BLANK',
}

export const Header: React.FC = () => {
  const { user: auth0user, logout } = useAuth0();
  const { data: crUser } = useUser();
  const { mutateAsync: acceptUserTerms } = useSetUserTermsAccepted();
  const { tenant } = useTenant();
  const { canUserReadAlert } = useContext(AuthorizationContext);
  const { setCurrentEnvironment, notification, setNotification, setSearchMode, setShowLimits } =
    useContext(AppContext);
  const { setSelectedEnsemble, showEnsembleErrorBanner, setShowEnsembleErrorBanner } =
    useContext(EnsembleContext);
  const { userChannel, tenantChannel } = useContext(AblyChannelContext);
  const { tenants } = useTenants();
  const [switchTenant, isTenantSwitching] = useSwitchTenants();
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutateAsync: resendConfrimationEmail } = useResendEmailConfrimation();
  const { data: tenantConfig } = useGetTenantConfig();

  const { track } = useAnalytics();

  const [selectedTenant, setSelectedTenant] = useState<number | undefined>(
    tenants?.find((t) => t.selected)?.id,
  );
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const previousSelectedTenant = usePrevious(selectedTenant);
  const { data: env } = useTenantEnvironment(selectedTenant);
  const { show: showIntercom } = useIntercom();
  const helpHero = useHelpHero();
  const [open, setOpen] = useState(false);

  if (helpHero) {
    helpHero.on((event, info) => {
      track(event.kind, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        tour_name: info.tour.name,
      });
      console.log('event, on headers>>>>', event.kind, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        tour_name: info.tour.name,
      });
    });
  }

  const [isPlantSelectOpen, setIsPlantSelectOpen] = useState(false);

  const plantUlRef = useRef(null);
  const queryClient = useQueryClient();
  const displayUploadButton = tenantConfig?.showUpload;

  const [userChannelCallbacks] = useChannel((userChannel?.name || '') as ChannelParameters, () => {
    return;
  });

  const [tenantChannelCallbacks] = useChannel(
    (tenantChannel?.name || '') as ChannelParameters,
    () => {
      return;
    },
  );

  useEffect(() => {
    const handleNotification = (subscriptionData: Types.Message) => {
      // Reloads folder data after notification. May want to limit this to specific notification types down the road.
      queryClient.invalidateQueries(FOLDER_KEY);
      setNotification(JSON.parse(subscriptionData.data));
    };

    userChannelCallbacks.subscribe((subscriptionData) => {
      handleNotification(subscriptionData);
    });

    tenantChannelCallbacks.subscribe((subscriptionData) => {
      handleNotification(subscriptionData);
    });

    return () => {
      userChannelCallbacks.unsubscribe((subscriptionData) => {
        handleNotification(subscriptionData);
      });
      tenantChannelCallbacks.unsubscribe((subscriptionData) => {
        handleNotification(subscriptionData);
      });
    };
  }, [tenantChannelCallbacks, userChannelCallbacks, queryClient, notification, setNotification]);

  useClickOutside(plantUlRef, () => setIsPlantSelectOpen(false));

  useEffect(() => {
    // Initialize from path
    setSelectedTenant(tenants?.find((t) => t.selected)?.id);
  }, [tenants]);

  // Set the current environment (contains streaming / batch info if needed outside this context)
  useEffect(() => {
    env && setCurrentEnvironment(env);
  }, [env, setCurrentEnvironment]);

  useEffect(() => {
    if (previousSelectedTenant && selectedTenant && previousSelectedTenant !== selectedTenant) {
      switchTenant(selectedTenant);
    }
  }, [selectedTenant, previousSelectedTenant, switchTenant]);

  const handleAcceptTerms = () => {
    track('User Terms', {
      acceptTerms: true,
    });
    acceptUserTerms(true);
    if (process.env.REACT_APP_HELP_HERO_TOUR_ID) {
      helpHero.startTour(process.env.REACT_APP_HELP_HERO_TOUR_ID, { skipIfAlreadySeen: true });
    }
    return true;
  };

  const handleCloseTerms = () => {
    track('User Terms', {
      closeTermsAndConditions: 'clicked',
    });
    const _params = new URLSearchParams(searchParams);
    _params.delete(QueryParams.TERMS_CONDITIONS);
    setSearchParams(_params);
    return true;
  };

  const handleClosePivacyPolicy = () => {
    track('User Privacy Policy', {
      closePrivacyPolicy: 'clicked',
    });
    const _params = new URLSearchParams(searchParams);
    _params.delete(QueryParams.PRIVACY_POLICY);
    setSearchParams(_params);
    return true;
  };

  const handleCloseEmailConfrimation = () => {
    track('User Confirmation Email', {
      closeResendConfirmationEmail: 'clicked',
    });
    const params = new URLSearchParams();
    params.delete(QueryParams.CONFIRM_EMAIL);
    setSearchParams(params);
  };

  const getUserFullName = () => {
    return auth0user?.given_name && auth0user?.family_name
      ? `${auth0user?.given_name} ${auth0user?.family_name}`
      : auth0user?.name;
  };

  const renderTenantSelector = () => {
    const enabled = (tenants?.length ?? 0) > 1;
    return (
      <div className="plant-name">
        <OptionsGroup>
          <button
            data-testid="plant-button"
            type="button"
            aria-haspopup="listbox"
            aria-expanded={isPlantSelectOpen}
            className={classNames({ disabled: !enabled })}
            onClick={(evt) => {
              evt.stopPropagation();
              enabled && setIsPlantSelectOpen(!isPlantSelectOpen);
            }}
          >
            <ButtonGroup>
              {selectedTenant && (
                <>
                  {tenants?.find((t) => t.id === selectedTenant)?.name}
                  {enabled && <Icon name="chevron" style={{ display: 'flex' }} />}{' '}
                </>
              )}
            </ButtonGroup>
          </button>
          <Ul isOpen={isPlantSelectOpen} ref={plantUlRef}>
            {tenants?.map((t) => (
              <Li
                data-testid={`plant-button-${t.name}`}
                onClick={(e) => {
                  setSearchMode(false);
                  if (e.currentTarget.value !== selectedTenant) {
                    setSelectedEnsemble(null);
                    setSelectedTenant(tenants?.find((t) => t.id === e.currentTarget.value)?.id);
                  }
                  setIsPlantSelectOpen(false);
                  track('Tenant Selection', {
                    tenantId: t.id,
                    tenantName: t.name,
                  });
                }}
                key={t.id}
                value={t.id}
              >
                {t.name}
              </Li>
            ))}
          </Ul>
        </OptionsGroup>
      </div>
    );
  };

  const renderStyledBanner = () => {
    if (notification) {
      // Make them go away automatically?
      // setTimeout(() => setNotification(undefined), 10 * 1000);
      return (
        <NotificationBanner
          notificationData={notification}
          onDismiss={() => setNotification(undefined)}
        />
      );
    }

    if (tenantConfig && tenantConfig.banner) {
      // See https://controlrooms.atlassian.net/browse/CRA-673 for updated banner logic
      if (tenantConfig.banner === BannerOptions.BLANK) return null;
      else if (tenantConfig.banner === BannerOptions.GET_STARTED) return <Sandbox />;
      else if (tenantConfig.banner === BannerOptions.GO_REAL_TIME) return <SandboxWithData />;
    }

    return null;
  };

  return (
    <StyledHeader>
      {isTenantSwitching && <Loading overlay />}
      <div className="header-left">
        <img
          className="logo"
          src={Logo}
          alt="Control Rooms"
          height={25}
          width={30}
          loading={'lazy'}
        />
        <nav className="navigation">
          {renderTenantSelector()}
          <div className="desk-view">
            <NavLink
              onClick={() => {
                track('Change Page', { currentPage: 'Monitor' });
                setSearchMode(false);
              }}
              data-testid="monitor"
              to={generatePath(Paths.MONITOR, { tenantId: tenant.toString() })}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Monitor
            </NavLink>
            <NavLink
              onClick={() => {
                track('Change Page', { currentPage: 'Analyze' });
                setShowLimits(true);
                setSearchMode(false);
              }}
              data-testid="analyze"
              onMouseOver={() => Analyze.preload()}
              to={generatePath(Paths.ANALYZE, { tenantId: tenant.toString() })}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Analyze
            </NavLink>
          </div>
        </nav>
      </div>
      <div className="header-right">
        <StyledBanner>{renderStyledBanner()}</StyledBanner>
        {canUserReadAlert && <NotificationSelect />}
        {displayUploadButton && (
          <ButtonGroup>
            <Modal open={Boolean(searchParams.get(QueryParams.UPLOAD))}>
              <Tooltip label="Upload your data files" offset="{'left': 8}" place="bottom,left">
                <ModalOpenButton>
                  <Button
                    onClick={() =>
                      track('Upload Files', {
                        openUploadFilesModal: 'clicked',
                      })
                    }
                    data-testid="upload-icon-header"
                    iconName={ICONS.Upload}
                    buttonType="icon"
                    buttonSize="large"
                  />
                </ModalOpenButton>
              </Tooltip>
              <ModalContents
                title="My Data Uploads"
                headerInfoComponent={<UploadInstructions />}
                footerPrompt="You will be notified by email when your data is ready to view"
                swapButtonOrder
                closeButtonText="Close"
                closeButtonCallback={() => {
                  track('Upload Instructions', {
                    closeUploadFilesModal: 'clicked',
                  });
                  const _params = new URLSearchParams(searchParams);
                  _params.delete(QueryParams.UPLOAD);
                  setSearchParams(_params);
                }}
              >
                <UploadModal />
              </ModalContents>
            </Modal>
          </ButtonGroup>
        )}
        <ExportSelect />
        <UserSelect userName={getUserFullName() || ''} logout={logout} />
        <StyledEllipse>
          <div className="ellipse" />
        </StyledEllipse>
      </div>
      <div className="mobile-view">
        <Burger open={open} setOpen={setOpen} />
        <MenuDrop open={open}>
          <NavLink
            onClick={() => track('Change Page', { currentPage: 'Monitor' })}
            data-testid="monitor-mobile"
            to={generatePath(Paths.MONITOR, { tenantId: tenant.toString() })}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Monitor
          </NavLink>
          <NavLink
            onClick={() => {
              track('Change Page', { currentPage: 'Analyze' });
              setShowLimits(true);
            }}
            data-testid="analyze-mobile"
            onMouseOver={() => Analyze.preload()}
            to={generatePath(Paths.ANALYZE, { tenantId: tenant.toString() })}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            Analyze
          </NavLink>
          {canUserReadAlert && (
            <div className="mobile-view-alert">
              <NotificationSelectMobile />
            </div>
          )}
          {displayUploadButton && (
            <div className="mobile-view-icon">
              <ButtonGroup>
                <Modal open={Boolean(searchParams.get(QueryParams.UPLOAD))}>
                  <Tooltip label="Upload your data files" offset="{'left': 8}" place="bottom,left">
                    <ModalOpenButton>
                      <NavLink
                        data-testid="upload-mobile"
                        onClick={() =>
                          track('Upload Files', {
                            openUploadFilesModal: 'clicked',
                          })
                        }
                        to={''}
                        className={({ isActive }) => (!isActive ? 'active' : '')}
                      >
                        Upload
                      </NavLink>
                    </ModalOpenButton>
                  </Tooltip>
                  <ModalContents
                    title="My Data Uploads"
                    headerInfoComponent={<UploadInstructions />}
                    footerPrompt="You will be notified by email when your data is ready to view"
                    swapButtonOrder
                    closeButtonText="Close"
                    closeButtonCallback={() => {
                      track('Upload Instructions', {
                        closeUploadFilesModal: 'clicked',
                      });
                      const _params = new URLSearchParams(searchParams);
                      _params.delete(QueryParams.UPLOAD);
                      setSearchParams(_params);
                    }}
                  >
                    <UploadModal />
                  </ModalContents>
                </Modal>
              </ButtonGroup>
            </div>
          )}
          <div className="mobile-view-icon">
            <ExportSelect />
          </div>
          <div className="mobile-view-user">
            <UserSelect userName={getUserFullName() || ''} logout={logout} />
          </div>
          <div className="mobile-view-chat">
            <Button
              buttonType="link"
              buttonSize="small"
              iconName={ICONS.Union}
              onClick={() => {
                track('Chat with an engineer now', {
                  chatWithEngineer: 'clicked from mobile',
                  intercom: 'visible',
                });
                showIntercom();
              }}
            >
              Chat with an engineer now
            </Button>
          </div>
        </MenuDrop>
      </div>
      {showEnsembleErrorBanner && (
        <EnsembleErrorBanner>
          <Icon className="icon" name={ICONS.Info} />
          <span data-testid="permission-info">
            {searchParams.get('reference')
              ? `This alert was evaluated by an ensemble family that you are not currently authorized to
            see. The tags from the alert shown below are populated with anomalies from your current
            live ensemble family.`
              : `This shared view was evaluated by an ensemble family that you are not currently authorized to
            see. The view shown below are populated with anomalies from your current
            live ensemble family.`}
          </span>
          <Icon
            className="close"
            name={ICONS.Close}
            onClick={() => {
              setShowEnsembleErrorBanner(false);
            }}
          />
        </EnsembleErrorBanner>
      )}
      <Modal open={!crUser?.accepted_terms}>
        <ModalContents
          shouldCloseOnEsc={false}
          closeButtonCallback={() => {
            const params = new URLSearchParams();
            params.append(QueryParams.AUTH0, 'true');
            // return to Auth0 login screen
            sessionStorage.clear();
            logout({
              returnTo: `${window.location.origin}?${params}`,
            });
          }}
          confirmButtonText="Next"
          confirmButtonCallback={handleAcceptTerms}
          footerPrompt={
            (
              <div>
                Need help?{' '}
                <Button
                  buttonType="link"
                  buttonSize="small"
                  onClick={() => {
                    track('User Help', {
                      chatWithEngineer: 'clicked',
                      intercom: 'visible',
                    });
                    showIntercom();
                  }}
                >
                  Chat with an engineer now
                </Button>
              </div>
            ) as JSX.Element
          }
          confirmButtonDisabled={!acceptTerms}
        >
          <ConfirmEmailModal acceptTerms={acceptTerms} onTermsChange={setAcceptTerms} />
        </ModalContents>
      </Modal>
      <Modal open={Boolean(searchParams.get(QueryParams.CONFIRM_EMAIL))}>
        <ModalContents
          title="Email Verification Incomplete"
          shouldCloseOnEsc={false}
          styles={{ content: { width: '35%', minWidth: '400px' } }}
          closeButtonCallback={handleCloseEmailConfrimation}
          closeButtonText="Cancel"
          confirmButtonText="Resend"
          confirmButtonCallback={() => {
            resendConfrimationEmail();
            handleCloseEmailConfrimation();
            return true;
          }}
        >
          <VerifyEmailModal />
        </ModalContents>
      </Modal>
      <Modal open={Boolean(searchParams.get(QueryParams.TERMS_CONDITIONS))}>
        <LegalModalContents
          confirmButtonText="Close"
          confirmButtonCallback={handleCloseTerms}
          closeButtonCallback={handleCloseTerms}
        >
          <TermsConditionsModal />
        </LegalModalContents>
      </Modal>
      <Modal open={Boolean(searchParams.get(QueryParams.PRIVACY_POLICY))}>
        <LegalModalContents
          confirmButtonText="Close"
          confirmButtonCallback={handleClosePivacyPolicy}
          closeButtonCallback={handleClosePivacyPolicy}
        >
          <PrivacyPolicyModal />
        </LegalModalContents>
      </Modal>
    </StyledHeader>
  );
};

const NotificationSelect = () => {
  const { tenant } = useTenant();

  return (
    <NotificationWrapper>
      <Tooltip label="Notifications" offset="{'left': 8}" place="bottom,left">
        <NavLink
          data-testid="alert"
          onMouseOver={() => AlertPage.preload()}
          to={generatePath(Paths.MANAGE_ALERTS, { tenantId: tenant.toString() })}
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          <Button
            data-testid="notification-button"
            buttonSize="large"
            buttonType="icon"
            iconName={ICONS.Bell}
          />
        </NavLink>
      </Tooltip>
    </NotificationWrapper>
  );
};

const NotificationSelectMobile = () => {
  const { tenant } = useTenant();

  return (
    <NotificationWrapper>
      <Tooltip label="Notifications" offset="{'left': 8}" place="bottom,left">
        <NavLink
          data-testid="alert-mobile"
          onMouseOver={() => AlertPage.preload()}
          to={generatePath(Paths.MANAGE_ALERTS, { tenantId: tenant.toString() })}
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          Alerts
        </NavLink>
      </Tooltip>
    </NotificationWrapper>
  );
};
