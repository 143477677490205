import React, { useCallback, useContext } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import { useAnalytics } from '../../../analytics';
import { useTenants } from '../../../hooks/tenants';
import { FileUploadContext } from '../file-upload-context';

import {
  Container,
  HeadingContainer,
  StyledFileList,
  UploaderHeader,
  UploaderSubheading,
} from './styles';
import { Uploader } from './uploader';

export const Dropzone: React.FC = ({ children }) => {
  const { files, setFiles, setTenantName } = useContext(FileUploadContext);
  const { currentTenant } = useTenants();
  const { track } = useAnalytics();

  const onRejected = useCallback((rejections: FileRejection[]) => {
    rejections.forEach((rejection: FileRejection) => {
      console.log(`${rejection.file.name} failed. ${rejection.errors[0].message}`);
      track('Dropzone - Rejected', {
        numberOfFilesRejected: rejections.length,
        fileNameRejected: rejection.file.name,
        rejectionError: rejection.errors[0].message,
      });
    });
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: [
      '.csv',
      'text/csv',
      'application/csv',
      'text/x-csv',
      'application/x-csv',
      'text/comma-separated-values',
      'text/x-comma-separated-values',
    ],
    minSize: 1,
    maxSize: 1024 * 1024 * 1024,
    noClick: Boolean(children),
    onDropRejected: onRejected,
    onDropAccepted: (dzFiles) => {
      track('Dropzone - Accepted', {
        numberOfFilesAccepted: dzFiles.length,
        tenant: currentTenant.name,
      });
      setTenantName(currentTenant.name);
      dzFiles.map((dzFile) => {
        setFiles(
          new Map(files).set(uuidv4(), { file: dzFile as File, progress: 0, status: 'uploading' }),
        );
      });
    },
  });

  return (
    <div>
      <Container
        data-testid="drag-and-drop-button"
        {...getRootProps({ isFocused, isDragAccept, isDragReject })}
      >
        <input data-testid="input-file" {...getInputProps()} />
        {children ? (
          children
        ) : (
          <HeadingContainer isDragAccept={isDragAccept}>
            <UploaderHeader>Drag and drop .CSV files here</UploaderHeader>
            <UploaderSubheading>Or select from computer</UploaderSubheading>
          </HeadingContainer>
        )}
      </Container>
      {/* TODO - once we actually use this we probably want to track accepted files and show the uploader based on some status there */}
      <StyledFileList>
        {[...files].map(([key, fileData]) => (
          <Uploader key={key} fileId={key} fileData={fileData} />
        ))}
      </StyledFileList>
    </div>
  );
};
