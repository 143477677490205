export class NumberUtils {
  /**
   * Returns a number whose value is limited to the given range.
   *
   * @param value the number
   * @param min the upper bound
   * @param max the lower bound
   */
  public static clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(Math.round(value), min), max);
  }
}
