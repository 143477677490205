import dayjs from 'dayjs';

import { ApiListResult, ApiResult, ApiTimeServicePayload, CRUser } from '../api';
import { CRAnomalies, CRAnomaly, CRLimitsExceeded } from '../api/anomaly';

import { TimeValue } from './index';

export enum SentimentTypes {
  TRUE_POSITIVE = 'True Positive',
  FALSE_POSITIVE = 'False Positive',
  FALSE_NEGATIVE = 'False Negative',
}

export enum VisibilityTypes {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum SeverityTestIDValue {
  g1 = 'anomalies-Low',
  g2 = 'anomalies-Medium',
  g3 = 'anomalies-High',
}

export interface SearchTimeRangeData {
  x: number;
  y: number;
  startTime: string | number;
  endTime: string | number;
  tag: any;
  folder: any;
}

export interface GroupTooltipData {
  targetElementId: string;
  currentTarget?: EventTarget | null;
  tooltipRef?: React.RefObject<HTMLDivElement>;
  start: string | number;
  end: string | number;
  folderId?: number;
  eventTypeIds?: number[];
  labeledEventId?: number;
}

export interface LabeledEvent {
  id: number;
  label_type_ids?: number[];
  label_type_id?: number;
  label_event_id?: number;
  start_time: string;
  end_time: string;
  ui_start_time: string;
  ui_end_time: string;
  process_id: number;
  ensemble_id: number | null;
  creation_date: string;
}

export interface LabeledEventType {
  id?: number;
  parent_id: number | null;
  type_name: string | null;
  description: string | null;
  custom_flag: boolean;
  visibility: string;
  sequence: number | null;
  label_type_id?: number;
  child_types?: LabeledEventType[];
}

export interface Filter {
  type_name: string;
  description: string;
  sentiment: SentimentTypes;
  custom_flag: boolean;
  visibility: string;
  sequence: number;
  label_type_id: number;
  created_by: CRUser;
  selected: boolean;
  count: number;
}

export interface AnomalyPayload extends ApiTimeServicePayload {
  folders: number[] | undefined;
  interval: number | undefined;
  ensemble_family_id?: string;
}

export class Anomaly implements TimeValue {
  time: number;
  timestamp: string;
  endTime: number;
  endTimestamp: string;
  value: number;
  isFirst: boolean;
  isLast: boolean;
  label?: string;

  constructor(init: CRAnomaly, interval: number, isFirst: boolean, isLast: boolean) {
    const end = dayjs(init.time).add(interval, 'seconds');

    this.time = dayjs(init.time).valueOf();
    this.timestamp = init.time;
    this.endTime = end.valueOf();
    this.endTimestamp = end.toISOString();
    this.value = init.value;
    this.isFirst = isFirst;
    this.isLast = isLast;
  }
}

export class LimitsExceeded {
  high: boolean;
  high_high: boolean;
  low: boolean;
  low_low: boolean;
  time: number;

  constructor(init: CRLimitsExceeded) {
    this.high = init.high;
    this.high_high = init.high_high;
    this.low = init.low;
    this.low_low = init.low_low;
    this.time = dayjs(init.time).valueOf();
  }
}

export class Anomalies implements CRAnomalies<Anomaly, LimitsExceeded> {
  anomalies: Anomaly[];
  folder: number;
  limits_exceeded: LimitsExceeded[] | undefined;

  constructor(init: CRAnomalies<CRAnomaly, CRLimitsExceeded>, interval: number) {
    this.anomalies = init.anomalies?.map(
      (anomaly, idx, anomalies) =>
        new Anomaly(
          anomaly,
          interval,
          anomaly.value !== 0 && (idx === 0 || anomalies[idx - 1].value === 0),
          anomaly.value !== 0 && (idx === anomalies.length - 1 || anomalies[idx + 1].value === 0),
        ),
    );
    this.limits_exceeded = init.limits_exceeded?.map((limit) => new LimitsExceeded(limit));
    this.folder = init.folder;
  }
}

export const parseAnomaliesResult = (
  anomalies: CRAnomalies<CRAnomaly, CRLimitsExceeded>[] | undefined,
  interval: number,
) => {
  const result =
    (anomalies || []).reduce<Record<number, Anomalies>>((acc, a) => {
      const foo = a.folder as number;
      acc[foo] = new Anomalies(a as CRAnomalies<CRAnomaly, CRLimitsExceeded>, interval as number);
      return acc;
    }, {}) ?? {};

  return result;
};

export class AnomaliesResult implements ApiResult<Record<number, Anomalies>> {
  result: Record<number, Anomalies>;
  request: Record<string, string | number>;
  headers: Record<string, string>;

  constructor(init: ApiListResult<CRAnomalies<CRAnomaly, CRLimitsExceeded>>) {
    const { interval } = init.request as unknown as AnomalyPayload;
    this.result = parseAnomaliesResult(init.result, interval as number);
    this.request = init.request as Record<string, string | number>;
    this.headers = init.headers;
  }
}

export interface AnomalyGroup {
  start: number;
  end: number;
  values: Anomaly[];
}

export interface AnomalyMerged {
  time: number;
  timestamp: string;
  endTime: number;
  endTimestamp: string;
  isFirst: boolean;
  isLast: boolean;
  value: number;
  high?: boolean;
  high_high?: boolean;
  low?: boolean;
  low_low?: boolean;
}

export interface LimitExceeded {
  time: number;
  high: boolean;
  high_high: boolean;
  low: boolean;
  low_low: boolean;
  value?: number;
}
