import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

import { useTenant } from '../../context/tenant-context';
import { ErrorPage, Error } from '../../pages/not-found/error-page';

export const Auth0HistoryProvider: React.FC = ({ children }) => {
  const domain: string | undefined = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId: string | undefined = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE;

  const { tenant } = useTenant();

  if (!(domain && clientId && audience)) {
    // TODO - log error, make this something else
    return (
      <ErrorPage>
        <Error message="Invalid authorization configuration, please contact the support@controlrooms.ai." />
      </ErrorPage>
    );
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={`${window.location.origin}/app/redirect`}
      tenant={tenant}
    >
      {children}
    </Auth0Provider>
  );
};
