import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  createLabelType,
  updateLabelType,
  deleteLabelType,
  createLabeledEvent,
  updateLabeledEvent,
  getLabelType,
  getLabeledEventsByLabelTypeId,
  getFilters,
  updateFilters,
  getLabeledEventsInRange,
  getLabelTypes,
  getLabeledEvents,
  deleteLabeledEvent,
} from '../services/accounts-customize';

import { LabeledEvent, LabeledEventType } from '@controlrooms/models';

// TODO - move to utils
export const getTypeLabelsById = (arr: LabeledEventType[], labelIds: number[]): string | null => {
  const labels: string[] = [];

  for (const obj of arr) {
    if (labelIds.includes(obj.label_type_id as number)) {
      labels.push(obj.type_name as string);
    }

    // If the object has child_types, look inside them.
    if (obj.child_types && obj.child_types.length > 0) {
      const childLabels = obj.child_types
        .map((child) => getTypeLabelsById([child], labelIds))
        .filter(Boolean);
      if (childLabels.length) {
        labels.push(`${obj.type_name}: ${childLabels.join(', ')}`);
      }
    }
  }

  return labels.join(', ');
};

export const useLabelsAPI = () => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createLabelTypeMutation = useMutation<any, any, Partial<LabeledEventType>>(
    (labelType) => createLabelType(labelType),
    {
      onSuccess: () => queryClient.invalidateQueries('labelType'),
    },
  );

  const updateLabelTypeMutation = useMutation(updateLabelType, {
    onSuccess: () => queryClient.invalidateQueries('labelType'),
  });

  const deleteLabelTypeMutation = useMutation(deleteLabelType, {
    onSuccess: () => queryClient.invalidateQueries('labelType'),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createLabeledEventMutation = useMutation<any, any, Partial<LabeledEvent>>(
    createLabeledEvent,
    {
      onSuccess: () => queryClient.invalidateQueries('labeledEvents'),
    },
  );

  const updateLabeledEventMutation = useMutation(updateLabeledEvent, {
    onSuccess: () => queryClient.invalidateQueries('labeledEvents'),
  });

  const deleteLabeledEventMutation = useMutation(deleteLabeledEvent, {
    onSuccess: () => queryClient.invalidateQueries('labeledEvents'),
  });

  const updateFiltersMutation = useMutation(updateFilters, {
    onSuccess: () => queryClient.invalidateQueries('filters'),
  });

  const useLabelTypeQuery = (id: number) => useQuery(['labelType', id], () => getLabelType(id));

  const useLabelTypesQuery = () => useQuery('labelType', getLabelTypes);

  const useLabeledEventsQuery = () => useQuery('labeledEvents', getLabeledEvents);

  const useLabeledEventsInRangeQuery = (startTime: string, endTime: string, interval: number) =>
    useQuery(['labeledEvents', { startTime, endTime }], () =>
      getLabeledEventsInRange({ start_time: startTime, end_time: endTime, interval: interval }),
    );

  const useLabeledEventsByLabelTypeIdQuery = (id: number) =>
    useQuery(['labeledEvents', id], () => getLabeledEventsByLabelTypeId(id));

  const useFiltersQuery = () => useQuery('filters', getFilters);

  return {
    createLabelTypeMutation,
    updateLabelTypeMutation,
    deleteLabelTypeMutation,
    createLabeledEventMutation,
    updateLabeledEventMutation,
    deleteLabeledEventMutation,
    updateFiltersMutation,
    useLabelTypeQuery,
    useLabelTypesQuery,
    useLabeledEventsQuery,
    useLabeledEventsInRangeQuery,
    useLabeledEventsByLabelTypeIdQuery,
    useFiltersQuery,
  };
};
