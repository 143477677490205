import { useCallback, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AnalyzeContext } from '../../context/analyze-context';
import { AppContext } from '../../context/app-context';
import SavedViewContextProvider from '../../context/saved-view-context';
import { SearchContext } from '../../context/search-context';
import { useSavedViews } from '../../hooks/accounts';
import { useFlatFolders, usePlantFolders, FolderSort } from '../../hooks/folders';
import { useHiddenTags } from '../../hooks/tags';
import { SidebarFooter } from '../../pages/monitor/styles';
import BrowserFilter from '../browser-filter/browser-filter';
import { CustomViewManager } from '../custom-view/custom-view';

import { Search } from './search';
import { SearchView } from './search-view';
import { BackButton, BrowserHeaderSection } from './styles/styles';
import { SystemBrowserContainer } from './styles/system-styles';
import { TagViewContainer } from './styles/tag-styles';
import { SystemView } from './system-view';
import { TagView } from './tag-view';
import { ViewHiddenTag } from './view-hidden-tag';

import { Button, Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { ParentTag } from '@controlrooms/models';
import { ViewType } from '@controlrooms/models';
import { FlattenedSubSystem, HiddenTags } from '@controlrooms/models';

// should this go somewhere more central?
const SUB_SYSTEMS_SEARCH_PARAM = 'subsystems';

export const SystemBrowser = () => {
  // context
  const {
    pinnedTags,
    setPinnedTags,
    selectedFolders,
    setSelectedFolders,
    currentFolder,
    setCurrentFolder,
    showHiddenTags,
    selectedViewTitle,
    setSelectedViewTitle,
  } = useContext(AnalyzeContext);
  const {
    searchTerm,
    setSearchTerm,
    setIsSearchDebounce,
    setSearchInputValue,
    setSearchTermOnNavigate,
    searchTermOnNavigate,
  } = useContext(SearchContext);

  const { data: savedViews } = useSavedViews(ViewType.ANALYZE);
  const [search] = useSearchParams();
  const subSystemsParams = search.get(SUB_SYSTEMS_SEARCH_PARAM);

  const { isLoading: isFoldersLoading, data: folders } = usePlantFolders(FolderSort.DEFAULT);
  const { data: flatFolders } = useFlatFolders(FolderSort.DEFAULT, {
    folderId: currentFolder as number,
  });

  const { data: hiddenTags } = useHiddenTags();

  const iowHiddenTags =
    hiddenTags?.result.map((t: HiddenTags) => ({ tag_name: t.tag_name, folder: t.folder })) || [];
  const isReport = search.get('report');

  const { setIsCustomViewFormVisible } = useContext(AppContext);

  useEffect(() => {
    if (!subSystemsParams) {
      return;
    }

    setSelectedFolders(subSystemsParams?.split(',').map(Number));
  }, [setSelectedFolders, subSystemsParams]);

  // handlers
  const handleCheckboxCheck = useCallback(
    (id: number, tag?: ParentTag) => {
      // do we need to include folder id with tag for full unique id?
      if (tag) {
        if (pinnedTags.some(({ folder, name }) => folder === tag.folder && name === tag.name)) {
          setPinnedTags(
            pinnedTags.filter(({ folder, name }) => !(folder === tag.folder && name === tag.name)),
          );
        } else {
          setPinnedTags([tag, ...pinnedTags]);
        }

        return;
      }

      // TODO: determine if all pinned tags need to be explicitly set when a folder is selected
      // or can we control that behavior in all relevant parts of the app with selected folder alone?
      if (selectedFolders?.includes(id)) {
        // remove id of subfolder or parent folder id
        const newArr = selectedFolders.filter((x) => {
          return x !== id;
        });
        return setSelectedFolders([...newArr]);
      }
      setSelectedFolders([id, ...selectedFolders]);
    },
    [pinnedTags, selectedFolders, setPinnedTags, setSelectedFolders],
  );

  const handlePinAll = useCallback(
    (isChecked: boolean) => {
      const allTags = flatFolders?.[0].tags;

      if (isChecked && allTags) {
        setPinnedTags(allTags);
      } else {
        setPinnedTags([]);
      }
    },
    [flatFolders, setPinnedTags],
  );

  const handleBack = () => {
    setCurrentFolder(null);
    setIsSearchDebounce(false);
    setSearchInputValue(searchTermOnNavigate);
    setSearchTerm(searchTermOnNavigate);
  };

  // TODO: what do we want to show here while loading?
  if (isFoldersLoading) {
    return null;
  }

  const handleSearchNav = (fid: string | number) => {
    setSearchInputValue('');
    setSearchTermOnNavigate(searchTerm);
    setCurrentFolder(fid);
  };

  const browserView = () => {
    if (searchTerm) {
      return (
        <div className="browser-wrapper">
          <BrowserHeaderSection>
            {savedViews && (
              <SavedViewContextProvider>
                <CustomViewManager
                  views={savedViews}
                  title={selectedViewTitle}
                  onTitleChange={setSelectedViewTitle}
                  viewType={ViewType.ANALYZE}
                />
                <BrowserFilter />
              </SavedViewContextProvider>
            )}
            <Search />
            <div className="sub-title">
              <Icon name={ICONS.SelectTrend} width="9" height="9" className="dynamic-icon" /> Select
              trends to keep in the view.
            </div>
          </BrowserHeaderSection>
          <TagViewContainer>
            <SearchView
              handleCheckboxCheck={handleCheckboxCheck}
              pinnedTags={pinnedTags}
              onNav={handleSearchNav}
            />
          </TagViewContainer>
        </div>
      );
    }

    if (currentFolder) {
      return (
        <div className="browser-wrapper">
          <BrowserHeaderSection>
            <h3 data-testid="tag" className="system-view-title">
              Tag View
            </h3>
            <Search />
            <div className="sub-title">
              <div>
                <Icon name={ICONS.SelectTrend} width="9" height="9" className="dynamic-icon" />{' '}
                Select trends to keep in the view.
              </div>
              <div className="lower">
                <ViewHiddenTag hiddenTags={iowHiddenTags} />
              </div>
            </div>
            <BackButton
              data-testid="systems-back-button"
              className="back-button"
              onClick={() => handleBack()}
            >
              <Icon name={ICONS.Back} width="11" height="11" className="browser-header-back" />{' '}
              Systems
            </BackButton>
          </BrowserHeaderSection>
          <TagViewContainer>
            <TagView
              folders={flatFolders as FlattenedSubSystem[]}
              hiddenTags={iowHiddenTags as HiddenTags[]}
              handleCheckboxCheck={handleCheckboxCheck}
              handlePinAll={handlePinAll}
              pinnedTags={pinnedTags}
              selectedFolders={selectedFolders}
              showHiddenFlag={showHiddenTags}
            />
          </TagViewContainer>
        </div>
      );
    }

    return (
      <div className="browser-wrapper">
        <BrowserHeaderSection>
          {savedViews && (
            <SavedViewContextProvider>
              <CustomViewManager
                views={savedViews}
                title={selectedViewTitle}
                viewType={ViewType.ANALYZE}
                onTitleChange={setSelectedViewTitle}
              />
              <BrowserFilter />
            </SavedViewContextProvider>
          )}
          <Search />
          <div className="sub-title">
            <div>
              <Icon name={ICONS.Dynamic} width="9" height="9" className="dynamic-icon" /> Select
              systems for dynamic view.
            </div>
            <div className="lower">
              <ViewHiddenTag hiddenTags={iowHiddenTags} />
            </div>
          </div>
        </BrowserHeaderSection>
        {showHiddenTags ? (
          <TagViewContainer>
            <TagView
              folders={flatFolders as FlattenedSubSystem[]}
              hiddenTags={iowHiddenTags as HiddenTags[]}
              handleCheckboxCheck={handleCheckboxCheck}
              handlePinAll={handlePinAll}
              pinnedTags={pinnedTags}
              selectedFolders={selectedFolders}
              showHiddenFlag={showHiddenTags}
            />
          </TagViewContainer>
        ) : (
          <SystemView
            handleCheckboxCheck={handleCheckboxCheck}
            onNav={setCurrentFolder}
            plant={folders}
            selectedFolders={selectedFolders}
          />
        )}
      </div>
    );
  };

  if (isReport) return <></>;

  return (
    <SystemBrowserContainer>
      {browserView()}

      {(pinnedTags?.length > 0 || selectedFolders?.length > 0) && (
        <SidebarFooter>
          <div className="gta-wrapper">
            <div>
              <Tooltip
                offset="{'left': 30}"
                label={
                  <>
                    Create a Custom View with
                    <br />
                    selected systems and tags
                  </>
                }
              >
                <Button
                  iconName="plus"
                  buttonType="text"
                  buttonSize="small"
                  className="add-custom-view"
                  data-testid="custom-view-button"
                  onClick={() => setIsCustomViewFormVisible(true)}
                >
                  Custom View
                </Button>
              </Tooltip>
            </div>
          </div>
        </SidebarFooter>
      )}
    </SystemBrowserContainer>
  );
};
