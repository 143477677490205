import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';

import { useAnalytics } from '../../analytics';
import { AppContext } from '../../context/app-context';
import { MonitorContext } from '../../context/monitor-context';
import { useHeatmap } from '../../hooks/anomalies';
import { FolderSort } from '../../hooks/folders';
import { FolderHeatmap, FolderSystems, StyledSubFolderRow } from '../../pages/monitor/styles';
import { SubFolder } from '../../pages/monitor/sub-folder';
import Collapsible from '../collapsible-folder/collapsible-folder';

import { Heatmap } from './heatmap';

import { Plant, SubSystem, System } from '@controlrooms/models';
import { SubSystemAnomalous } from '@controlrooms/models';

interface HeatmapGroupProps {
  folders: Plant | undefined;
  selectedFolders: Array<number>;
  handleHeaderCheckboxCheck: (id: number) => void;
  handleCheckboxCheck: (id: number, parentId: number) => void;
  handleAnalyzeLink: (systemId?: number) => void;
}

export const HeatmapGroup: React.FC<HeatmapGroupProps> = ({
  folders,
  selectedFolders,
  handleHeaderCheckboxCheck,
  handleCheckboxCheck,
  handleAnalyzeLink,
}) => {
  const { monitorTimeSelection: timeSelection, showLimits, setShowLimits } = useContext(AppContext);
  const { showMonitorLimits } = useContext(MonitorContext);
  const { track } = useAnalytics();

  const {
    data: heatmapData,
    isLoading: isLoadingHeatmapData,
    interval,
    labeledEvents,
    fetchNextPage,
    fetchPreviousPage,
  } = useHeatmap(
    {
      startTime: timeSelection.startTime,
      endTime: timeSelection.endTime,
      streamingTimeInSeconds: timeSelection.streamingTimeInSeconds,
    },
    FolderSort.DEFAULT,
  );

  const isAnomalousMap = useMemo(() => {
    if (!heatmapData) return {};
    // This will have to update if implement panning via infinite query and cannot assume page[0] is the correct data
    return Object.values(heatmapData.pages[0].result).reduce<Record<number, boolean>>(
      (acc, { folder, anomalies }) => {
        acc[folder] = anomalies?.some(({ value }) => value > 0);
        return acc;
      },
      {},
    );
  }, [heatmapData]);

  // Partial if we ever want to use fetch next / previous functionality
  // useEffect(() => {
  //   if (!timeSelection.streamingTimeInSeconds) {
  //     const { startTime, endTime } = viewWindow;
  //     if (Math.abs(pageBounds[0].diff(startTime, 's')) < 15 * interval) {
  //       fetchPreviousPage();
  //     }
  //     if (Math.abs(pageBounds[1].diff(endTime, 's')) < 15 * interval) {
  //       fetchNextPage();
  //     }
  //   }
  // }, [
  //   fetchNextPage,
  //   fetchPreviousPage,
  //   interval,
  //   pageBounds,
  //   timeSelection.streamingTimeInSeconds,
  // ]);

  const buildSubFolder = (
    id: number,
    parentId: number,
    subsystem: SubSystem,
    wideSpacing: boolean,
    lastSystem: boolean,
  ) => {
    const subSystemAnomalous = {
      ...subsystem,
      isAnomalous: Boolean(isAnomalousMap[id]),
    } as SubSystemAnomalous;
    return (
      <StyledSubFolderRow
        key={id}
        className={classNames(`folder-row-${id}`, {
          selected: selectedFolders?.includes(id),
        })}
      >
        <SubFolder
          lastSystem={lastSystem}
          wideSpacing={wideSpacing}
          subsystem={subSystemAnomalous}
          handleCheckboxCheck={() => {
            track('Monitor - Select SubSystem', {
              selectedSubSystemId: id,
              selectedSubSystemName: subsystem.name,
              selectedParentId: parentId,
            });
            handleCheckboxCheck(id, parentId);
          }}
          handleAnalyzeLink={() => {
            track('Monitor - Go to Analyze via SubSystem', {
              heatmap: 'clicked',
              subsystemId: id,
            });
            handleAnalyzeLink(id);
            !showMonitorLimits ? setShowLimits(showMonitorLimits) : setShowLimits(!showLimits);
          }}
          isChecked={selectedFolders?.includes(id) || false}
          showAnalyzeLink={!selectedFolders.includes(id)}
        />
        <FolderHeatmap>
          {!isLoadingHeatmapData && (
            <Heatmap
              wideSpacing={wideSpacing}
              handleAnalyzeLink={() => {
                handleAnalyzeLink(id);
                !showMonitorLimits ? setShowLimits(showMonitorLimits) : setShowLimits(!showLimits);
              }}
              folderId={id}
              heatmapData={heatmapData}
              labeledEvents={labeledEvents}
              interval={interval}
              fetchNextPage={fetchNextPage}
              fetchPreviousPage={fetchPreviousPage}
            />
          )}
        </FolderHeatmap>
      </StyledSubFolderRow>
    );
  };

  return (
    <>
      {folders?.subfolders?.map((system: System<SubSystem>) => (
        <Collapsible
          key={system.folder}
          open
          description={system?.description}
          name={system?.name}
          className={`folder-container-${system.folder}`}
          isChecked={selectedFolders?.includes(system.folder) || false}
          handleCheckboxCheck={() => {
            track('Monitor - Select System', {
              selectedSystemId: system.folder,
              selectedSystemName: system.name,
            });
            handleHeaderCheckboxCheck(system.folder);
          }}
          hasChildren={system.subfolders.length > 0}
        >
          <FolderSystems className="folder-systems">
            {system.subfolders.map((subsystem: SubSystem, index: number) => {
              const lastSystem =
                system.subfolders.length > 1 && index === system.subfolders.length - 1;
              const wideSpacing =
                folders?.subfolders?.length === 1 && system.subfolders.length <= 6;
              return buildSubFolder(
                subsystem.folder,
                system.folder,
                subsystem,
                wideSpacing,
                lastSystem,
              );
            })}
          </FolderSystems>
        </Collapsible>
      ))}
    </>
  );
};
