import lazy from 'react-lazy-with-preload';

import MonitorComponent from './monitor/monitor';
import RedirectComponent from './redirect/redirect';

// Default
export const Redirect = RedirectComponent;
export const Monitor = MonitorComponent;

// Lazy
export const Analyze = lazy(() => import('./analyze/analyze'));
export const Investigate = lazy(() => import('../../app-v2/pages/investigate/investigate'));
export const TimeSearch = lazy(() => import('./time-search/time-search'));
export const AlertPage = lazy(() => import('./alert/alert'));
export const LimitPage = lazy(() => import('./limits/limits'));
export const Share = lazy(() => import('./share/share'));
