import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useCallback, useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { matchPath, useLocation } from 'react-router-dom';

import { Paths } from '../constants/paths';
import { TimePresetMap } from '../constants/time-selection-form';
import { AnalyzeChartContext } from '../context/analyze-chart-context';
import { AnalyzeContext } from '../context/analyze-context';
import { AppContext } from '../context/app-context';
import { EnsembleContext } from '../context/ensemble-context';
import { MonitorContext } from '../context/monitor-context';
import { useTenant } from '../context/tenant-context';
import { getView, saveView } from '../services/tenants';

import {
  AnalyzeSavedView,
  MonitorSavedView,
  PersistTimeSelection,
  PersistView,
  View,
  ViewType,
  TimePresetKeys,
  TimePresetKeyType,
  TimeRanges,
} from '@controlrooms/models';
import { unique } from '@controlrooms/utils';

export const useGetView = (hash: string) => {
  const key = ['view', hash];
  return useQuery(key, () => getView(hash), {
    enabled: Boolean(hash),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const useGetDefaultView = () => {
  return useCallback((viewType: string | undefined) => {
    const now = dayjs();
    return viewType === ViewType.MONITOR
      ? {
          id: '1',
          type: ViewType.MONITOR,
          name: 'All Systems',
          pinnedTags: [],
          selectedFolders: [],
          showMonitorLimits: false,
          timeSelection: {
            startTime: now.subtract(12, 'hour').toISOString(),
            endTime: now.toISOString(),
            timezone: dayjs.tz.guess(), // Default to user timezone
            timeRange: TimeRanges.PRESET,
            streamingTimeInSeconds: TimePresetMap.get(TimePresetKeys.LAST_TWELVE_HOURS),
          },
        }
      : {
          id: '1',
          type: ViewType.ANALYZE,
          name: 'Default View',
          pinnedTags: [],
          selectedFolders: [],
          showAnalyzeLimits: true,
          timeSelection: {
            startTime: now.subtract(12, 'hour').toISOString(),
            endTime: now.toISOString(),
            timezone: dayjs.tz.guess(), // Default to user timezone
            timeRange: TimeRanges.PRESET,
            streamingTimeInSeconds: TimePresetMap.get(TimePresetKeys.LAST_TWELVE_HOURS),
          },
        };
  }, []);
};

export const useSaveView = (
  onSuccess?: (data: string) => Promise<unknown> | void,
  onError?: (error: AxiosError) => Promise<unknown> | void,
) => {
  return useMutation((view: PersistView) => saveView(view), { onSuccess, onError });
};

const useBuildBaseView = () => {
  const { pathname } = useLocation();
  const { analyzeTimeSelection, monitorTimeSelection } = useContext(AppContext);
  const { selectedEnsemble } = useContext(EnsembleContext);
  const {
    selectedFolders: selectedFoldersM,
    severityFilter: severityFilterM,
    showMonitorLimits: showMonitorLimits,
  } = useContext(MonitorContext);
  const {
    selectedFolders: selectedFoldersA,
    pinnedTags,
    showAnalyzeLimits: showAnalyzeLimits,
  } = useContext(AnalyzeContext);
  const { hiddenTags } = useContext(AnalyzeChartContext);

  return useCallback(() => {
    const isAnalyze = matchPath(Paths.ANALYZE, pathname);

    const rest = isAnalyze
      ? {
          selectedFolders: selectedFoldersA,
          pinnedTags,
          hiddenTags,
          showAnalyzeLimits,
        }
      : {
          selectedFolders: selectedFoldersM,
          severityFilter: severityFilterM,
          showMonitorLimits: showMonitorLimits,
        };

    const timeSelection = isAnalyze ? analyzeTimeSelection : monitorTimeSelection;
    const type = isAnalyze ? ViewType.ANALYZE : ViewType.MONITOR;

    return {
      type,
      timeSelection: PersistTimeSelection.ofTimeSelection(timeSelection),
      ensemble_family_id: selectedEnsemble?.family_id,
      ...rest,
    } as View;
  }, [
    analyzeTimeSelection,
    monitorTimeSelection,
    pathname,
    pinnedTags,
    selectedFoldersA,
    selectedFoldersM,
    severityFilterM,
    showMonitorLimits,
    hiddenTags,
    showAnalyzeLimits,
    selectedEnsemble,
  ]);
};

export const useBuildSavedView = () => {
  const _buildBaseView = useBuildBaseView();

  return useCallback(
    (name: string, preset: TimePresetKeyType | null) => {
      const _view = _buildBaseView();

      if (preset && TimePresetMap.get(preset)) {
        const now = dayjs();
        _view.timeSelection = {
          ..._view.timeSelection,
          startTime: now.subtract(TimePresetMap.get(preset) as number, 'seconds').toISOString(),
          endTime: now.toISOString(),
          timePreset: preset,
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: TimePresetMap.get(preset),
        };
      }

      return {
        ..._view,
        name: name,
        id: unique(),
      } as MonitorSavedView | AnalyzeSavedView;
    },
    [_buildBaseView],
  );
};

export const useBuildSharedView = () => {
  const _buildBaseView = useBuildBaseView();
  const { tenant } = useTenant();

  return useCallback(() => {
    const _view = _buildBaseView();

    return {
      tenant_id: tenant,
      view: _view,
    } as PersistView;
  }, [_buildBaseView, tenant]);
};
