import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';

import { NotificationData } from '../components/header/banners/notifications/notification-banner';
import { Paths } from '../constants/paths';
import { TimePresetMap } from '../constants/time-selection-form';
import { useLatestData } from '../hooks/folders';
import { useTenants } from '../hooks/tenants';
import { getAppRouteParams, isDemoApp } from '../utils/app';

import { useTenant } from './tenant-context';

import { usePrevious, useSessionStorage } from '@controlrooms/hooks';
import {
  AnalyzeSessionStorage,
  MonitorSessionStorage,
  SeverityFilter,
  TimePresetKeys,
  TimeRanges,
  TimeSelection,
  Environment,
  EnvStatus,
  AnalyzeView,
  MonitorView,
  PersistTimeSelection,
  ViewType,
} from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

dayjs.extend(timezone);

interface ContextProps {
  timeSelectionHistory: TimeSelection[];
  analyzeSessionStorage: AnalyzeSessionStorage;
  monitorSessionStorage: MonitorSessionStorage;
  setAnalyzeSessionStorage: Dispatch<SetStateAction<AnalyzeSessionStorage>>;
  setMonitorSessionStorage: Dispatch<SetStateAction<MonitorSessionStorage>>;
  analyzeTimeSelection: TimeSelection;
  monitorTimeSelection: TimeSelection;
  setTimelineHistory: Dispatch<SetStateAction<Array<TimeSelection>>>;
  recordTimelineHistory: (timeline: TimeSelection) => void;
  setAnalyzeTimeSelection: Dispatch<SetStateAction<TimeSelection>>;
  setMonitorTimeSelection: Dispatch<SetStateAction<TimeSelection>>;
  isCustomViewFormVisible: boolean;
  setIsCustomViewFormVisible: Dispatch<SetStateAction<boolean>>;
  isStreaming: boolean;
  setIsStreaming: Dispatch<SetStateAction<boolean>>;
  currentEnvironment: Environment | undefined;
  setCurrentEnvironment: Dispatch<SetStateAction<Environment | undefined>>;
  notification: NotificationData | string | undefined;
  setNotification: Dispatch<SetStateAction<NotificationData | string | undefined>>;
  resetStorageState: () => void;
  // setCurrentTimeStamp: () => void;
  tagsFilterCount: number;
  setTagsFilterCount: Dispatch<SetStateAction<number>>;
  newestLoadedData: { timestamp: string | null } | undefined;
  searchMode: boolean;
  setSearchMode: Dispatch<SetStateAction<boolean>>;
  showLimits: boolean;
  setShowLimits: Dispatch<SetStateAction<boolean>>;
}

const defaultTimeSelections: TimeSelection = {
  startTime: dayjs().subtract(12, 'hour'),
  endTime: dayjs(),
  timezone: dayjs.tz.guess(), // Default to user timezone
  timeRange: TimeRanges.PRESET,
  streamingTimeInSeconds: TimePresetMap.get(TimePresetKeys.LAST_TWELVE_HOURS),
  nowSelected: false,
};

const sessionStorageDefaultState = {
  analyzeView: {
    timeSelection: defaultTimeSelections,
    timeSelectionHistory: [],
    selectedFolders: [],
    pinnedTags: [],
    currentFolder: null,
    currentTenant: null,
    showAnalyzeLimits: true,
    selectedViewTitle: 'Default View',
  },
  monitorView: {
    timeSelection: defaultTimeSelections,
    selectedFolders: [],
    severityFilter: SeverityFilter.LOW,
    showMonitorLimits: false,
    currentTenant: null,
    initialMonitorStorage: null,
    selectedLabelTypes: undefined,
    selectedViewTitle: 'All Systems',
  },
};

const defaultState = {
  timeSelectionHistory: [],
  analyzeSessionStorage: sessionStorageDefaultState.analyzeView,
  monitorSessionStorage: sessionStorageDefaultState.monitorView,
  setAnalyzeSessionStorage: () => null,
  setMonitorSessionStorage: () => null,
  analyzeTimeSelection: defaultTimeSelections,
  monitorTimeSelection: defaultTimeSelections,
  setTimelineHistory: () => null,
  recordTimelineHistory: () => null,
  setAnalyzeTimeSelection: () => null,
  setMonitorTimeSelection: () => null,
  isCustomViewFormVisible: false,
  setIsCustomViewFormVisible: () => null,
  isStreaming: false,
  setIsStreaming: () => null,
  currentEnvironment: undefined,
  setCurrentEnvironment: () => null,
  notification: undefined,
  setNotification: () => null,
  resetStorageState: () => null,
  // setCurrentTimeStamp: () => null,
  newestLoadedData: undefined,
  tagsFilterCount: 0,
  setTagsFilterCount: () => null,
  searchMode: false,
  setSearchMode: () => null,
  showLimits: false,
  setShowLimits: () => null,
};

interface AppRouteState {
  view?: AnalyzeView | MonitorView;
}

export const AppContext = createContext<ContextProps>(defaultState);

const AppContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const view = (location.state as AppRouteState)?.view;
  const { tenant: currentTenantId } = useTenant();
  const { currentTenant, tenants } = useTenants();
  const previousSelectedTenant = usePrevious(currentTenantId);
  const tenantId = getAppRouteParams(location.pathname)?.params?.tenantId;
  const isDemo = isDemoApp(location.pathname);
  const [search] = useSearchParams();
  const isReport = search.get('report');

  const isAnalyze =
    !!matchPath(Paths.ANALYZE, location.pathname) ||
    !!matchPath(Paths.DEMO_ANALYZE, location.pathname);
  console.debug('debug: isAnalyze>>>', isAnalyze);

  const initialTimeFrameSec = isDemo
    ? 14400
    : view?.timeSelection?.streamingTimeInSeconds ||
      currentTenant?.preferences?.initialTimeframeSec ||
      43200; //Default is 12 hours
  console.debug('debug: initialTimeFrameSec>>>', initialTimeFrameSec);

  const analyzeViewFromSession = sessionStorage.getItem('analyzeView');
  const monitorViewFromSession = sessionStorage.getItem('monitorView');
  const analyzeSessionTimeSelection =
    analyzeViewFromSession && JSON.parse(analyzeViewFromSession).timeSelection;
  const monitorSessionTimeSelection =
    monitorViewFromSession && JSON.parse(monitorViewFromSession).timeSelection;

  const initialAnalyzeStorage = {
    ...sessionStorageDefaultState.analyzeView,
    timeSelection: {
      ...(analyzeSessionTimeSelection ? analyzeSessionTimeSelection : {}),
      ...sessionStorageDefaultState.analyzeView.timeSelection,
    },
  };
  const initialMonitorStorage = {
    ...sessionStorageDefaultState.monitorView,
    timeSelection: {
      ...(monitorSessionTimeSelection ? monitorSessionTimeSelection : {}),
      ...sessionStorageDefaultState.monitorView.timeSelection,
    },
    selectedLabelTypes: undefined,
  };

  console.debug('debug: initialAnalyzeStorage>>>', initialAnalyzeStorage);
  console.debug('debug: initialMonitorStorage>>>', initialMonitorStorage);

  const [searchMode, setSearchMode] = useState(defaultState.searchMode);
  const [isStreaming, setIsStreaming] = useState<boolean>(defaultState.isStreaming);
  const [isTenantSwitching, setTenantSwitchingStatus] = useState<boolean>(false);

  const [currentEnvironment, setCurrentEnvironment] = useState<Environment | undefined>(
    defaultState.currentEnvironment,
  );

  const [showLimits, setShowLimits] = useState<boolean>(isReport ? true : defaultState.showLimits);

  const [analyzeSessionStorage, setAnalyzeSessionStorage] =
    useSessionStorage<AnalyzeSessionStorage>('analyzeView', initialAnalyzeStorage);

  const [monitorSessionStorage, setMonitorSessionStorage] =
    useSessionStorage<MonitorSessionStorage>('monitorView', initialMonitorStorage);

  const [timeSelectionHistory, setTimelineHistory] = useState<TimeSelection[]>(() => {
    if (analyzeSessionStorage.timeSelectionHistory.length > 0) {
      return analyzeSessionStorage.timeSelectionHistory.map((timeHistory) => ({
        ...timeHistory,
        startTime: dayjs(timeHistory.startTime),
        endTime: dayjs(timeHistory.endTime),
      }));
    } else return analyzeSessionStorage.timeSelectionHistory;
  });

  const initializeTimeSelection = (
    viewType: ViewType,
    sessionData: AnalyzeSessionStorage | MonitorSessionStorage,
    view: AnalyzeView | MonitorView | undefined,
  ): TimeSelection => {
    return view?.type === viewType
      ? PersistTimeSelection.toTimeSelection(view.timeSelection)
      : {
          ...sessionData.timeSelection,
          startTime: dayjs(sessionData.timeSelection.startTime),
          endTime: dayjs(sessionData.timeSelection.endTime),
        };
  };

  const [analyzeTimeSelection, setAnalyzeTimeSelection] = useState<TimeSelection>(() =>
    initializeTimeSelection(ViewType.ANALYZE, analyzeSessionStorage, view),
  );

  const [monitorTimeSelection, setMonitorTimeSelection] = useState<TimeSelection>(() =>
    initializeTimeSelection(ViewType.MONITOR, monitorSessionStorage, view),
  );

  console.debug('debug: analyzeTimeSelection>>>', analyzeTimeSelection);
  console.debug('debug: monitorTimeSelection>>>', monitorTimeSelection);

  const [notification, setNotification] = useState<NotificationData | string | undefined>(
    defaultState.notification,
  );

  const [isCustomViewFormVisible, setIsCustomViewFormVisible] = useState<boolean>(false);

  const recordTimelineHistory = useCallback(
    (timeline: TimeSelection) => {
      setTimelineHistory([...timeSelectionHistory, timeline]);
    },
    [timeSelectionHistory],
  );

  const { data: newestLoadedData } = useLatestData(
    currentEnvironment?.isBatch,
    currentTenant?.status === EnvStatus.RUNNING_WITH_DATA,
  );

  const resetStorageState = useCallback(() => {
    setAnalyzeTimeSelection(
      initializeTimeSelection(ViewType.ANALYZE, sessionStorageDefaultState.analyzeView, view),
    );
    setMonitorTimeSelection(
      initializeTimeSelection(ViewType.MONITOR, sessionStorageDefaultState.monitorView, view),
    );

    sessionStorage.clear();
    setAnalyzeSessionStorage(initialAnalyzeStorage);
    setMonitorSessionStorage(initialMonitorStorage);
    // eslint-disable-next-line
  }, []);

  const [tagsFilterCount, setTagsFilterCount] = useState<number>(defaultState.tagsFilterCount);

  /**
   * Time selection for Demo Plant
   */
  useEffect(() => {
    if (isDemo) {
      setMonitorTimeSelection((prevState) => ({
        ...prevState,
        timeRange: TimeRanges.PRESET,
        streamingTimeInSeconds: initialTimeFrameSec,
        startTime: dayjs().subtract(initialTimeFrameSec, 'seconds'),
      }));
      setAnalyzeTimeSelection((prevState) => ({
        ...prevState,
        timeRange: TimeRanges.PRESET,
        streamingTimeInSeconds: initialTimeFrameSec,
        startTime: dayjs().subtract(initialTimeFrameSec, 'seconds'),
      }));
    }
  }, [initialTimeFrameSec, isDemo]);

  /**
   * Core logic on time selection
   */
  useEffect(() => {
    if (
      previousSelectedTenant == 0 &&
      currentTenantId !== 0 &&
      currentTenant?.preferences?.initialTimeframeSec
    ) {
      //New Login state
      setMonitorTimeSelection((prevState) => {
        console.debug('debug: New Login state Monitor>>>', {
          ...prevState,
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: initialTimeFrameSec,
          startTime: dayjs().subtract(initialTimeFrameSec, 'seconds'),
        });

        return {
          ...prevState,
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: initialTimeFrameSec,
          startTime: dayjs().subtract(initialTimeFrameSec, 'seconds'),
        };
      });
      setAnalyzeTimeSelection((prevState) => {
        console.debug('debug: New Login state Analyze>>>', {
          ...prevState,
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: initialTimeFrameSec,
          startTime: dayjs().subtract(initialTimeFrameSec, 'seconds'),
        });
        return {
          ...prevState,
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: initialTimeFrameSec,
          startTime: dayjs().subtract(initialTimeFrameSec, 'seconds'),
        };
      });
    } else if (previousSelectedTenant === undefined && currentTenantId) {
      // Page refresh / url change / Share gets into this section
      if (
        Number(monitorSessionStorage?.currentTenant) !== Number(tenantId) ||
        Number(analyzeSessionStorage?.currentTenant) !== Number(tenantId)
      ) {
        console.debug('debug: Tenant switch - URL manipulation>>>');
        //Tenant switch by manipulating the URL
        setTenantSwitchingStatus(true);
        return;
      }
      const timeselectionFn = isAnalyze ? setAnalyzeTimeSelection : setMonitorTimeSelection;
      const localStore = isAnalyze ? analyzeSessionStorage : monitorSessionStorage;
      timeselectionFn((prevState) => {
        if (localStore.timeSelection.timeRange === TimeRanges.PRESET) {
          return {
            ...prevState,
            timeRange: TimeRanges.PRESET,
            streamingTimeInSeconds: localStore.timeSelection.streamingTimeInSeconds,
          };
        } else {
          return {
            ...prevState,
            timeRange: TimeRanges.CUSTOM,
            streamingTimeInSeconds: 0,
            startTime: dayjs(localStore.timeSelection.startTime),
            endTime: dayjs(localStore.timeSelection.endTime),
          };
        }
      });
      // }
    } else if (previousSelectedTenant && previousSelectedTenant !== currentTenantId) {
      //Tenant switch by selecting the tenant from the dropdown
      console.debug('debug: Tenant switch - Manual>>>');
      setTenantSwitchingStatus(true);
    }
  }, [
    analyzeSessionStorage,
    analyzeSessionStorage?.currentTenant,
    currentTenant,
    currentTenantId,
    initialTimeFrameSec,
    isAnalyze,
    isDemo,
    monitorSessionStorage,
    monitorSessionStorage?.currentTenant,
    previousSelectedTenant,
    tenantId,
    tenants,
  ]);

  /**
   * Once the Tenant is switched, and aftet the new tenant config is fetched
   * set the tenant time selection
   */
  useEffect(() => {
    if (isTenantSwitching && currentTenant?.preferences) {
      console.debug('debug: Tenant switch - currentTenant pref: ', currentTenant?.preferences);
      setMonitorTimeSelection((prevState) => {
        console.debug('debug: Tenant switch - Set Monitor timeselection -', {
          ...prevState,
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: initialTimeFrameSec,
          startTime: dayjs().subtract(initialTimeFrameSec, 'seconds'),
        });
        return {
          ...prevState,
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: initialTimeFrameSec,
          startTime: dayjs().subtract(initialTimeFrameSec, 'seconds'),
        };
      });
      setAnalyzeTimeSelection((prevState) => {
        console.debug('debug: Tenant switch - Set Monitor timeselection -', {
          ...prevState,
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: initialTimeFrameSec,
          startTime: dayjs().subtract(initialTimeFrameSec, 'seconds'),
        });
        return {
          ...prevState,
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: initialTimeFrameSec,
          startTime: dayjs().subtract(initialTimeFrameSec, 'seconds'),
        };
      });
      setTenantSwitchingStatus(false);
    }
  }, [
    currentTenant?.preferences,
    currentTenant?.preferences.initialTimeframeSec,
    initialTimeFrameSec,
    isTenantSwitching,
  ]);

  /**
   * Update the Time selection history
   */
  useEffect(() => {
    setAnalyzeSessionStorage((prevState) => {
      return {
        ...prevState,
        timeSelectionHistory: timeSelectionHistory,
      };
    });
    // eslint-disable-next-line
  }, [timeSelectionHistory]);

  /**
   * Update the session storage when the timeselection is updated
   */
  useEffect(() => {
    setAnalyzeSessionStorage((prevState) => {
      return {
        ...prevState,
        timeSelection: analyzeTimeSelection,
        currentTenant: currentTenant?.id,
      };
    });
    setMonitorSessionStorage((prevState) => {
      return {
        ...prevState,
        timeSelection: monitorTimeSelection,
        currentTenant: currentTenant?.id,
      };
    });
  }, [
    monitorTimeSelection,
    analyzeTimeSelection,
    setAnalyzeSessionStorage,
    setMonitorSessionStorage,
    currentTenant?.id,
  ]);

  //For now
  useEffect(() => {
    if (newestLoadedData?.timestamp && !view) {
      const { timestamp } = newestLoadedData;

      const updateTimeSelection = (prev: TimeSelection) => {
        return {
          ...prev,
          endTime: TimeUtils.toTimezone(timestamp, prev.timezone),
          startTime: TimeUtils.toTimezone(timestamp, prev.timezone).subtract(
            initialTimeFrameSec,
            'seconds',
          ),
          streamingTimeInSeconds: undefined,
          timeRange: TimeRanges.CUSTOM,
        };
      };

      setMonitorTimeSelection((prev) => updateTimeSelection(prev));
      setAnalyzeTimeSelection((prev) => updateTimeSelection(prev));
    }
    // eslint-disable-next-line
  }, [view, newestLoadedData, newestLoadedData?.timestamp, currentTenant, initialTimeFrameSec]);

  useEffect(
    () =>
      setIsStreaming(
        analyzeTimeSelection.streamingTimeInSeconds !== undefined ||
          monitorTimeSelection.streamingTimeInSeconds !== undefined,
      ),
    [analyzeTimeSelection, monitorTimeSelection],
  );

  /**
   * Set the timeselection from the shared url
   */
  useEffect(() => {
    if (!view) {
      return;
    }
    console.info('Setting timeselection from a shared url---');
    const now = dayjs();
    if (view.timeSelection.timeRange === TimeRanges.PRESET) {
      view.timeSelection.startTime = now
        .subtract(view.timeSelection.streamingTimeInSeconds as number, 'seconds')
        .toISOString();
      view.timeSelection.endTime = now.toISOString();
    }
    if (view && view.type === ViewType.ANALYZE) {
      setAnalyzeTimeSelection(PersistTimeSelection.toTimeSelection(view.timeSelection));
      setAnalyzeSessionStorage((prevState) => {
        return {
          ...prevState,
          timeSelection: PersistTimeSelection.toTimeSelection(view.timeSelection),
        };
      });
    } else if (view && view.type === ViewType.MONITOR) {
      setMonitorTimeSelection(PersistTimeSelection.toTimeSelection(view.timeSelection));
      setMonitorSessionStorage((prevState) => {
        return {
          ...prevState,
          timeSelection: PersistTimeSelection.toTimeSelection(view.timeSelection),
        };
      });
    }
  }, [setAnalyzeSessionStorage, setMonitorSessionStorage, view]);

  const appState = useMemo(
    () => ({
      analyzeSessionStorage,
      monitorSessionStorage,
      setAnalyzeSessionStorage,
      setMonitorSessionStorage,
      timeSelectionHistory,
      analyzeTimeSelection,
      monitorTimeSelection,
      setTimelineHistory,
      recordTimelineHistory,
      setAnalyzeTimeSelection,
      setMonitorTimeSelection,
      isStreaming,
      setIsStreaming,
      currentEnvironment,
      setCurrentEnvironment,
      isCustomViewFormVisible,
      setIsCustomViewFormVisible,
      notification,
      setNotification,
      resetStorageState,
      // setCurrentTimeStamp,
      newestLoadedData,
      tagsFilterCount,
      setTagsFilterCount,
      searchMode,
      setSearchMode,
      showLimits,
      setShowLimits,
    }),
    // eslint-disable-next-line
    [
      analyzeSessionStorage,
      monitorSessionStorage,
      analyzeTimeSelection,
      monitorTimeSelection,
      recordTimelineHistory,
      timeSelectionHistory,
      isCustomViewFormVisible,
      isStreaming,
      currentEnvironment,
      notification,
      newestLoadedData,
      searchMode,
      showLimits,
      setShowLimits,
    ],
  );

  return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
