import React, { useContext } from 'react';

import { useAnalytics } from '../../analytics';
import { MonitorContext } from '../../context/monitor-context';

import { SeverityFilterContainer, StyledSlider, StyledColorSquare } from './styles';

import { Slider } from '@controlrooms/components';

const SliderLevels = [
  {
    key: 'low',
    label: 'Low',
  },
  {
    key: 'medium',
    label: 'Med',
  },
  {
    key: 'high',
    label: 'High',
  },
];

interface Props {
  label?: string;
  showSlider?: boolean;
}

const SeverityFilter: React.FC<Props> = ({ label, showSlider }) => {
  const { severityFilter, setSeverityFilter } = useContext(MonitorContext);
  const { track } = useAnalytics();
  return (
    <SeverityFilterContainer>
      {label ? (
        <span className="label">{label}</span>
      ) : (
        <span className="label">
          Severity
          <br />
          Threshold
        </span>
      )}
      <div>
        <StyledSlider>
          {SliderLevels.map((level) => {
            return (
              <div className="level-container" key={level.key}>
                <StyledColorSquare color={level.key} />
                <span className="severity-label">{level.label}</span>
              </div>
            );
          })}
        </StyledSlider>
        {showSlider && (
          <Slider
            tooltip="Drag to adjust display threshold"
            testid="severity-treshhold"
            value={severityFilter}
            onChange={(e) => {
              track('Monitor - Severity Filter', {
                severityFilterIndex: severityFilter,
              });
              setSeverityFilter(e);
            }}
          />
        )}
      </div>
    </SeverityFilterContainer>
  );
};

export default SeverityFilter;
