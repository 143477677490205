// eslint-disable-next-line
import { PublicClientApplication } from '@azure/msal-browser';
// eslint-disable-next-line
import { MsalProvider } from '@azure/msal-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import App from './app/app';
import { msalConfig } from './app/services/ms-teams';
import reportWebVitals from './reportWebVitals';

const msalInstance = new PublicClientApplication(msalConfig);

Sentry.init({
  dsn: 'https://a3125c2d0b3e41e982a93148dcf9306c@o1305961.ingest.sentry.io/6747757',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// eslint-disable-next-line
// if (!!window.Intercom) {
//   intercomSettings = {
//     app_id: 'odwdpc8a',
//   };
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 15 * 60 * 1000, // 15 minutes
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <IntercomProvider appId={'odwdpc8a'}>
        <QueryClientProvider client={queryClient}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </QueryClientProvider>
      </IntercomProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
