import { AxiosResponseHeaders, AxiosHeaderValue } from 'axios';

import { ApiListResult } from '../api';

export type CustomHeader = AxiosResponseHeaders | Partial<AxiosHeaderValue>;

export class MsTeam {
  team_id: string;
  team_name?: string;
  constructor(init: any) {
    this.team_id = init.id;
    this.team_name = init.displayName || '';
  }
}

export class MsChannel {
  channel_id: string;
  channel_name: string;
  email?: string;
  constructor(init: any, email?: string) {
    this.channel_id = init.id;
    this.channel_name = init.displayName;
    this.email = email || '';
  }
}

export interface MsTeamsChannels extends MsTeam {
  channels: MsChannel[];
}

export type RegisteredChannel = {
  team_id?: string;
  channel_id?: string;
  email?: string;
  channel_name: string;
  tenant_id?: number | string;
};

export interface sortedTeamsChannel {
  team_id: string;
  channels: RegisteredChannel[];
}

export class RegisteredChannelResult implements ApiListResult<RegisteredChannel> {
  result: RegisteredChannel[];
  request: Record<string, string | number>;
  headers: CustomHeader;
  constructor(init: ApiListResult<RegisteredChannel>) {
    this.result = init.result ?? [];
    this.request = init.request as Record<string, string | number>;
    this.headers = init.headers;
  }
}
