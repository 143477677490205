import * as d3 from 'd3';
import { Dayjs } from 'dayjs';

import { groupStringDataByValues, truncateStringLabels } from './chart-utils';

import { SVGDefsSelection, TimeSeries } from '@controlrooms/models';

export const buildStringLine = (
  chart: SVGDefsSelection,
  xScale: d3.ScaleTime<number, number, never>,
  yScale: d3.ScaleLinear<number, number, never>,
  stringData: TimeSeries[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any,
  endTime: Dayjs,
  chartHeight?: number,
) => {
  if (!stringData) return;
  const segments = groupStringDataByValues(stringData, endTime);

  const colors = [
    theme.chart.stringChartBackground.color1,
    theme.chart.stringChartBackground.color2,
  ];

  let lastValue = null;
  let colorIndex = 0;
  let currentColor = colors[0];

  chart
    .selectAll('.segment')
    .data(segments)
    .enter()
    .append('rect')
    .attr('x', (d) => xScale(new Date(d.start)))
    .attr('width', (d) => Math.max(xScale(new Date(d.end)) - xScale(new Date(d.start)), 1))
    .attr('y', chartHeight ? chartHeight / 2 : 5)
    .attr('height', 20)
    .attr('fill', (d) => {
      if (d.value !== lastValue) {
        colorIndex = (colorIndex + 1) % colors.length;
        currentColor = colors[colorIndex];
        lastValue = d.value;
      }
      return currentColor;
    });

  chart
    .selectAll('.label')
    .data(segments)
    .enter()
    .append('text')
    .attr('x', (d) => xScale(new Date(d.start)) + 5)
    .attr('y', chartHeight ? chartHeight / 2 + 15 : 18)
    .text((d) => d.value)
    .attr('font-size', '10px')
    .attr('fill', 'white')
    .each(function (d) {
      const label = d3.select(this);
      d.labelWidth = this.getComputedTextLength();
      d.segmentWidth = xScale(new Date(d.end)) - xScale(new Date(d.start));
      const ellipsisWidth = 12;
      const minimumWidth = ellipsisWidth + 3;
      const availableSpace = d.segmentWidth - minimumWidth;

      if (availableSpace < d.labelWidth) {
        if (availableSpace < ellipsisWidth) {
          label.style('display', 'none');
        } else {
          const truncatedText = truncateStringLabels(d.value, availableSpace, this);
          label.text(truncatedText);
        }
      }
    });
};
