import React, { SetStateAction, useContext } from 'react';

import { useAnalytics } from '../../../analytics';
import {
  UserPreferencesContext,
  userPreferencesPages,
} from '../../../context/user-preferences-context';

import { Theme } from './pages/theme';
import { UserProfile } from './pages/user-profile';
import { StyledUserPrefs } from './styles';

import { Icon } from '@controlrooms/components';

export const UserPreferences: React.FC = () => {
  const { currentPage, setCurrentPage } = useContext(UserPreferencesContext);
  const { track } = useAnalytics();

  const pageValues = Object.values(userPreferencesPages);

  const toTitleCase = (str: string) => {
    return str.toLowerCase().replace(/(?:^|[\s-/])\w/g, (match: string) => {
      return match.toUpperCase();
    });
  };

  return (
    <StyledUserPrefs>
      <div className="prefs-nav">
        <ul>
          {pageValues.map((value) => {
            return (
              <li data-testid={`${value}-button`} key={value}>
                <button
                  className={currentPage === value ? 'active' : ''}
                  onClick={() => {
                    track('User Preferences', {
                      activeTab: value,
                    });
                    setCurrentPage(value as SetStateAction<userPreferencesPages>);
                  }}
                >
                  {toTitleCase(value)}
                  <Icon name="chevron" rotate={-90} height="6" />
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="prefs-pages">
        {currentPage === userPreferencesPages.THEME && <Theme />}
        {currentPage === userPreferencesPages.USER_PROFILE && <UserProfile />}
      </div>
      <div></div>
    </StyledUserPrefs>
  );
};
