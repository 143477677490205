import { AxiosResponseHeaders, AxiosHeaderValue } from 'axios';

export type ApiTimeServicePayload = {
  start_time?: string;
  end_time?: string;
  preset?: number | undefined;
};

export type CustomHeader = AxiosResponseHeaders | Partial<AxiosHeaderValue>;

export interface IApiResult<T> {
  result: T;
  request: string | Record<string, unknown> | undefined;
  headers: CustomHeader;
}

export class ApiListResult<T> implements IApiResult<T[]> {
  result: T[];
  request: string | Record<string, unknown> | undefined;
  headers: CustomHeader;

  constructor(init: ApiListResult<T>) {
    this.result = init.result;
    this.request = init.request;
    this.headers = init.headers;
  }
}

export class ApiResult<T> implements IApiResult<T> {
  result: T;
  request: string | Record<string, unknown> | undefined;
  headers: CustomHeader;

  constructor(init: ApiResult<T>) {
    this.result = init.result;
    this.request = init.request;
    this.headers = init.headers;
  }
}

export * from './account';
export * from './anomaly';
export * from './batch';
export * from './folder';
export * from './location';
export * from './notification';
export * from './tag';
export * from './limit';
export * from './tenant';
