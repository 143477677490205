import { TimeRangeUtil } from '../utils/time';

import Request from './request';

import { AnomaliesResult, AnomalyPayload } from '@controlrooms/models';

const SERVICE_ROOT = '/tag-explorer/api/v1';

export const getAnomalies = (payload: AnomalyPayload): Promise<AnomaliesResult> => {
  const { preset, ...rest } = payload;
  let _payload = rest;
  if (preset) {
    // Update the start / end time based on the relative time selection
    _payload = {
      ..._payload,
      ...TimeRangeUtil.ofDuration(preset),
    };
  }

  return Request.post(`${SERVICE_ROOT}/anomalies`, _payload, { timeout: 30000 }).then(
    (r) => new AnomaliesResult(r),
  );
};
