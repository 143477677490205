import * as d3 from 'd3';
import dayjs, { Dayjs } from 'dayjs';

import { groupStepLineDataBySameValue, refineStepSeriesData } from './chart-utils';

import { TimeSeries, Line, SVGDefsSelection } from '@controlrooms/models';

export const buildStepLine = (
  chart: SVGDefsSelection,
  xScale: d3.ScaleTime<number, number, never>,
  yScale: d3.ScaleLinear<number, number, never>,
  seriesData: TimeSeries[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any,
  defaultEndTime: Dayjs,
) => {
  const _seriesData = refineStepSeriesData(seriesData, defaultEndTime);

  const lineGenerator: Line<TimeSeries> = d3
    .line<TimeSeries>()
    .curve(d3.curveStepAfter)
    .defined((d) => d.value !== null && typeof d.value === 'number')
    .x((d) => {
      return xScale(dayjs(d.time).valueOf());
    })
    .y((d) => {
      return yScale(d.value);
    });

  chart
    .append('path')
    .datum(_seriesData)
    .attr('fill', 'none')
    .attr('stroke', theme.chart.lineColor)
    .attr('stroke-width', 1.2)
    .attr('d', lineGenerator as Line<TimeSeries>);

  // Prepare group data for displaying labels
  const groupedData = groupStepLineDataBySameValue(_seriesData);

  chart
    .selectAll('.label')
    .data(groupedData)
    .enter()
    .append('text')
    .attr('class', 'label')
    .attr('x', (d) => xScale(dayjs(d.start).valueOf()) + 1)
    .attr('y', (d) => yScale(d.value) - 2)
    .attr('text-anchor', 'start')
    .attr('font-size', '9px')
    .attr('font-family', 'Open Sans, sans-serif')
    .attr('fill', theme.chart.labelColor)
    .text((d) => d.value)
    .each(function (d, i, nodes) {
      const labelWidth = this.getComputedTextLength();
      const nextLabelX =
        i < nodes.length - 1
          ? xScale(dayjs(groupedData[i + 1].start).valueOf())
          : xScale.range()[1];
      const currentLabelX = xScale(dayjs(d.start).valueOf());
      if (nextLabelX - currentLabelX < labelWidth) {
        d3.select(this).style('display', 'none');
      }
    });
};
