import throttle from 'lodash.throttle';
import { useMemo, useRef } from 'react';

export interface ThrottleSettings {
  leading?: boolean | undefined;
  trailing?: boolean | undefined;
}

/**
 * Debounce a function.
 *
 * @param callback the callback function
 * @param wait the debounce wait time
 * @param options throttle settings
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useThrottle = <T extends any[]>(
  callback: (...args: T) => void,
  wait?: number,
  options?: ThrottleSettings,
) => {
  const argsRef = useRef<T>();

  return useMemo(
    () =>
      throttle(
        (...args: T) => {
          argsRef.current = args;
          if (argsRef.current) {
            callback(...argsRef.current);
          }
        },
        wait,
        options,
      ),
    [callback, options, wait],
  );
};

export default useThrottle;
