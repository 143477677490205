import Request from './request';

import { EnvironmentResult, TenantConfig, TenantResult, PersistView } from '@controlrooms/models';

const SERVICE_ROOT = '/accounts-v2';

export const getTenants = (): Promise<TenantResult> =>
  Request.get(`${SERVICE_ROOT}/users/me/tenants`).then((r) => new TenantResult(r));

export const getTenantEnvironment = (): Promise<EnvironmentResult> =>
  Request.get(`${SERVICE_ROOT}/environments/me`).then((r) => new EnvironmentResult(r));

export const getView = (viewId: string): Promise<PersistView> =>
  Request.get(`${SERVICE_ROOT}/views/${viewId}`).then(({ result }) => result);

export const saveView = (view: PersistView): Promise<string> =>
  Request.post(`${SERVICE_ROOT}/views`, view).then(({ result }) => result);

export const getTenantConfig = (): Promise<TenantConfig> =>
  Request.get(`${SERVICE_ROOT}/tenants/config`).then(({ result }) => new TenantConfig(result));
