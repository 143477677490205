import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { matchPath } from 'react-router-dom';

const instance = axios.create({
  baseURL: process.env.REACT_APP_CONTROLROOMS_API,
});
let _tokenFetcher: () => Promise<string>;
let _demoTokenFetcher: () => Promise<string>;

instance.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    const whiteListedURIs = [
      '/accounts-v2/auth0/users/auth/demo',
      'https://hubspot-eucwb5cebqd9fjby.z01.azurefd.net/hubspot/',
    ];
    const isDemo =
      matchPath({ path: '/demo/t/:tenantId/*' }, location.pathname) ||
      matchPath({ path: '/demo/redirect' }, location.pathname);

    const isWhitelisted = whiteListedURIs.some((url) => config.url?.includes(url));
    if (!isWhitelisted && ((isDemo && !_demoTokenFetcher) || (!isDemo && !_tokenFetcher))) {
      return Promise.resolve({
        ...config,
        cancelToken: new axios.CancelToken((cancel) =>
          cancel('Axios API was not initialized with the appropriate token fetching function.'),
        ),
      } as unknown as InternalAxiosRequestConfig);
    }
    try {
      if (isWhitelisted) {
        return Promise.resolve({
          ...config,
          headers: { ...config.headers },
        } as unknown as InternalAxiosRequestConfig);
      }
      const jwt = isDemo ? await _demoTokenFetcher() : await _tokenFetcher();
      return Promise.resolve({
        ...config,
        headers: { ...config.headers, Authorization: `Bearer ${jwt}` },
      } as unknown as InternalAxiosRequestConfig);
    } catch (e) {
      return Promise.resolve({
        ...config,
        cancelToken: new axios.CancelToken((cancel) => cancel('No access token!')),
      } as unknown as InternalAxiosRequestConfig);
    }
  },
  (error: Error) => Promise.reject(error),
);

export default instance;

/**
 * Provide the ability to pass the getAccessTokenSilently function from Auth0 when the app bootstraps.
 * @param tokenFetcher
 */
export const setTokenFetcher = (tokenFetcher: () => Promise<string>) => {
  _tokenFetcher = tokenFetcher;
  return this;
};

export const setDemoTokenFetcher = (tokenFetcher: () => Promise<string>) => {
  _demoTokenFetcher = tokenFetcher;
  return this;
};
