import * as d3 from 'd3';

import { calculateTriangleIndicatorPoints, cleanTagName, dashLimit } from './chart-utils';

import { CRTagMode, Line, Mode, ModeType, SVGDefsSelection } from '@controlrooms/models';

export const buildMode = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chart: SVGDefsSelection,
  xScale: d3.ScaleTime<number, number, never>,
  yScale: d3.ScaleLinear<number, number, never>,
  modes: Mode[] | CRTagMode[],
  // theme: DefaultTheme,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any, // TODO: Export styled components from shared package
  modeType: ModeType,
  tag?: string,
) => {
  const tagName = cleanTagName(tag);
  const stepGenerator = d3
    .line()
    .defined((d) => {
      return (d as unknown as Mode)?.value != null;
    })
    .x((d) => {
      return xScale((d as unknown as Mode)?.start);
    })
    .y((d) => {
      return yScale((d as unknown as Mode)?.value);
    })
    .curve(d3.curveStepAfter) as Line<Mode>;

  const hasOnlyNormalLimits =
    modes.length >= 1 && (modes as Mode[]).every((mode: Mode) => mode.limitCondition === 'n/a');
  const hasConditionalLimits =
    modes.length >= 1 && (modes as Mode[]).some((mode: Mode) => mode.limitCondition !== 'n/a');

  if (hasOnlyNormalLimits) {
    (modes as Mode[]).map((mode, idx) => {
      const customData = [{ ...mode }, { ...mode, start: mode.end }];
      chart
        .append('path')
        .datum(customData)
        .attr('fill', 'none')
        .attr('class', `limit-${modeType}-${tagName}-${idx}`)
        .style(
          'stroke',
          modeType === ModeType.FREQUENT
            ? theme.chart.frequentLineColor
            : theme.chart.limitLineColor,
        )
        .style('stroke-width', '1px')
        .style('stroke-dasharray', dashLimit[modeType])
        .attr('d', stepGenerator);
    });
  }

  if (hasConditionalLimits) {
    const onlyConditionalLimits = (modes as Mode[]).filter(
      (mode: Mode) => mode.limitCondition !== 'n/a',
    );

    let customData = modes;
    if (onlyConditionalLimits.length === 1) {
      customData = [
        { ...onlyConditionalLimits[0] },
        { ...onlyConditionalLimits[0], start: onlyConditionalLimits[0].end },
      ];
    }

    onlyConditionalLimits.map((mode: Mode, index: number) => {
      chart
        .append('path')
        .datum(customData)
        .attr('fill', 'none')
        .attr('class', `conditional-limit-${modeType}-${tagName}`)
        .attr('id', `conditional-limit-${modeType}-${tagName}-${index}`)
        .style('stroke', theme.chart.limitLineColor)
        .style('stroke-width', '1px')
        .style('stroke-dasharray', dashLimit[modeType])
        .attr('d', stepGenerator);

      if (onlyConditionalLimits.length > 0) {
        // const firstDataPoint = modes[0];
        const lastDataPoint = modes[modes.length - 1];

        const drawTriangle = (dataPoint, type) => {
          const x = xScale(dataPoint.start);
          const y = yScale(dataPoint.value);
          const points = calculateTriangleIndicatorPoints(x, y, 5, type);
          chart
            .append('polygon')
            .attr('class', `triangle-${type}-${modeType}-${tagName}-${index}`)
            .attr('points', points)
            .style('fill', theme.chart.limitIndicatorDefault)
            .style('stroke', theme.chart.limitIndicatorDefault);
        };

        // drawTriangle(firstDataPoint, 'start');
        drawTriangle(lastDataPoint, 'end');
      }
    });
  }
};
