import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useContext, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

dayjs.extend(customParseFormat);

import { Paths } from '../../constants/paths';
import { TimePresetMap, getTimePresetMapKey } from '../../constants/time-selection-form';
import { AppContext } from '../../context/app-context';
import { TimeRangeUtil } from '../../utils/time';
import { TimeSelector } from '../timeframe-modal/time-selector';

import {
  Container,
  TimeCard,
  PrimaryLabel,
  SubLabel,
  TimeContainer,
  TimeZone,
  Chevron,
  DurationContainer,
  TimeRangeContainer,
} from './styles';

import { Modal, ModalContents, ModalOpenButton } from '@controlrooms/components';
import {
  DateFormats,
  Fields,
  TimePresetKeyType,
  TimeRangesType,
  TimeRanges,
  TimePresetKeys,
} from '@controlrooms/models';
import { TimeUtils, getQueryParams } from '@controlrooms/utils';

interface FormProps {
  timerange: TimeRangesType;
  preset: TimePresetKeyType | undefined;
  customTimeframe: string;
  timeframe: [dayjs.Dayjs, dayjs.Dayjs] | [];
  timezone: string;
}

export const TimeDisplay: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    analyzeTimeSelection,
    monitorTimeSelection,
    recordTimelineHistory,
    setAnalyzeTimeSelection,
    setMonitorTimeSelection,
  } = useContext(AppContext);

  const isAnalyze =
    !!matchPath(Paths.ANALYZE, pathname) || !!matchPath(Paths.DEMO_ANALYZE, pathname);

  const { startTime, endTime, timezone, streamingTimeInSeconds, timeRange, nowSelected } = isAnalyze
    ? analyzeTimeSelection
    : monitorTimeSelection;
  const setTimeSelection = isAnalyze ? setAnalyzeTimeSelection : setMonitorTimeSelection;

  const [_startTime, _setStartTime] = useState(startTime);

  // Set the time selection from query params.
  const { startTime: qpStartTime, endTime: qpEndTime } = getQueryParams(location.search);

  useEffect(() => {
    if (qpStartTime && qpEndTime) {
      setTimeSelection(() => {
        return {
          startTime: dayjs(qpStartTime),
          endTime: dayjs(qpEndTime),
          timezone: timezone,
          timeRange: TimeRanges.CUSTOM,
          streamingTimeInSeconds: 0,
          relative: false,
        };
      });
    }
    // eslint-disable-next-line
  }, []);

  // Build a clock... basically
  useEffect(() => {
    if (streamingTimeInSeconds) {
      let timer: ReturnType<typeof setTimeout>;
      const tick = (waitMs: number) => {
        timer = setTimeout(() => {
          _setStartTime(dayjs().subtract(streamingTimeInSeconds, 'seconds'));
          tick(1000 - (Date.now() % 1000)); // set up the next tick
        }, waitMs);
      };
      tick(1000 - (Date.now() % 1000));

      const timeContextRefresh =
        streamingTimeInSeconds <= dayjs.duration(4, 'd').asSeconds() ? 30000 : 300000;
      const timeContextId = setInterval(() => {
        const now = dayjs();
        setTimeSelection((prevState) => ({
          ...prevState,
          timezone: timezone,
          startTime: now.subtract(streamingTimeInSeconds as number, 'seconds'),
          endTime: now,
          timeRange: timeRange,
          streamingTimeInSeconds: streamingTimeInSeconds,
        }));
      }, timeContextRefresh);
      return () => {
        timer && clearTimeout(timer);
        clearInterval(timeContextId);
      };
    }
  }, [streamingTimeInSeconds, startTime, setTimeSelection, timezone, timeRange]);

  useEffect(() => {
    _setStartTime(startTime);
  }, [startTime]);

  const methods = useForm<FormProps>({
    mode: 'onChange',
    defaultValues: {
      [Fields.TIMERANGE]: timeRange,
      [Fields.PRESET]:
        getTimePresetMapKey(streamingTimeInSeconds) ?? TimePresetKeys.LAST_TWELVE_HOURS,
      [Fields.CUSTOM_TIMEFRAME]:
        timeRange === TimeRanges.CUSTOM
          ? `${TimeUtils.toTimezone(startTime, timezone).format(
              DateFormats.DATETIME_ALT,
            )} - ${TimeUtils.toTimezone(endTime, timezone).format(DateFormats.DATETIME_ALT)}`
          : '',
      [Fields.TIMEFRAME]:
        timeRange === TimeRanges.CUSTOM
          ? [TimeUtils.toTimezone(startTime, timezone), TimeUtils.toTimezone(endTime, timezone)]
          : [],
      [Fields.TIMEZONE]: timezone,
    },
  });

  const onConfirm = () => {
    const errors = methods.formState.errors;
    if (Object.values(errors).length) return false;

    // Reset view if the time is updated
    navigate('.', { replace: true });

    if (methods.getValues(Fields.TIMERANGE as 'timerange') === TimeRanges.PRESET) {
      const _streamSeconds = TimePresetMap.get(
        methods.getValues(Fields.PRESET) as TimePresetKeyType,
      ) as number;
      const now = dayjs();
      setTimeSelection((prevState) => {
        recordTimelineHistory(prevState);
        return {
          ...prevState,
          startTime: now.subtract(_streamSeconds, 'seconds'),
          endTime: now,
          timezone: methods.getValues(Fields.TIMEZONE as 'timezone'),
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: _streamSeconds,
          relative: true,
        };
      });
      return true;
    } else {
      const _timezone = methods.getValues(Fields.TIMEZONE as 'timezone');
      const _timeframe = methods.getValues(Fields.CUSTOM_TIMEFRAME as 'customTimeframe');

      if (!_timeframe || _timeframe.length === 0) {
        methods.setError(Fields.CUSTOM_TIMEFRAME as 'customTimeframe', { type: 'validate' });
        return false;
      }
      const _tokens = _timeframe.split(' - ');
      if (!_timeframe.match(/^[^a-zA-Z]+$/) || _tokens.length !== 2) {
        methods.setError(Fields.CUSTOM_TIMEFRAME as 'customTimeframe', { type: 'validate' });
        return false;
      }

      const _start = dayjs(_tokens[0], DateFormats.DATETIME_ALT);
      if (!_start.isValid()) {
        methods.setError(Fields.CUSTOM_TIMEFRAME as 'customTimeframe', {
          type: 'custom',
          message: 'Invalid start time',
        });
        return false;
      }

      const _end = dayjs(_tokens[1], DateFormats.DATETIME_ALT);
      if (!_end.isValid()) {
        methods.setError(Fields.CUSTOM_TIMEFRAME as 'customTimeframe', {
          type: 'custom',
          message: 'Invalid end time',
        });
        return false;
      }

      methods.clearErrors();
      setTimeSelection((prevState) => {
        recordTimelineHistory(prevState);
        return {
          ...prevState,
          timezone: _timezone,
          startTime: _start.tz(_timezone, true),
          endTime: _end.tz(_timezone, true),
          timeRange: TimeRanges.CUSTOM,
          streamingTimeInSeconds: 0,
          relative: false,
        };
      });
      return true;
    }
  };

  const preparePrimaryLabel = () => {
    const hoursDisplay = TimeRangeUtil.calculateTimePreset('label', streamingTimeInSeconds);
    if (nowSelected && !hoursDisplay) {
      return (
        <>
          {TimeUtils.toTimezone(startTime, timezone).format(DateFormats.DATE)}{' '}
          <span className="time">
            {TimeUtils.toTimezone(startTime, timezone).format(DateFormats.TIME)}
          </span>{' '}
          - Now
        </>
      );
    }
    return hoursDisplay || TimeUtils.toTimezone(_startTime, timezone).format(DateFormats.TIME);
  };

  const isStreamingSpanningDays = () => {
    return dayjs()
      .subtract(streamingTimeInSeconds as number, 'seconds')
      .isBefore(dayjs().subtract(1, 'day').endOf('day'));
  };

  // change the calculation to account for daylight saving times in certain date selections
  const calculateForDST = () => {
    const startTimeUtcOffset = startTime.utcOffset();
    const endTimeUtcOffset = endTime.utcOffset();
    const dstAdjustment = (endTimeUtcOffset - startTimeUtcOffset) / 60;

    const totalHours = endTime.diff(startTime, 'hours') + dstAdjustment;
    return {
      seconds: Math.floor(endTime.diff(startTime, 's') % 60),
      minutes: Math.floor(endTime.diff(startTime, 'm') % 60),
      hours: totalHours % 24,
      days: Math.floor(totalHours / 24),
    };
  };

  const getTimeDiff = calculateForDST();

  const getDurationContent = () => {
    return (
      <>
        {getTimeDiff.days > 0 && `${getTimeDiff.days}d `}
        {getTimeDiff.hours > 0 && `${getTimeDiff.hours}h `}
        {getTimeDiff.minutes > 0 && `${getTimeDiff.minutes}m `}
        {endTime.diff(startTime, 'm') < 1 && getTimeDiff.seconds > 0 && `${getTimeDiff.seconds}s`}
      </>
    );
  };

  const getPrimaryLabelContent = () => {
    return (
      <>
        <span className="date">
          {endTime.endOf('day').diff(dayjs().endOf('day')) === 0 &&
          startTime.endOf('day').diff(dayjs().endOf('day')) === 0
            ? `Today`
            : TimeUtils.toTimezone(startTime, timezone).format(DateFormats.DATE)}
        </span>
        &nbsp;
        {TimeUtils.toTimezone(startTime, timezone).format(
          endTime.diff(startTime, 'm') >= 1 ? DateFormats.TIME : DateFormats.TIME_SECONDS,
        )}
        &nbsp;-&nbsp;
        <span className="date">
          {endTime.endOf('day').diff(startTime.endOf('day')) > 0 &&
            TimeUtils.toTimezone(endTime, timezone).format(DateFormats.DATE)}
        </span>
        &nbsp;
        {TimeUtils.toTimezone(endTime, timezone).format(
          endTime.diff(startTime, 'm') >= 1 ? DateFormats.TIME : DateFormats.TIME_SECONDS,
        )}
      </>
    );
  };

  const getSubLabelContent = () => {
    return (
      <>
        {streamingTimeInSeconds && !isStreamingSpanningDays() ? (
          `Today ${TimeUtils.toTimezone(startTime, timezone).format(
            DateFormats.TIME,
          )} - ${TimeUtils.toTimezone(endTime, timezone).format(DateFormats.TIME)}`
        ) : (
          <>
            {TimeUtils.toTimezone(startTime, timezone).format(DateFormats.DATE)}{' '}
            <span className="time">
              {TimeUtils.toTimezone(startTime, timezone).format(DateFormats.TIME)}
            </span>{' '}
            - {TimeUtils.toTimezone(endTime, timezone).format(DateFormats.DATE)}{' '}
            <span className="time">
              {TimeUtils.toTimezone(endTime, timezone).format(DateFormats.TIME)}
            </span>
          </>
        )}
      </>
    );
  };

  return (
    <div>
      <FormProvider {...methods}>
        <Modal unmountOnExit>
          <ModalOpenButton>
            {streamingTimeInSeconds ? (
              <Container>
                <TimeCard data-testid="timecard_table">
                  <TimeContainer>
                    <PrimaryLabel data-testid="timecard_date">{preparePrimaryLabel()}</PrimaryLabel>
                    <Chevron className="icon" />
                  </TimeContainer>
                  <TimeRangeContainer>
                    {nowSelected ? (
                      <DurationContainer>{getDurationContent()}</DurationContainer>
                    ) : (
                      <SubLabel data-testid="time-card-sub-label">{getSubLabelContent()}</SubLabel>
                    )}
                    <TimeZone
                      data-testid={`current-time-zone-${TimeUtils.zoneFormat(timezone, 'z')}`}
                    >
                      {TimeUtils.zoneFormat(timezone, 'z')}
                    </TimeZone>
                  </TimeRangeContainer>
                </TimeCard>
              </Container>
            ) : (
              <Container>
                <TimeCard data-testid="timecard_table">
                  <TimeContainer>
                    <PrimaryLabel data-testid="timecard_date">
                      {getPrimaryLabelContent()}
                    </PrimaryLabel>
                    <Chevron className="icon" />
                  </TimeContainer>
                  <TimeRangeContainer>
                    <DurationContainer data-testid="timecard_summary">
                      {getDurationContent()}
                    </DurationContainer>
                    <TimeZone>{TimeUtils.zoneFormat(timezone, 'z')}</TimeZone>
                  </TimeRangeContainer>
                </TimeCard>
              </Container>
            )}
          </ModalOpenButton>
          <ModalContents
            title="Select Timeframe"
            confirmButtonCallback={onConfirm}
            closeButtonText="Cancel"
            styles={{ content: { maxWidth: '580px' } }}
          >
            <TimeSelector />
          </ModalContents>
        </Modal>
      </FormProvider>
    </div>
  );
};
