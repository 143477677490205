import * as d3 from 'd3';
import { v4 as uuidv4 } from 'uuid';

import { DisplayLimitData, Limits, Line, SVGDefsSelection, TimeSeries } from '@controlrooms/models';

export const buildAnomalyLimitLines = (
  chart: SVGDefsSelection,
  xScale: d3.ScaleTime<number, number, never>,
  yScale: d3.ScaleLinear<number, number, never>,
  tooltipData: TimeSeries[],
  limits: Limits,
  displayLimitData: DisplayLimitData,
  chartHeight: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any, // TODO: Export styled components from shared package
) => {
  const uuid = uuidv4();

  // HIGH and HIGH HIGH
  const highRanges = (limits: Limits) => {
    if (limits.highHighs.length) {
      return limits.highHighs;
    } else if (limits.highs.length) {
      return limits.highs;
    }
    return [];
  };

  const highLine = d3
    .line()
    .defined((d) => {
      return (d as unknown as TimeSeries).max != null;
    })
    .x((d) => {
      return xScale((d as unknown as TimeSeries).time);
    })
    .y((d) => {
      return yScale((d as unknown as TimeSeries).max);
    }) as Line<TimeSeries>;

  highRanges(limits).forEach((limit, idx) => {
    // Filtering tooltipData based on time range for each limit
    const dataWithinTimeRange = (tooltipData as TimeSeries[]).filter(
      (d) => d.time >= limit.start && d.time < limit.end,
    );

    const yScaleHighThreshold =
      limits.highs[idx]?.value || limits.highs[idx]?.value === 0
        ? yScale(limits.highs[idx]?.value)
        : 0;
    const yScaleHighHighThreshold =
      limits.highHighs[idx]?.value || limits.highHighs[idx]?.value === 0
        ? yScale(limits.highHighs[idx]?.value)
        : 0;

    const getHighGradientOffsetData = () => {
      if (displayLimitData.displayHighLine && displayLimitData.displayHighHighLine) {
        return [
          {
            offset:
              (limit.type === 'highs' ? yScaleHighThreshold : yScaleHighHighThreshold) /
              chartHeight,
            color: theme.chart.anomalyLimitLines,
          },
          {
            offset:
              (limit.type === 'highs' ? yScaleHighThreshold : yScaleHighHighThreshold) /
              chartHeight,
            color: 'transparent',
          },
        ];
      } else if (!displayLimitData.displayHighLine && displayLimitData.displayHighHighLine) {
        return [
          { offset: yScaleHighHighThreshold / chartHeight, color: theme.chart.anomalyLimitLines },
          { offset: yScaleHighHighThreshold / chartHeight, color: 'transparent' },
        ];
      } else if (displayLimitData.displayHighLine && !displayLimitData.displayHighHighLine) {
        return [
          { offset: yScaleHighHighThreshold / chartHeight, color: 'transparent' },
          { offset: yScaleHighHighThreshold / chartHeight, color: theme.chart.anomalyLimitLines },
          { offset: yScaleHighThreshold / chartHeight, color: theme.chart.anomalyLimitLines },
          { offset: yScaleHighThreshold / chartHeight, color: 'transparent' },
        ];
      }
      return [];
    };

    if (displayLimitData.displayHighLine || displayLimitData.displayHighHighLine) {
      chart
        .append('linearGradient')
        .attr('id', `high-${uuid}`)
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', chartHeight)
        .selectAll('stop')
        .data(getHighGradientOffsetData())
        .join('stop')
        .attr('offset', (d) => d.offset)
        .attr('stop-color', (d) => d.color);
    }

    chart
      .append('path')
      .datum(dataWithinTimeRange)
      .attr('fill', 'none')
      .attr('stroke', `url(#high-${uuid})`)
      .attr('stroke-width', 1)
      .attr('d', highLine);
  });

  // LOW and LOW LOW
  const lowRanges = (limits: Limits) => {
    if (limits.lowLows.length) {
      return limits.lowLows;
    } else if (limits.lows.length) {
      return limits.lows;
    }
    return [];
  };

  const lowLine = d3
    .line()
    .defined((d) => {
      return (d as unknown as TimeSeries).min != null;
    })
    .x((d) => {
      return xScale((d as unknown as TimeSeries).time);
    })
    .y((d) => {
      return yScale((d as unknown as TimeSeries).min);
    }) as Line<TimeSeries>;

  lowRanges(limits).forEach((limit, idx) => {
    // Filtering tooltipData based on time range for each limit
    const dataWithinTimeRange = (tooltipData as TimeSeries[]).filter(
      (d) => Number(d.time) >= limit.start && Number(d.time) < limit.end,
    );

    const yScaleLowThreshold =
      limits.lows[idx]?.value || limits.lows[idx]?.value === 0
        ? yScale(limits.lows[idx]?.value)
        : 0;
    const yScaleLowLowThreshold =
      limits.lowLows[idx]?.value || limits.lowLows[idx]?.value === 0
        ? yScale(limits.lowLows[idx]?.value)
        : 0;

    const getLowGradientOffsetData = () => {
      if (displayLimitData.displayLowLine && displayLimitData.displayLowLowLine) {
        return [
          {
            offset:
              (limit.type === 'lows' ? yScaleLowThreshold : yScaleLowLowThreshold) / chartHeight,
            color: 'transparent',
          },
          {
            offset:
              (limit.type === 'lows' ? yScaleLowThreshold : yScaleLowLowThreshold) / chartHeight,
            color: (limit.type === 'lows' ? yScaleLowThreshold : yScaleLowLowThreshold)
              ? theme.chart.anomalyLimitLines
              : 'transparent',
          },
        ];
      } else if (!displayLimitData.displayLowLine && displayLimitData.displayLowLowLine) {
        return [
          { offset: yScaleLowLowThreshold / chartHeight, color: 'transparent' },
          {
            offset: yScaleLowLowThreshold / chartHeight,
            color: yScaleLowLowThreshold ? theme.chart.anomalyLimitLines : 'transparent',
          },
        ];
      } else if (
        displayLimitData.displayLowLine &&
        !displayLimitData.displayLowLowLine &&
        yScaleLowThreshold
      ) {
        return [
          { offset: yScaleLowThreshold / chartHeight, color: 'transparent' },
          {
            offset: yScaleLowThreshold / chartHeight,
            color:
              yScaleLowThreshold && yScaleLowLowThreshold
                ? theme.chart.anomalyLimitLines
                : 'transparent',
          },
          {
            offset: yScaleLowLowThreshold / chartHeight,
            color:
              yScaleLowThreshold && yScaleLowLowThreshold
                ? theme.chart.anomalyLimitLines
                : 'transparent',
          },
          {
            offset: yScaleLowLowThreshold / chartHeight,
            color:
              yScaleLowThreshold && yScaleLowLowThreshold
                ? 'transparent'
                : theme.chart.anomalyLimitLines,
          },
        ];
      }
      return [];
    };

    if (displayLimitData.displayLowLine || displayLimitData.displayLowLowLine) {
      chart
        .append('linearGradient')
        .attr('id', `low-${uuid}`)
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', chartHeight)
        .selectAll('stop')
        .data(getLowGradientOffsetData())
        .join('stop')
        .attr('offset', (d) => d.offset)
        .attr('stop-color', (d) => d.color);
    }

    chart
      .append('path')
      .datum(dataWithinTimeRange)
      .attr('fill', 'none')
      .attr('stroke', `url(#low-${uuid})`)
      .attr('stroke-width', 1)
      .attr('d', lowLine);
  });
};
