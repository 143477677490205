import * as d3 from 'd3';

import { calculateTriangleIndicatorPoints, cleanTagName } from './chart-utils';

import { Mode, ModeType, SVGDefsSelection } from '@controlrooms/models';

export const buildLimitIndicator = (
  chart: SVGDefsSelection,
  xScale: d3.ScaleTime<number, number, never>,
  yScale: d3.ScaleLinear<number, number, never>,
  modes: Mode[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any, // TODO: Export styled components from shared package
  modeType: ModeType,
  gap: number,
) => {
  modes.map((mode, idx) => {
    const endX = xScale(mode.end);
    const endY = yScale(mode.value);
    const triangleSize = 5; // Adjust size as needed
    const trianglePoints = calculateTriangleIndicatorPoints(endX, endY, triangleSize, 'end', gap);
    const tagName = cleanTagName(mode.tagName as string);
    // Draw triangle
    const triangleFill = modeType === ModeType.HHLIMIT || modeType == ModeType.LLLIMIT;
    chart
      .append('polygon')
      .attr('class', `triangle-${modeType}-${tagName}-${idx}`)
      .attr('points', trianglePoints)
      .style('fill', triangleFill ? theme.chart.limitIndicatorDefault : 'none')
      .style('stroke', theme.chart.limitIndicatorDefault);
  });
};
