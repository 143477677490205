import styled from 'styled-components';

import { DateFormats } from '@controlrooms/models';
import { AnalyzeSavedView, MonitorSavedView } from '@controlrooms/models';
import { TimeUtils } from '@controlrooms/utils';

export const StyledCustomViewDeleteConfirm = styled.div`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  margin: 28px 60px 20px;
  .details {
    font-family: 'Space Grotesk';
    font-size: 17px;
    line-height: 19px;
    font-weight: 400;
    margin-bottom: 18px;
  }
  .label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 8px;
  }
`;

export const CustomViewDeleteConfirmContent: React.FC<
  MonitorSavedView | AnalyzeSavedView | null
> = ({ name, timeSelection }) => {
  const getTimeFrameContent = () => {
    return (
      <span className="date" data-testid="data_custom_view_date">
        {`${TimeUtils.toTimezone(timeSelection.startTime, timeSelection.timezone).format(
          DateFormats.DATETIME_ALT,
        )} - ${TimeUtils.toTimezone(timeSelection.endTime, timeSelection.timezone).format(
          DateFormats.DATETIME_ALT,
        )}`}
      </span>
    );
  };

  return (
    <StyledCustomViewDeleteConfirm>
      <div className="details">{name}</div>
      <div className="label" data-testid="custom_view_delete_details">
        Timeframe
      </div>
      <div className="details">{getTimeFrameContent()}</div>
    </StyledCustomViewDeleteConfirm>
  );
};

const FooterInfoContainer = styled.div`
  text-align: end;
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  line-height: 13px;
`;

export const FooterInfo: React.FC = () => {
  return <FooterInfoContainer>This cannot be undone</FooterInfoContainer>;
};
