export const Paths = {
  HOME: '/',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/tos-online-2023',
  REDIRECT: '/app/redirect',
  INVESTIGATE: '/app/t/:tenantId/v2/investigate',
  MONITOR: '/app/t/:tenantId/monitor',
  DEMO_MONITOR: '/demo/t/:tenantId/monitor',
  ANALYZE: '/app/t/:tenantId/analyze',
  TIME_SEARCH: '/app/t/:tenantId/time-search',
  MANAGE_ALERTS: '/app/t/:tenantId/alerts',
  MANAGE_LIMITS: '/app/t/:tenantId/limits',
  DEMO_ANALYZE: '/demo/t/:tenantId/analyze',
  SHARE: '/app/t/:tenantId/:hash',
  NOT_FOUND: '*',
};

export const QueryParams = {
  AUTH0: 'auth0',
  TRIAL_UPLOAD: 'trial-upload',
  UPLOAD: 'upload',
  INVITE: 'invite',
  TERMS_CONDITIONS: 'terms-conditions',
  PRIVACY_POLICY: 'privacy-policy',
  CONFIRM_EMAIL: 'confirm-email',
  ROLE: 'role',
};
