import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { ThemeProvider } from 'styled-components';
import { TusClientProvider } from 'use-tus';

import { Layout } from '../../../app-v2/components/layout';
import { AppContextProvider as AppContextV2Provider } from '../../../app-v2/context/app-context';
import GlobalStyle from '../../../theme/globalStyles';
import AnalyticsContextProvider from '../../analytics';
import { Ably } from '../../components/ably';
import AuthRoute from '../../components/auth/auth-route';
import { Auth0HistoryProvider } from '../../components/auth/auth0-history-provider';
import { ErrorFallback } from '../../components/error-fallback/error-fallback';
import { FileUpload } from '../../components/file-upload';
import FileUploadContextProvider from '../../components/file-upload-modal/file-upload-context';
import { Loading } from '../../components/loading/loading';
import {
  UploadInstructions,
  UploadInstructionsFooter,
} from '../../components/upload-instructions-modal';
import { Paths, QueryParams } from '../../constants/paths';
import AblyChannelProvider from '../../context/ably-channel-context';
import AppContextProvider from '../../context/app-context';
import AuthorizationContextProvider, {
  AuthorizationContext,
} from '../../context/authorization-context';
import EnsembleContextProvider from '../../context/ensemble-context';
import HelpHeroContextProvider from '../../context/help-hero-context';
import TenantContextProvider, { useTenant } from '../../context/tenant-context';
import { useTheme } from '../../hooks/accounts';
import { useAuthorization } from '../../hooks/authorization';
import { ErrorPage, PageNotFound, VerifyUser, Error } from '../../pages/not-found/error-page';
import {
  AlertPage,
  Analyze,
  LimitPage,
  Monitor,
  Redirect,
  Share,
  TimeSearch,
} from '../../pages/routes';
import { setTokenFetcher } from '../../services/api';
import { CR_USER_PROP } from '../../utils/token';

import { Modal, ModalContents } from '@controlrooms/components';

const App: React.FC = () => {
  const { isAuthenticated, isLoading, error, getAccessTokenSilently, user } = useAuthorization();
  const { tenant } = useTenant();
  const { canUserReadAlert } = useContext(AuthorizationContext);
  const userDec = user ? user[CR_USER_PROP] : '';

  const [theme] = useTheme();

  useEffect(() => {
    // Initialize the application permissions
    isAuthenticated && getAccessTokenSilently({ tenant: tenant });
    // eslint-disable-next-line
  }, [isAuthenticated]);

  // Use this to pass the getAccessTokenSilently function to api.ts
  useEffect(() => {
    setTokenFetcher(() => getAccessTokenSilently({ tenant }));
  }, [getAccessTokenSilently, isAuthenticated, tenant]);
  const [searchParams, setSearchParams] = useSearchParams();

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <ErrorPage>
          <Error message={error.message} />
        </ErrorPage>
      </ThemeProvider>
    );
  }

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <Loading />
      </ThemeProvider>
    );
  }
  if (user && user.email_verified === false) {
    return (
      <ThemeProvider theme={theme}>
        <ErrorPage>
          <VerifyUser />
        </ErrorPage>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<Loading />}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <AnalyticsContextProvider
            isAuthenticated={isAuthenticated}
            platform={'segment'}
            userTheme={theme?.name}
            writeKey={process.env.REACT_APP_SEGMENT_WRITE_KEY || ''}
            userIdentification={{
              userId: user?.sub,
              userEmail: user?.email,
              userName: user?.nickname,
              userLocale: user?.locale,
              userLastName: user?.family_name,
              userFirstName: user?.given_name,
            }}
          >
            <HelpHeroContextProvider>
              {userDec.app_version === 2 && (
                <AppContextV2Provider>
                  <Layout />
                </AppContextV2Provider>
              )}
              {(!userDec || !userDec?.app_version || userDec?.app_version === 1) && (
                <AppContextProvider>
                  <AblyChannelProvider>
                    <GlobalStyle />
                    <Ably />
                    <FileUpload />
                    <Routes>
                      <Route index element={<AuthRoute component={Redirect} />} />
                      <Route path={'signup'} element={<Signup />} />
                      <Route path={'redirect'} element={<AuthRoute component={Redirect} />} />
                      <Route
                        path={'t/:tenantId/monitor'}
                        element={<AuthRoute component={Monitor} />}
                      />
                      <Route
                        path={'t/:tenantId/analyze'}
                        element={<AuthRoute component={Analyze} />}
                      />
                      <Route
                        path={'t/:tenantId/time-search'}
                        element={<AuthRoute component={TimeSearch} />}
                      />
                      {canUserReadAlert && (
                        <Route
                          path={'t/:tenantId/alerts'}
                          element={<AuthRoute component={AlertPage} />}
                        />
                      )}
                      <Route
                        path={'t/:tenantId/limits'}
                        element={<AuthRoute component={LimitPage} />}
                      />
                      <Route path={'t/:tenantId/:hash'} element={<AuthRoute component={Share} />} />
                      <Route
                        path={Paths.NOT_FOUND}
                        element={
                          <ErrorPage>
                            <PageNotFound />
                          </ErrorPage>
                        }
                      />
                    </Routes>

                    <Modal open={Boolean(searchParams.get(QueryParams.TRIAL_UPLOAD))}>
                      <ModalContents
                        title="Upload Instructions"
                        styles={{ content: { maxWidth: '900px' } }}
                        footerPrompt={<UploadInstructionsFooter />}
                        secondary
                        closeButtonCallback={() => {
                          const _params = new URLSearchParams(searchParams);
                          _params.delete(QueryParams.TRIAL_UPLOAD);
                          setSearchParams(_params);
                        }}
                      >
                        <UploadInstructions />
                      </ModalContents>
                    </Modal>
                  </AblyChannelProvider>
                </AppContextProvider>
              )}
            </HelpHeroContextProvider>
          </AnalyticsContextProvider>
        </ErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </React.Suspense>
    </ThemeProvider>
  );
};

export const CRApp: React.FC = () => {
  const { boot } = useIntercom();
  const queryClient = useQueryClient();

  useEffect(() => {
    boot();
    (async () => {
      await queryClient.resetQueries();
    })();
  });

  return (
    <TenantContextProvider>
      <Auth0HistoryProvider>
        <AuthorizationContextProvider>
          <TusClientProvider>
            <FileUploadContextProvider>
              <EnsembleContextProvider>
                <App />
              </EnsembleContextProvider>
            </FileUploadContextProvider>
          </TusClientProvider>
        </AuthorizationContextProvider>
      </Auth0HistoryProvider>
    </TenantContextProvider>
  );
};

const Signup: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  loginWithRedirect({
    screen_hint: 'signup',
  });
  return <></>;
};

export default CRApp;
