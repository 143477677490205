import * as d3 from 'd3';
import dayjs, { Dayjs } from 'dayjs';

import {
  ViewFilters,
  ViewFiltersValue,
  Mode,
  TimeSeries,
  TooltipData,
  CRTagMinMaxTimeSeries,
  TimeSeriesString,
} from '@controlrooms/models';

export const xAccessor = (d: TimeSeries) => d.time as number;
export const yAccessor = (d: TimeSeries) => d.value as number;
export const yAccessorString = (d: TimeSeriesString) => d.value as string;
export const yAccessorMin = (d: TimeSeries) => d.min as number;
export const yAccessorMax = (d: TimeSeries) => d.max as number;
export const yAccessorUom = (d: TooltipData) => d.uom as string;

export const yAccessorExtentMin = (data: Array<TimeSeries>, displayFreqLine?: boolean) =>
  d3.min(data, (d: TimeSeries) => {
    const { min, frequent, value } = d;
    const possibleValues = displayFreqLine ? [min, frequent as number, value] : [min, value];
    return Math.min(...possibleValues.filter((i) => i !== null && i !== undefined));
  }) as number;

export const yAccessorExtentMax = (data: Array<TimeSeries>, displayFreqLine?: boolean) =>
  d3.max(data, (d: TimeSeries) => {
    const { max, frequent, value } = d;
    const possibleValues = displayFreqLine ? [max, frequent as number, value] : [max, value];
    return Math.max(...possibleValues.filter((i) => i !== null && i !== undefined));
  }) as number;

export const yAccessorExtentMinWithLimit = (
  data: Array<TimeSeries>,
  limitData: Mode[],
  displayFreqLine?: boolean,
) => {
  const minValue = d3.min(data, (d: TimeSeries) => {
    const { min, frequent, value } = d;
    const possibleValues = displayFreqLine ? [min, frequent as number, value] : [min, value];
    return Math.min(...possibleValues.filter((i) => i !== null && i !== undefined));
  }) as number;
  const minLimitValue = d3.min(limitData, (d: Mode) => {
    const { value } = d;
    const possibleValues = [value];
    return Math.min(...possibleValues.filter((i) => i !== null && i !== undefined));
  }) as number;
  return d3.min([minLimitValue, minValue]) as number;
};

export const yAccessorExtentMaxWithLimit = (
  data: Array<TimeSeries>,
  limitData: Mode[],
  displayFreqLine?: boolean,
) => {
  const maxValue = d3.max(data, (d: TimeSeries) => {
    const { max, frequent, value } = d;
    const possibleValues = displayFreqLine ? [max, frequent as number, value] : [max, value];
    return Math.max(...possibleValues.filter((i) => i !== null && i !== undefined));
  }) as number;
  const maxLimitValue = d3.max(limitData, (d: Mode) => {
    const { value } = d;
    const possibleValues = [value];
    return Math.max(...possibleValues.filter((i) => i !== null && i !== undefined));
  }) as number;
  return d3.max([maxLimitValue, maxValue]) as number;
};

export const yScaleDomain = (
  data: Array<TimeSeries>,
  limitData: Mode[],
  displayFreqLine?: boolean,
): [number, number] => {
  const minValue = yAccessorExtentMinWithLimit(data, limitData, displayFreqLine);
  const maxValue = yAccessorExtentMaxWithLimit(data, limitData, displayFreqLine);
  const diff = maxValue - minValue;
  const offset = 0.05 * diff;
  return [minValue - offset, maxValue + offset];
};

export const numberFormatConverter = (d: d3.NumberValue, ratio = 10) => {
  if (Number(d) >= 1000) {
    if (ratio < 1) {
      // If the values are close, show more digits so the y-axis labels differ
      return d3.format(`.${calculateRequiredDigits(d, ratio)}s`)(d);
    }
    return d3.format(['1', '9'].includes(String(d)[0]) ? '.3s' : '.2s')(d);
  }
  if (ratio < 0.1) {
    // Show two decimal places because numbers are less than 0.1 apart
    return d3.format('.2f')(d);
  }
  if (ratio < 1) {
    // Show one decimal place because numbers are less than 1 apart
    return d3.format('.1f')(d);
  }
  return d3.format('.3s')(d);
};

const calculateRequiredDigits = (d: d3.NumberValue, ratio: number): number => {
  const rounded = ratio.toFixed(1 - Math.floor(Math.log(ratio) / Math.log(10)));
  const _leftAdd = d.toString().length % 3;
  const _deciString = rounded.toString().split('.')[1];

  if (_deciString?.length > 1) {
    return Number(_deciString[_deciString.length - 1]) >= 5
      ? _deciString.length - 1 + _leftAdd
      : _deciString.length + _leftAdd;
  }

  return _leftAdd;
};

export const canApplyChartFilter = (
  chartFilters: ViewFilters[],
  currentFilterValue: ViewFiltersValue,
) => {
  return chartFilters.some((option) => option.value === currentFilterValue && option.checked);
};

export const generateAbbreviation = (limitType: string) => {
  const words = limitType.split(/(?=[A-Z])/);
  let acronym = '';

  words.forEach((word: string) => {
    acronym += word[0].toUpperCase();
  });

  return acronym;
};

export const cleanTagName = (tagName: string | undefined) => {
  return tagName?.replaceAll(/[^a-zA-Z0-9]/g, '-') || '';
};

export const calculateTriangleIndicatorPoints = (
  x: number,
  y: number,
  size: number,
  direction = 'start',
  gap = 0,
) => {
  const halfSize = size / 2;
  const points = [
    direction === 'start' ? [x + gap + halfSize, y] : [x + gap - halfSize, y],
    direction === 'start' ? [x + gap - halfSize, y + halfSize] : [x + gap + halfSize, y - halfSize],
    direction === 'start' ? [x + gap - halfSize, y - halfSize] : [x + gap + halfSize, y + halfSize],
  ];
  return points.join(' ');
};

export enum dashLimit {
  'frequent' = '10, 5, 2, 5',
  'high-limit' = '4, 6',
  'high-high-limit' = '10, 2',
  'low-limit' = '4, 6',
  'low-low-limit' = '10, 2',
}

/**
 * This method generates the color ranges for different limit areas
 * generateLimitRangeColor(min, lowLow, low, high,  highHigh, max)                          -> [{start, end, color}, ...]
 * generateLimitRangeColor(1725, undefined, undefined, undefined, undefined, 1748, colors)  -> [{1725, 1748, transparent}]
 * generateLimitRangeColor(1725, undefined, undefined, undefined, 1740, 1748, colors)       -> [{1725, 1740, transparent}, {1740, 1748, darkRed}]
 * generateLimitRangeColor(1725, undefined, undefined, 1738, undefined, 1748, colors)       -> [{1725, 1738, transparent}, {1738, 1748, lightRed}]
 * generateLimitRangeColor(1725, undefined, undefined, 1738, 1740, 1748, colors)            -> [{1725, 1738, transparent}, {1738, 1740, lightRed}, {1740, 1748,darkRed}]
 * generateLimitRangeColor(1725, undefined, 1736, undefined, undefined, 1748, colors)       -> [{1725, 1736, lightRed}, {1736, 1748, transparent}]
 * generateLimitRangeColor(1725, undefined, 1736, undefined, 1740, 1748, colors)            -> [{1725, 1736, lightRed}, {1736, 1740, transparent}, {1740, 1748, darkRed}]
 * generateLimitRangeColor(1725, undefined, 1736, 1738, undefined, 1748, colors)            -> [{1725, 1736, lightRed}, {1736, 1738, transparent}, {1738, 1748, lightRed}]
 * generateLimitRangeColor(1725, undefined, 1736, 1738, 1740, 1748, colors)                 -> [{1725, 1736, lightRed}, {1736, 1738, transparent}, {1738, 1740, lightRed}, {1740, 1748, darkRed}]
 * generateLimitRangeColor(1725, 1727, undefined, undefined, undefined, 1748, colors)       -> [{1725, 1727, darkRed}, {1727, 1748, transparent}]
 * generateLimitRangeColor(1725, 1727, undefined, undefined, 1740, 1748, colors)            -> [{1725, 1727, darkRed}, {1727, 1740, transparent}, {1740, 1748, darkRed}]
 * generateLimitRangeColor(1725, 1727, undefined, 1738, undefined, 1748, colors)            -> [{1725, 1727, darkRed}, {1727, 1738, transparent}, {1738, 1748, lightRed}]
 * generateLimitRangeColor(1725, 1727, undefined, 1738, 1740, 1748, colors)                 -> [{1725, 1727, darkRed}, {1727, 1738, transparent}, {1738, 1740, lightRed}, {1740, 1748, darkRed}]
 * generateLimitRangeColor(1725, 1727, 1736, undefined, undefined, 1748, colors)            -> [{1725, 1727, darkRed}, {1727, 1736, lightRed}, {1736, 1748, transparent}]
 * generateLimitRangeColor(1725, 1727, 1736, undefined, 1740, 1748, colors)                 -> [{1725, 1727, darkRed}, {1727, 1736, lightRed}, {1736, 1740, transparent}, {1740, 1748, darkRed}]
 * generateLimitRangeColor(1725, 1727, 1736, 1738, undefined, 1748, colors)                 -> [{1725, 1727, darkRed}, {1727, 1736, lightRed}, {1736, 1738, transparent}, {1738, 1748, lightRed}]
 * generateLimitRangeColor(1725, 1727, 1736, 1738, 1740, 1748, colors)                      -> [{1725, 1727, darkRed}, {1727, 1736, lightRed}, {1736, 1738, transparent}, {1738, 1740, lightRed}, {1740, 1748, darkRed}]
 * @param min Float
 * @param lowLow Float
 * @param low Float
 * @param high Float
 * @param highHigh Float
 * @param max Float
 * @param colors Colors: { darkRed: String, lightRed: String, transparent: 'String' }
 * @returns ColorRanges[]
 */
export const generateLimitRangeColor = (min, lowLow, low, high, highHigh, max, colors) => {
  const ranges = [];

  // Handle the range between min and lowLow/low
  if (lowLow !== undefined) {
    ranges.push({ start: min, end: lowLow, color: colors.darkRed });
    if (low !== undefined) {
      ranges.push({ start: lowLow, end: low, color: colors.lightRed });
    }
  } else if (low !== undefined) {
    ranges.push({ start: min, end: low, color: colors.lightRed });
  }

  // Middle range (transparent)
  if (low !== undefined || lowLow !== undefined) {
    ranges.push({
      start: low || lowLow || min,
      end: high || highHigh || max,
      color: colors.transparent,
    });
  } else {
    // If low and lowLow are undefined
    ranges.push({ start: min, end: high || highHigh || max, color: colors.transparent });
  }

  // Upper range
  if (high !== undefined) {
    if (highHigh !== undefined) {
      ranges.push({ start: high, end: highHigh, color: colors.lightRed });
    } else {
      // If highHigh is undefined
      ranges.push({ start: high, end: max, color: colors.lightRed });
    }
  }

  // Handle the range between highHigh and max
  if (highHigh !== undefined) {
    ranges.push({ start: highHigh, end: max, color: colors.darkRed });
  }

  return ranges;
};

// Methods needed for data grouping of discrete and string value charts

export const interpolateTypes = [d3.curveStepBefore, d3.curveStepAfter];

interface GroupedDataItem {
  value: number;
  start: string | number;
  end: string | number;
  data: Array<TimeSeries | CRTagMinMaxTimeSeries>;
}

export const groupStepLineDataBySameValue = (
  data: Array<TimeSeries | CRTagMinMaxTimeSeries> | undefined,
): GroupedDataItem[] => {
  const groupedData: GroupedDataItem[] = [];
  let currentGroup: GroupedDataItem | null = null;

  data?.forEach((point: TimeSeries | CRTagMinMaxTimeSeries) => {
    if (point.value === null) return;
    if (currentGroup && currentGroup.value === point.value) {
      currentGroup.end = point.time;
      currentGroup.data.push(point);
    } else {
      if (currentGroup) {
        groupedData.push(currentGroup);
      }
      currentGroup = { value: point.value, start: point.time, end: point.time, data: [point] };
    }
  });

  if (currentGroup) {
    groupedData.push(currentGroup);
  }

  return groupedData;
};

export const refineStepSeriesData = (seriesData: TimeSeries[], defaultEndTime: Dayjs) => {
  const _seriesData = [...seriesData];

  if (_seriesData?.length && _seriesData[_seriesData.length - 1].value !== null) {
    const lastDataPoint = _seriesData[_seriesData.length - 1];
    _seriesData.push({
      ...lastDataPoint,
      time: dayjs(defaultEndTime).valueOf(),
    });
  }
  return _seriesData;
};

// STRING CHART

export const groupStringDataByValues = (data: TimeSeries[], defaultEndTime: Dayjs) => {
  const segments = [];

  if (data && data.length) {
    let start = data[0];

    for (let i = 1; i < data.length; i++) {
      if (data[i].value !== start.value) {
        segments.push({
          start: start.time,
          end: data[i].time,
          value: start.value,
        });
        start = data[i];
      }
    }

    const lastData = data[data.length - 1];
    segments.push({
      start: start.time,
      end: lastData.value !== null ? dayjs(defaultEndTime).valueOf() : lastData.time,
      value: start.value,
    });
  }
  return segments.filter((segment) => segment.value !== null);
};

export const truncateStringLabels = (
  text: string,
  maxWidth: number,
  textElement: SVGTextElement,
) => {
  let truncated = text;
  while (textElement.getSubStringLength(0, truncated.length) > maxWidth) {
    truncated = truncated.slice(0, -1);
  }
  return truncated + '…';
};
