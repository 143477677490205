export const ICONS = {
  AddChannel: 'add-channel',
  Arrow: 'arrow',
  ArrowDown: 'arrow-down',
  ArrowRight: 'arrow-right',
  ArrowUp: 'arrow-up',
  Back: 'back',
  AnalyzeLink: 'analyze-link',
  Calendar: 'calendar',
  Checkmark: 'checkmark',
  CheckmarkDefault: 'CheckmarkDefault',
  Check: 'check',
  Overlay: 'overlay',
  OverlayActive: 'overlay-active',
  Indeterminate: 'indeterminate',
  JumpBack: 'jump-back',
  Chevron: 'chevron',
  Close: 'close',
  CloseX: 'close-x',
  ClosedEye: 'closed-eye',
  Delete: 'delete',
  Download: 'download',
  Edit: 'edit',
  Envelope: 'envelope',
  Eye: 'eye',
  List: 'list',
  ListActive: 'list-active',
  Grid: 'grid',
  GridActive: 'grid-active',
  Copy: 'copy',
  Dynamic: 'dynamic',
  RowDragHandle: 'row-drag-handle',
  ZoomIn: 'zoom-in',
  Undo: 'undo',
  ZoomOut: 'zoom-out',
  Plus: 'plus',
  Pin: 'pin',
  Search: 'search',
  SelectTrend: 'select-trend',
  Sparkline: 'sparkline',
  System: 'system',
  SystemDown: 'system-down',
  Minus: 'minus',
  Menu: 'menu',
  TooltipArrow: 'tooltip-arrow',
  Export: 'export',
  Bell: 'bell',
  Teams: 'teams',
  Comment: 'comment',
  Info: 'info',
  Union: 'union',
  Upload: 'upload',
  Help: 'help',
  ThumbUp: 'thumb-up',
  ThumbDown: 'thumb-down',
  PanRight: 'pan-right',
  PanLeft: 'pan-left',
  Now: 'now',
  BurgerMenu: 'burger-menu',
  Filter: 'filter',
  Spinner: 'spinner',
};

export const GRADIENTS = {
  g1: 'g1',
  g2: 'g2',
  g3: 'g3',
  hover: 'hover-row',
  selected: 'selected-row',
  tooltipLine: 'tooltip-line',
  tagHover: 'tag-hover',
  tagHeaderHover: 'tag-header-hover',
  limits: 'limits',
};

export const SUB_SYSTEMS_SEARCH_PARAM = 'subsystems';
export const TAGS_SEARCH_PARAM = 'tagname';

export { default as timezones } from './timezones';
export type { TimeZone } from './timezones';

export * from './labeled-events';
