import { colors } from '@controlrooms/design-tokens';

export const investigateDarkTheme = {
  backgroundColor: colors.k[5],
  itemGroupBorder: colors.content['neutral-6'],
  tag: {
    folder: {
      label: colors.k[55],
    },
    chip: {
      background: colors.k[8],
      desc: colors.k[80],
    },
  },
  controls: {
    content: colors.content['neutral-1'],
  },
};

export const investigateLightTheme = {
  backgroundColor: colors.k[95],
  itemGroupBorder: colors.content['neutral-2'],
  tag: {
    folder: {
      label: colors.k[30],
    },
    chip: {
      background: colors.k[90],
      desc: colors.k[12],
    },
  },
  controls: {
    content: colors.content['neutral-6'],
  },
};
