import { SubSystem } from '@controlrooms/models';

export const sanitizeString = (str) => {
  // Replace any character that is not a letter, number, or space with an underscore
  // Then replace spaces with underscores and convert to lowercase
  return str
    .replace(/[^a-zA-Z0-9 ]/g, '_')
    .replace(/\s+/g, '_')
    .toLowerCase();
};

export const flattenFolders = (
  folders: SubSystem[],
  level = 0,
  parentVisible = true,
  collapsed: { [key: number]: boolean } = {},
): Array<{ folder: SubSystem; level: number; visible: boolean }> => {
  let result: Array<{ folder: SubSystem; level: number; visible: boolean }> = [];
  folders.forEach((folder) => {
    const isVisible = parentVisible && !collapsed[folder.folder];
    result.push({ folder, level, visible: parentVisible });
    if (folder.subfolders && isVisible) {
      result = result.concat(flattenFolders(folder.subfolders, level + 1, isVisible, collapsed));
    }
  });
  return result;
};
