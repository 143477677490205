import dayjs from 'dayjs';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { AppContext } from './app-context';
import { useTenant } from './tenant-context';

import {
  AnalyzeSavedView,
  AnalyzeView,
  GroupTooltipData,
  PersistTimeSelection,
  SearchTimeRangeData,
  TimeRanges,
} from '@controlrooms/models';
import { ParentTag } from '@controlrooms/models';
import { getQueryParams } from '@controlrooms/utils';

export interface AnalyzeContextProps {
  updateView: (view: AnalyzeSavedView) => void;
  selectedFolders: number[];
  selectedViewTitle: string;
  setSelectedFolders: Dispatch<SetStateAction<number[]>>;
  groupTooltipData?: GroupTooltipData;
  setGroupTooltipData: Dispatch<SetStateAction<GroupTooltipData | undefined>>;
  labelTooltipData?: GroupTooltipData;
  setLabelTooltipData: Dispatch<SetStateAction<GroupTooltipData | undefined>>;
  searchTimeRangeData?: SearchTimeRangeData;
  setSearchTimeRangeData: Dispatch<SetStateAction<SearchTimeRangeData | undefined>>;
  pinnedTags: ParentTag[];
  setPinnedTags: Dispatch<SetStateAction<ParentTag[]>>;
  currentFolder: number | string | null;
  setCurrentFolder: Dispatch<SetStateAction<number | string | null>>;
  setSelectedViewTitle: Dispatch<SetStateAction<string>>;
  showHiddenTags: boolean;
  setShowHiddenTags: Dispatch<SetStateAction<boolean>>;
  showAnalyzeLimits: boolean;
  setShowAnalyzeLimits: Dispatch<SetStateAction<boolean>>;
}

const defaultState = {
  updateView: () => null,
  selectedFolders: [],
  selectedViewTitle: 'Default View',
  setSelectedFolders: () => null,
  groupTooltipData: undefined,
  setGroupTooltipData: () => undefined,
  labelTooltipData: undefined,
  setLabelTooltipData: () => undefined,
  searchTimeRangeData: undefined,
  setSearchTimeRangeData: () => undefined,
  pinnedTags: [],
  setPinnedTags: () => null,
  currentFolder: null,
  setCurrentFolder: () => null,
  showHiddenTags: false,
  setShowHiddenTags: () => null,
  showAnalyzeLimits: true,
  setShowAnalyzeLimits: () => null,
  setSelectedViewTitle: () => null,
};

export const AnalyzeContext = createContext<AnalyzeContextProps>(defaultState);

interface AnalyzeRouteState {
  view?: AnalyzeView;
}

const AnalyzeContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const view = (location.state as AnalyzeRouteState)?.view;

  const {
    analyzeSessionStorage,
    setAnalyzeSessionStorage,
    setAnalyzeTimeSelection,
    resetStorageState,
  } = useContext(AppContext);

  const { selectedFolders: qpSelectedFolders } = getQueryParams(location.search);

  const [selectedFolders, setSelectedFolders] = useState<number[]>(() => {
    if (qpSelectedFolders) {
      return JSON.parse(qpSelectedFolders);
    }
    return view?.selectedFolders ?? analyzeSessionStorage.selectedFolders;
  });
  const [selectedViewTitle, setSelectedViewTitle] = useState<string>(
    analyzeSessionStorage.selectedViewTitle,
  );

  const [showHiddenTags, setShowHiddenTags] = useState<boolean>(false);
  const [pinnedTags, setPinnedTags] = useState<ParentTag[]>(
    view?.pinnedTags ?? analyzeSessionStorage.pinnedTags,
  );
  const [currentFolder, setCurrentFolder] = useState<number | string | null>(
    analyzeSessionStorage.currentFolder,
  );

  const [showAnalyzeLimits, setShowAnalyzeLimits] = useState<boolean>(
    analyzeSessionStorage.showAnalyzeLimits || defaultState.showAnalyzeLimits,
  );

  const { tenant } = useTenant();

  useEffect(() => {
    if (tenant === 0) {
      resetStorageState();
    }
  }, [tenant, resetStorageState]);

  useEffect(() => {
    setAnalyzeSessionStorage((prevState) => ({
      ...prevState,
      selectedFolders: selectedFolders,
      pinnedTags: pinnedTags,
      currentFolder: currentFolder,
      showAnalyzeLimits: showAnalyzeLimits,
      selectedViewTitle: selectedViewTitle,
    }));
    // eslint-disable-next-line
  }, [selectedFolders, pinnedTags, currentFolder, showAnalyzeLimits, selectedViewTitle]);

  const [groupTooltipData, setGroupTooltipData] = useState<GroupTooltipData>();
  const [labelTooltipData, setLabelTooltipData] = useState<GroupTooltipData>();
  const [searchTimeRangeData, setSearchTimeRangeData] = useState<SearchTimeRangeData>();

  const updateView = useCallback(
    (view: AnalyzeSavedView) => {
      if (
        view.timeSelection.timeRange === TimeRanges.PRESET &&
        view.timeSelection.streamingTimeInSeconds
      ) {
        view.timeSelection.endTime = dayjs().toISOString();
        view.timeSelection.startTime = dayjs()
          .subtract(view.timeSelection.streamingTimeInSeconds, 'seconds')
          .toISOString();
      }
      setAnalyzeTimeSelection(PersistTimeSelection.toTimeSelection(view.timeSelection));
      setSelectedFolders(view.selectedFolders);
      setPinnedTags(view.pinnedTags);
      setSelectedViewTitle(view.name);
      setShowAnalyzeLimits(view.showAnalyzeLimits || true);
    },
    [setAnalyzeTimeSelection],
  );

  const analyzeState = useMemo(
    () => ({
      updateView,
      selectedFolders,
      setSelectedFolders,
      groupTooltipData,
      setGroupTooltipData,
      labelTooltipData,
      setLabelTooltipData,
      searchTimeRangeData,
      setSearchTimeRangeData,
      pinnedTags,
      setPinnedTags,
      currentFolder,
      setCurrentFolder,
      showHiddenTags,
      setShowHiddenTags,
      showAnalyzeLimits,
      setShowAnalyzeLimits,
      selectedViewTitle,
      setSelectedViewTitle,
    }),
    [
      currentFolder,
      pinnedTags,
      selectedFolders,
      groupTooltipData,
      labelTooltipData,
      searchTimeRangeData,
      updateView,
      showHiddenTags,
      showAnalyzeLimits,
      setShowAnalyzeLimits,
      selectedViewTitle,
      setSelectedViewTitle,
    ],
  );

  return <AnalyzeContext.Provider value={analyzeState}>{children}</AnalyzeContext.Provider>;
};

export default AnalyzeContextProvider;
