import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';

const StyledButton = styled.button`
  font-family: inherit;
  display: flex;
  padding: 0;
  margin-top: 3px;
  background-color: inherit;
  border: none;
  color: ${({ theme }) => theme.modal.panel.color};
  width: 80%;
  font-weight: 200;
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;
  .select-label {
    &:hover {
      color: ${({ theme }) => theme.timeDisplay.primaryTextHover};
      cursor: ${({ disabled }) => (disabled ? 'default' : '')};
    }
  }
`;

export const ChevronContainer = styled.div`
  padding: unset;
  margin-top: 7px;
  margin-left: 10px;
`;

export const ButtonGroup = styled.div`
  display: flex;
`;

export const Chevron = styled(Icon).attrs((props) => ({
  color: props.theme.timeDisplay.chevronColor,
  name: ICONS.Chevron,
}))``;

const StyledUL = styled.ul<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 211px;
  padding: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
  list-style: none;
  border: 1px solid black;
  border-radius: 4px;
  z-index: 10;
  ${(props) => {
    if (!props.isOpen) {
      return `
        display: none;
      `;
    }
  }}
`;

const SubLabel = styled.span`
  margin-right: 8px;
  color: ${({ theme }) => theme.select.subLabelColor};
`;

const StyledLI = styled.li<{ disabled?: boolean }>`
  list-style: none;
  cursor: pointer;
  font-style: normal;
  color: ${({ theme }) => theme.modal.panel.color};
  font-weight: 200;
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  line-height: 17px;
  padding: 3px 0;
  flex-basis: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  :hover {
    &:hover {
      background: ${({ theme, disabled }) =>
        disabled
          ? theme.button.disabledBackgroundColor
          : theme.sidebar.folders.hover.backgroundGradient};
    }
    cursor: pointer;
  }
`;

const SelectListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export interface Option {
  value: string;
  label: string;
  subLabel?: string;
  dataTestId?: string;
  disabled?: boolean;
}

interface SelectProps {
  options: Option[];
  onChange: (option: Option) => void;
  prefix?: string;
  defaultOption?: { value: string; label: string };
  markSelectedOption?: boolean; //display a checkmark for the selected option
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const Select: React.FC<SelectProps> = ({
  options,
  onChange,
  prefix,
  defaultOption,
  markSelectedOption = false,
  ...args
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option>(() => {
    onChange(defaultOption ?? options[0]);
    return defaultOption ?? options[0];
  });
  const selectRef = useRef();

  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [defaultOption]);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (index: Option) => {
    onChange(index);
    setSelectedOption(index);
    setIsOptionsOpen(false);
  };

  useClickOutside(selectRef, () => setIsOptionsOpen(false));

  return (
    <div {...args}>
      <StyledButton
        type="button"
        aria-haspopup="listbox"
        aria-expanded={isOptionsOpen}
        onClick={toggleOptions}
      >
        {prefix ? `${prefix} ${selectedOption.label}` : selectedOption.label}
        <ButtonGroup>
          <ChevronContainer>
            <Chevron />
          </ChevronContainer>
        </ButtonGroup>
      </StyledButton>
      <StyledUL ref={selectRef} isOpen={isOptionsOpen} role="listbox" tabIndex={-1}>
        {options.map((option) => {
          const isSelected = selectedOption.value === option.value;
          return (
            <SelectListContainer key={option.value}>
              {isSelected && markSelectedOption && (
                <Icon
                  data-testid={`${option.dataTestId}-checkmark`}
                  name={ICONS.CheckmarkDefault}
                />
              )}
              <StyledLI
                disabled={option.disabled}
                data-testid={option.dataTestId}
                id={option.value}
                role="option"
                aria-selected={isSelected}
                tabIndex={0}
                onClick={() => {
                  if (!option.disabled) setSelectedThenCloseDropdown(option);
                }}
              >
                <span
                  style={{
                    paddingLeft: isSelected && markSelectedOption ? 5 : 20,
                  }}
                >
                  {option.label}
                </span>
                {option.subLabel && <SubLabel>{option.subLabel}</SubLabel>}
              </StyledLI>
            </SelectListContainer>
          );
        })}
      </StyledUL>
    </div>
  );
};
