import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

export enum userPreferencesPages {
  USER_PROFILE = 'user-profile',
  THEME = 'theme',
}

interface ContextProps {
  currentPage: userPreferencesPages;
  setCurrentPage: Dispatch<SetStateAction<userPreferencesPages>>;
}

const defaultState = {
  currentPage: userPreferencesPages.USER_PROFILE,
  setCurrentPage: () => null,
};

export const UserPreferencesContext = createContext<ContextProps>(defaultState);

const UserPreferencesContextProvider: React.FC = ({ children }) => {
  const [currentPage, setCurrentPage] = useState<userPreferencesPages>(defaultState.currentPage);

  const analyzeState = useMemo(
    () => ({
      currentPage,
      setCurrentPage,
    }),
    [currentPage],
  );

  return (
    <UserPreferencesContext.Provider value={analyzeState}>
      {children}
    </UserPreferencesContext.Provider>
  );
};

export default UserPreferencesContextProvider;
