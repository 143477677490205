import { ApiResult, CustomHeader } from '../api';
import { LimitConfig, States } from '../api/limit';

export class LimitConfigType {
  center_type: string;
  center_value: number | null;
  criticality: string;
  end_time: string;
  high_high_limit: number | null;
  high_limit: number | null;
  is_active: boolean;
  limit_id?: number | null;
  limit_type: string;
  low_limit: number | null;
  low_low_limit: number | null;
  start_time: string;
  states: States[];
  tag_name: string;

  constructor(init: LimitConfig) {
    this.center_type = init.center_type || '-';
    this.center_value = init.center_value;
    this.criticality = init.criticality || '';
    this.end_time = init.end_time || '';
    this.high_high_limit = init.high_high_limit;
    this.high_limit = init.high_limit;
    this.is_active = Boolean(init.is_active);
    this.limit_id = init.limit_id;
    this.limit_type = init.limit_type || '';
    this.low_limit = init.low_limit;
    this.low_low_limit = init.low_low_limit;
    this.start_time = init.start_time;
    this.states = init.states || [];
    this.tag_name = init.tag_name;
  }
}
export interface LimitConfigResponse {
  limits: LimitConfig[];
}

export class LimitConfigsResults {
  result: LimitConfig[] | undefined;
  request: Record<string, string | number>;
  headers: CustomHeader;

  constructor(init: ApiResult<LimitConfigResponse>) {
    this.result = init.result.limits.map((l) => new LimitConfigType(l));
    this.request = init.request as Record<string, string | number>;
    this.headers = init.headers;
  }
}
