import * as d3 from 'd3';

import { Area } from '@controlrooms/models';
import { SVGDefsSelection, TimeSeries } from '@controlrooms/models';

export const buildMinMaxArea = (
  chart: SVGDefsSelection,
  xScale: d3.ScaleTime<number, number, never>,
  yScale: d3.ScaleLinear<number, number, never>,
  seriesData: TimeSeries[] | undefined,
  // theme: DefaultTheme,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any, // TODO: Export styled components from shared package
  isDiscreteChart?: boolean,
) => {
  const areaFillColor = theme.chart.areaFillColor;
  const curveOfMinMaxArea = isDiscreteChart ? d3.curveStepAfter : d3.curveMonotoneX;
  // define area
  const areaGenerator = d3
    .area()
    .defined((d) => {
      return (d as unknown as TimeSeries).max != null && (d as unknown as TimeSeries).min != null;
    })
    .curve(curveOfMinMaxArea)
    .x((d) => {
      return xScale((d as unknown as TimeSeries).time);
    })
    .y0((d) => {
      return yScale((d as unknown as TimeSeries).max);
    })
    .y1((d) => {
      return yScale((d as unknown as TimeSeries).min);
    }) as Area;
  chart
    .append('path')
    .datum(seriesData)
    .attr('fill', areaFillColor)
    .attr('seriesData', 'area')
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .attr('d', areaGenerator as any);
};
