import classNames from 'classnames';
import React, { useState, useEffect, FormEvent } from 'react';
import { useUID } from 'react-uid';

import { Icon } from '../icon';

import { StyledInput, StyledInputWrapper, StyledLabel, StyledTextarea } from './styles';

import { ICONS } from '@controlrooms/constants';

export enum TextInputType {
  DATE = 'date',
  DATETIME_LOCAL = 'datetime-local',
  MONTH = 'month',
  MULTILINE = 'multiline',
  NUMBER = 'number',
  PASSWORD = 'password',
  TEL = 'tel',
  TEXT = 'text',
  TIME = 'time',
  WEEK = 'week',
}

interface Props extends React.HTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  className?: string;
  disabled?: boolean;
  errorId?: string;
  errorMessage?: string;
  infoMessage?: string;
  children?: React.ReactElement;
  id?: string;
  defaultValue?: string;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  type?: string;
  label?: string;
  name?: string;
  value?: string | number;
  onBlur?: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange?: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export type TextInputProps = Props;

const TEXT = 'text';
const PASSWORD = 'password';
const SHOW_PASSWORD = 'Show password';
const HIDE_PASSWORD = 'Hide password';

export const TextInput = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
  (
    {
      className,
      disabled,
      errorId,
      errorMessage,
      infoMessage,
      children,
      id,
      label,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      type,
      value,
      ...other
    }: Props,
    ref,
  ) => {
    const [showText, setShowText] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const inputType = showText ? TEXT : PASSWORD;
    const inputUuid = useUID();
    const inputId = id || `input-${inputUuid}`;
    const [visited, setVisited] = useState(false);

    useEffect(() => {
      if (isFocused) {
        setVisited(true);
      }
    }, [visited, isFocused]);

    const handleFocus = (e: FormEvent<HTMLInputElement>): void => {
      setIsFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    };

    const handleBlur = (e: FormEvent<HTMLInputElement>): void => {
      setIsFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    };

    const toggleText = (
      e: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>,
    ) => {
      e.preventDefault();
      setShowText(!showText);
    };

    const formControlClasses = classNames(className, {
      'form-control': true,
      disabled,
      error: errorMessage,
    });

    const inputClasses = classNames({
      'text-input': true,
      'form-control__input': true,
      disabled,
      'text-input--with-password-visibility-toggle': type === PASSWORD,
    });

    return (
      <StyledInputWrapper className={formControlClasses}>
        <StyledLabel className="label">
          {label && <label htmlFor={id || name || label}>{label}</label>}
        </StyledLabel>
        <div className={inputClasses}>
          {type === TextInputType.MULTILINE ? (
            <StyledTextarea
              id={inputId}
              className={className}
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
              onBlur={onBlur}
              onFocus={onFocus}
              aria-describedby={`${errorMessage ? errorId || `${inputId}-error` : ''}`}
              {...other}
              ref={ref as React.RefObject<HTMLTextAreaElement>}
            />
          ) : (
            <>
              <StyledInput
                id={inputId}
                name={name}
                type={type === PASSWORD ? inputType : type}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                onFocus={(e) => handleFocus(e)}
                onBlur={(e) => handleBlur(e)}
                disabled={disabled}
                aria-describedby={`${errorMessage ? errorId || `${inputId}-error` : ''}`}
                {...other}
                ref={ref as React.RefObject<HTMLInputElement>}
              />
              {type === PASSWORD && (
                <button
                  className={classNames({
                    'text-input__password-visibility-toggle': true,
                    'text-input__password-visibility-toggle--pressed': showText,
                  })}
                  tabIndex={-1}
                  aria-hidden="true"
                  title={showText ? HIDE_PASSWORD : SHOW_PASSWORD}
                  onClick={toggleText}
                  onKeyDown={(e) => (e.keyCode === 32 ? toggleText(e) : null)}
                  type="button"
                >
                  <Icon name={showText ? ICONS.ClosedEye : ICONS.Eye} height="14px" width="14px" />
                </button>
              )}
              {errorMessage && <Icon name="system-down" />}
              {children && <>{children}</>}
            </>
          )}
        </div>
        {errorMessage && (
          <span
            className="error-message"
            data-testid={errorId || `${inputId}-error`}
            id={errorId || `${inputId}-error`}
          >
            {errorMessage}
          </span>
        )}
        {infoMessage && (
          <span className="info-message" id={`${inputId}-info`}>
            {infoMessage}
          </span>
        )}
      </StyledInputWrapper>
    );
  },
);
