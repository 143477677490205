import * as d3 from 'd3';
import dayjs from 'dayjs';
import { DefaultTheme } from 'styled-components';

import { appendGradients, getGradientClass } from './gradients';

import {
  GRADIENTS,
  LABELING_DEAD_ZONE_MINUTES,
  LABELING_ZOOM_OUT_THRESHOLD,
} from '@controlrooms/constants';
import { colors } from '@controlrooms/design-tokens';
import {
  GroupTooltipData,
  LabeledEvent,
  MonitorAnomalyThresholds,
  SVGDefsSelection,
} from '@controlrooms/models';
import {
  Anomaly,
  LimitsExceeded,
  AnomalyMerged,
  AnomalyGroup,
  SeverityTestIDValue,
} from '@controlrooms/models';
import { delayedMouseOut } from '@controlrooms/utils';

const calculateY = (totalHeight: number, itemHeight: number) => {
  return totalHeight / 2 - itemHeight / 2;
};

export const buildCells = (
  container: SVGDefsSelection,
  chartData: Anomaly[],
  xScale: d3.ScaleTime<number, number, never>,
  thresholds: MonitorAnomalyThresholds,
  params: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    theme: any;
    height: number;
    interval: number;
    rowHeight: number;
    severityFilter?: number;
    labelTypesFilter?: number[];
    labeledEvents?: LabeledEvent[];
    limitData?: LimitsExceeded[] | undefined;
    showMonitorLimits?: boolean;
    showAnalyzeLimits?: boolean;
    folderId?: number;
  },
) => {
  const { height, interval, rowHeight } = params;

  const gradients = params?.theme?.heatmap?.gradients;

  const limitData = params.limitData || [];
  const limitExceededCopy: LimitsExceeded[] = [...limitData];
  const chartDataMerged: AnomalyMerged[] = [];

  const findItem = (time: number) => {
    return limitData.find((itmInner) => time === itmInner.time);
  };

  chartData?.forEach((data) => {
    const item = findItem(data.time);
    if (item) {
      const indexOfItem = limitExceededCopy.indexOf(item);
      if (indexOfItem > -1) {
        limitExceededCopy.splice(indexOfItem, 1);
      }
    }
    chartDataMerged.push({
      ...data,
      ...item,
    });
  });

  const chartDataWithLimitExceeded = [...chartDataMerged, ...limitExceededCopy];

  const colorCodes = (item: LimitsExceeded | AnomalyMerged) => {
    let gradientClass = null;
    if (!(item instanceof LimitsExceeded) && item?.value) {
      gradientClass = getGradientClass(item.value, thresholds);
    }
    return gradientClass;
  };

  const setStrokeColorBasedOnLimit = (d: LimitsExceeded | AnomalyMerged) => {
    if (params.showAnalyzeLimits === false) return 'none';
    if (d.low_low || d.high_high) return gradients[GRADIENTS.limits].color2;
    else if (d.low || d.high) return gradients[GRADIENTS.limits].color1;
    else return 'none';
  };

  const setStrokeWidthBasedOnLimit = (d: LimitsExceeded | AnomalyMerged) => {
    if (params.showMonitorLimits === false) return '0';
    if (d.low_low || d.high_high) return '2px';
    else if (d.low || d.high) return '1px';
    else return 'none';
  };

  const shouldShowCell = (d: LimitsExceeded | AnomalyMerged) => {
    const overlappingEvent = params.labeledEvents?.find((le: LabeledEvent) => {
      if (params.folderId !== le.process_id) return false;

      const st = dayjs(le.start_time).valueOf();
      const et = dayjs(le.end_time).valueOf();

      return st <= d.time && et >= d.time;
    });

    if (!overlappingEvent) return true;

    return overlappingEvent.label_type_ids?.some((id) => {
      return params.labelTypesFilter?.indexOf(id) !== -1;
    });
  };

  // add Gradients to svg defs
  appendGradients(container, params.theme);

  return container
    .selectAll('rect.cell')
    .data(chartDataWithLimitExceeded)
    .join('rect')
    .attr('data-testid', (d) => SeverityTestIDValue[colorCodes(d)] || 'anomalies')
    .attr('height', height)
    .attr('rx', 2)
    .attr('y', calculateY(rowHeight, height))
    .attr('x', (d) => {
      return xScale(d.time);
    })
    .attr('width', (d) => {
      return xScale(d.time + interval * 1000) - xScale(d.time);
    })
    .attr('stroke', (d: LimitsExceeded | AnomalyMerged) => setStrokeColorBasedOnLimit(d))
    .attr('stroke-width', (d: LimitsExceeded | AnomalyMerged) => setStrokeWidthBasedOnLimit(d))
    .style('fill', (d) => {
      let color = 'none';
      if (shouldShowCell(d)) {
        color = colorCodes(d) !== null ? `url(#${colorCodes(d)})` : 'transparent';
      }
      return color;
    })
    .style('outline', (d) => {
      return shouldShowCell(d) ? 'none' : `1px solid ${colors.k[20]}`;
    })
    .attr('class', (d) => {
      return `cell ${colorCodes(d)}`;
    })
    .classed('cell', true);
};

/**
 * Creates a group of anomalies that are contiguous in time.
 *
 * @param container The SVG container to append the group to
 * @param chartData Anomalies to group
 * @param xScale The xScale to use for positioning
 * @param params
 * @returns The SVG container with the group appended
 */
export const buildGroups = (
  container: SVGDefsSelection,
  chartData: Anomaly[],
  xScale: d3.ScaleTime<number, number, never>,
  folderId: number,
  params: {
    height: number;
    interval: number;
    rowHeight: number;
    groupTooltipData?: GroupTooltipData;
    setGroupTooltipData: React.Dispatch<React.SetStateAction<GroupTooltipData | undefined>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    theme: any;
  },
) => {
  // Don't allow labeling when zoomed out past 10 minutes
  // TODO: This should be a tenant setting
  if (params.interval >= LABELING_ZOOM_OUT_THRESHOLD) {
    return;
  }

  const { height, rowHeight, theme } = params;

  const borderContainer = container.append('g');

  const groupOutlineColor = theme.name === 'Dark' ? colors.focus.dark[2] : colors.focus.light[1];

  const contiguousGroups = [] as AnomalyGroup[];
  let currentGroup: AnomalyGroup | undefined;

  const groupEndsMinutesBeforeNow = (group: AnomalyGroup) => {
    return group.end < dayjs().subtract(LABELING_DEAD_ZONE_MINUTES, 'minute').valueOf();
  };

  chartData.forEach((d, i) => {
    if (!currentGroup) {
      currentGroup = { start: d.time, end: d.time + params.interval * 1000, values: [d] };
    } else if (currentGroup.end === d.time) {
      currentGroup.end = d.time + params.interval * 1000;
      currentGroup.values.push(d);
    } else {
      // Check if the currentGroup ends at least 10 minutes before now before adding
      if (groupEndsMinutesBeforeNow(currentGroup)) {
        contiguousGroups.push(currentGroup);
      }
      currentGroup = { start: d.time, end: d.time + params.interval * 1000, values: [d] };
    }

    // At the last element, check again for the last group
    if (i === chartData.length - 1 && currentGroup) {
      if (groupEndsMinutesBeforeNow(currentGroup)) {
        contiguousGroups.push(currentGroup);
      }
    }
  });

  let timeoutId: NodeJS.Timeout;
  let mouseOutTimeoutId: NodeJS.Timeout;

  borderContainer
    .selectAll('rect.group-border')
    .data(contiguousGroups)
    .join('rect')
    .attr('class', 'group-border')
    .attr('x', (d) => xScale(d.start - 2))
    .attr('y', calculateY(rowHeight, height + 1))
    .attr('width', (d) => xScale(d.end + 2) - xScale(d.start - 2) + 1)
    .attr('height', height + 1)
    .attr('id', (d) => `group-${d.start}-${d.end}-${folderId}`)
    .style('fill', 'none')
    .style('padding', '1px')
    .attr('pointer-events', 'all')
    .on('mouseover', (event, d) => {
      event.stopPropagation();
      event.preventDefault();
      d3.select(event.target)
        .style('outline', `2px solid ${groupOutlineColor}`)
        .style('border-radius', `1px`)
        .style('box-shadow', `0 0 10px ${groupOutlineColor}`);

      clearTimeout(mouseOutTimeoutId);
      clearTimeout(timeoutId);

      const targetElementId = `group-${d.start}-${d.end}-${folderId}`;

      // Show thumbs
      timeoutId = setTimeout(() => {
        params.setGroupTooltipData({
          targetElementId,
          currentTarget: event.target,
          start: d.start,
          end: d.end,
          folderId,
        });
      }, 1000);
    })
    .on('mouseout', (event) => {
      clearTimeout(timeoutId);
      d3.select(event.target).style('outline', 'none');

      // Clear thumbs tooltip if mouseout more than n seconds
      const mouseOutCallback = () => {
        params.setGroupTooltipData(undefined);
        clearTimeout(mouseOutTimeoutId);
      };

      const mouseOutElements: HTMLElement[] = [
        event.target as HTMLElement,
        params.groupTooltipData?.tooltipRef?.current as HTMLElement,
      ];

      delayedMouseOut({ elements: mouseOutElements, callback: mouseOutCallback });
    });

  return borderContainer;
};

export const buildLabeledEventGroups = (
  container: SVGDefsSelection,
  labeledEvents: LabeledEvent[],
  xScale: d3.ScaleTime<number, number, never>,
  folderId: number,
  params: {
    theme: DefaultTheme;
    height: number;
    rowHeight: number;
    setGroupTooltipData: React.Dispatch<React.SetStateAction<GroupTooltipData | undefined>>;
    setLabelTooltipData: React.Dispatch<React.SetStateAction<GroupTooltipData | undefined>>;
  },
) => {
  const { height, rowHeight } = params;

  if (labeledEvents.length === 0) return;

  const borderContainer = container.append('g');
  let tooltipTimeout: NodeJS.Timeout;

  borderContainer
    .selectAll('rect.group-border')
    .data(labeledEvents)
    .join('rect')
    .attr('class', 'group-border')
    .attr('x', (d) => xScale(new Date(d.ui_start_time)))
    .attr('y', calculateY(rowHeight, height - 7))
    .attr('width', (d) => xScale(new Date(d.ui_end_time)) - xScale(new Date(d.ui_start_time)))
    .attr('height', height - 8)
    .attr('id', (d) => `group-${d.start_time}-${d.end_time}-${folderId}`)
    .style('fill', 'none')
    .style('outline', `2px solid ${colors.focus.dark[2]}`)
    .style('border-radius', `1px`)
    .style('box-shadow', `0 0 10px ${colors.focus.dark[2]}`)
    .attr('pointer-events', 'all')
    .on('mouseover', (event, d) => {
      // Add a delay before showing informational tooltip with label type
      tooltipTimeout = setTimeout(() => {
        params.setLabelTooltipData({
          targetElementId: `group-${d.start_time}-${d.end_time}-${folderId}`,
          start: d.start_time,
          end: d.end_time,
          eventTypeIds: d.label_type_ids as number[],
        });
      }, 0);

      // For 'remove label' tooltip
      tooltipTimeout = setTimeout(() => {
        console.log(d, 'd');
        params.setGroupTooltipData({
          labeledEventId: d.label_event_id,
          targetElementId: `group-${d.start_time}-${d.end_time}-${folderId}`,
          start: d.start_time,
          end: d.end_time,
          eventTypeIds: d.label_type_ids as number[],
        });
      }, 1000);
    })
    .on('mouseout', () => {
      params.setLabelTooltipData(undefined);
      clearTimeout(tooltipTimeout);
    });

  return borderContainer;
};
