import { NumberFormatValues } from 'react-number-format';

import { States } from '@controlrooms/models';

export const identifyUnsortedFields = (fields) => {
  console.log('fields>>>', fields);
  // Filter out disabled fields and get their values
  const activeFields = fields
    .filter((field) => field.isEnabled)
    .map((field) => Number(field.value));
  const activeFieldNames = fields.filter((field) => field.isEnabled).map((field) => field.key);

  // If there's only one or no field active, no validation is needed
  if (activeFields.length <= 1) {
    return null;
  }

  // Initialize an array to hold the names of unsorted fields
  let unsortedFields = [];

  // Iterate through the active fields to find unsorted fields
  for (let i = 0; i < activeFields.length - 1; i++) {
    if (activeFields[i] < activeFields[i + 1]) {
      // If the current value is less than the next, it's not in the correct descending order
      unsortedFields.push(activeFieldNames[i]);
      unsortedFields.push(activeFieldNames[i + 1]);
    }
  }

  // Remove duplicates using Array.from to convert the Set to an array
  unsortedFields = Array.from(new Set(unsortedFields));

  return unsortedFields.length > 0 ? unsortedFields : [];
};

export const setErrorMessages = (fields, unsortedFields, setError) => {
  const fieldOrder = [
    'high_high_limit',
    'high_limit',
    'center_value',
    'low_limit',
    'low_low_limit',
  ];

  // Only consider enabled fields for setting error messages
  const enabledFields = fields.filter((field) => field.isEnabled).map((field) => field.key);

  console.log('enabledFields>>>', enabledFields);

  unsortedFields.forEach((field) => {
    if (enabledFields.includes(field)) {
      const index = fieldOrder.indexOf(field);
      const nextField = fieldOrder[index + 1] || null;

      if (nextField && unsortedFields.includes(nextField)) {
        const message = `${field} should be greater than ${nextField}`;
        setError(field, { type: 'manual', message });
      } else {
        const prevFieldIndex = index > 0 ? index - 1 : null;
        const prevField = prevFieldIndex !== null ? fieldOrder[prevFieldIndex] : null;

        if (prevField && unsortedFields.includes(prevField)) {
          const message = `${field} should be less than ${prevField}`;
          setError(field, { type: 'manual', message });
        }
      }
    }
  });
};

export const constructStates = (state1: States, state2: States) => {
  const filteredStates = [state1, state2]
    .filter((state) => !!state)
    .filter(
      (state) => state.tag_name !== '' && state.tag_category !== '' && state.tag_value !== '',
    );
  return filteredStates.length ? filteredStates : null;
};

export const isValidState = (state: States): boolean => {
  return state.tag_name !== '' && state.tag_category !== '' && state.tag_value !== '';
};

export const validateStates = (state1: States, state2: States): boolean => {
  if (isValidState(state1) && isValidState(state2)) {
    if (
      state1.tag_name === state2.tag_name &&
      state1.tag_category === state2.tag_category
      // commented to throw error if state 1 and state 2 is same without considering value
      //  &&
      // state1.tag_value === state2.tag_value
    ) {
      return false;
    }
  }
  return true;
};

const getSubstringAsInt = (value: string, length: number) =>
  Number(value.substring(value.length - length, value.length));

export const isTimeAllowed = ({ value }: NumberFormatValues): boolean => {
  switch (value.length) {
    case 0:
      return true;
    // Hour
    case 1:
      return getSubstringAsInt(value, 1) <= 2;
    case 2:
      return getSubstringAsInt(value, 2) <= 23;
    // Minute
    case 3:
    case 5:
      return getSubstringAsInt(value, 1) <= 5;
    // Second
    case 4:
    case 6:
      return getSubstringAsInt(value, 2) <= 59;
    default:
      return false;
  }
};
