import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';

import { useAlertsAPI } from '../../hooks/alerts';

import { AlertSearchWrapper, HeaderContainer, SearchContainer, StyledLi } from './styles';

import { Checkbox, Icon, OptionsGroup, StyledInput, Ul } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { useClickOutside } from '@controlrooms/hooks';
import { AlertSearchCategory, AlertSearchResult, SearchSuggestion } from '@controlrooms/models';

interface AlertSearchProps {
  children?: React.ReactNode;
  handleSearchClick: (result: SearchSuggestion) => void;
}

const alertSearchCategory: AlertSearchCategory[] = [
  {
    label: 'Alert Name',
    value: 'alert_name',
    checked: true,
  },
  {
    label: 'Recipients',
    value: 'recipient',
    checked: true,
  },
  {
    label: 'Created by',
    value: 'created_by',
    checked: true,
  },
];

export const AlertSearch: React.FC<AlertSearchProps> = ({ handleSearchClick }) => {
  const [searchText, setSearchText] = useState('');
  const [isSearchCategoryOpen, setIsSearchCategoryOpen] = useState(false);
  const [isSearchResultOpen, setIsSearchResultOpen] = useState(false);
  const [searchCategory, setSearchCategory] = useState(alertSearchCategory);
  const [searchSuggestion, setSearchSuggestion] = useState({} as AlertSearchResult);

  const searchCategoryRef = useRef(null);
  const searchResultRef = useRef(null);
  const { getAlertSearchSuggestionMutation } = useAlertsAPI();
  const { mutateAsync: getAlertSearchSuggestion } = getAlertSearchSuggestionMutation;

  const toggleSearchCategory = () => setIsSearchCategoryOpen((prevState) => !prevState);
  useClickOutside(searchCategoryRef, () => setIsSearchCategoryOpen(false));
  useClickOutside(searchResultRef, () => setIsSearchResultOpen(false));

  useEffect(() => {
    if (!searchText) {
      setSearchSuggestion({});
    } else {
      const delayDebounceFn = setTimeout(() => {
        (async () => {
          try {
            const { result } = await getAlertSearchSuggestion({ searchText });
            setSearchSuggestion(result);
          } catch (error) {
            console.log(error);
          }
        })();
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [getAlertSearchSuggestion, searchText, searchCategory]);

  return (
    <AlertSearchWrapper>
      <SearchContainer>
        <OptionsGroup className="option-container" ref={searchCategoryRef}>
          <span className="search-icon" style={{ display: 'flex' }} onClick={toggleSearchCategory}>
            <Icon data-testid="search-icon-alerts-page" name={ICONS.Search} />
            <Icon name={ICONS.Chevron} />
          </span>
          <Ul isOpen={isSearchCategoryOpen} style={{ left: '-9px' }}>
            <div>
              <HeaderContainer>LIMIT SEARCH TO...</HeaderContainer>
              {searchCategory.map((category) => (
                <StyledLi key={category.value}>
                  <Checkbox
                    onChange={() => {
                      category.checked = !category.checked;
                      setSearchCategory([...searchCategory]);
                    }}
                    checked={category.checked || false}
                    dataTestId={`${category.value} Search Filter Checkbox`}
                  />
                  <span data-testid={category.label}>{category.label}</span>
                </StyledLi>
              ))}
            </div>
          </Ul>
        </OptionsGroup>
        <OptionsGroup ref={searchResultRef} className="option-container">
          <StyledInput
            id={'alert-search'}
            className="search-alert"
            placeholder="Search Tags, Systems & Views"
            onFocus={() => {
              setIsSearchResultOpen(true);
            }}
            data-testid="search-bar-alerts-page"
            // onKeyDown={(event) => {
            //   if (event.key === 'Enter') {
            //     handleSearch();
            //   }
            // }}
            onChange={({ currentTarget }) => setSearchText(currentTarget?.value)}
          />
          <Ul isOpen={isSearchResultOpen} style={{ left: '-32px' }}>
            <div>
              {searchCategory.map((category) => (
                <React.Fragment key={category.value}>
                  {category.checked && searchSuggestion[category.value]?.length > 0 && (
                    <span>
                      <HeaderContainer>{category.label}</HeaderContainer>
                      {searchSuggestion[category.value]?.map(
                        ({ search_result, type, ...rest }: SearchSuggestion, key) => (
                          <StyledLi
                            data-testid={search_result}
                            key={key}
                            onClick={() => {
                              handleSearchClick({
                                search_result,
                                search_value: rest.target_id || rest.user_id || search_result || '',
                                type: category.value === 'alert_name' ? type : category.value,
                                category: category.value,
                              });
                              setIsSearchResultOpen(false);
                            }}
                          >
                            <Highlighter
                              searchWords={[searchText]}
                              textToHighlight={search_result}
                              autoEscape={true}
                            ></Highlighter>
                          </StyledLi>
                        ),
                      )}
                    </span>
                  )}
                </React.Fragment>
              ))}
            </div>
          </Ul>
        </OptionsGroup>
      </SearchContainer>
    </AlertSearchWrapper>
  );
};

export default React.memo(AlertSearch);
