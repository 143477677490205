import { Paths } from './paths';

import { TimePresetKeyType, TimePresetKeys } from '@controlrooms/models';

export interface SelectOption {
  value: string;
  label: string;
  paths: Array<typeof Paths[keyof typeof Paths]>;
}

export interface GroupOption {
  label: string;
  options: SelectOption[];
}

export interface ShiftReportOption {
  value: string;
  label: string;
  timeLabel: string;
  paths: Array<typeof Paths[keyof typeof Paths]>;
}

export interface ShiftReportGroupOption {
  label: string;
  options: ShiftReportOption[];
}

export const TimePresetMap: Map<TimePresetKeyType, number> = new Map<TimePresetKeyType, number>([
  [TimePresetKeys.LAST_HOUR, 60 * 60],
  [TimePresetKeys.LAST_FOUR_HOURS, 60 * 60 * 4],
  [TimePresetKeys.LAST_TWELVE_HOURS, 60 * 60 * 12],
  [TimePresetKeys.LAST_DAY, 60 * 60 * 24],
  [TimePresetKeys.LAST_WEEK, 60 * 60 * 24 * 7],
  [TimePresetKeys.LAST_THIRTY_DAYS, 60 * 60 * 24 * 30],
  [TimePresetKeys.LAST_MONTH, 60 * 60 * 24 * 30],
  [TimePresetKeys.LAST_QUARTER, 60 * 60 * 24 * 91],
  [TimePresetKeys.SIX_MONTHS, 60 * 60 * 24 * 182],
  [TimePresetKeys.LAST_YEAR, 60 * 60 * 24 * 365],
  [TimePresetKeys.ALL_TIME, 60 * 60 * 24 * 365],
]);

export const getTimePresetMapKey = (searchValue?: number): TimePresetKeyType | undefined => {
  for (const [key, value] of TimePresetMap) {
    if (value === searchValue) {
      return key;
    }
  }
  return undefined;
};

export enum Preset {
  LAST_HOUR = 'Last Hour',
  LAST_FOUR_HOURS = 'Last 4 Hours',
  LAST_TWELVE_HOURS = 'Last 12 Hours',
  LAST_DAY = 'Last Day',
  LAST_WEEK = 'Last Week',
  LAST_THIRTY_DAYS = 'Last 30 Days',
}

export const PresetOptions: SelectOption[] = [
  {
    value: TimePresetKeys.LAST_HOUR,
    label: Preset.LAST_HOUR,
    paths: [
      Paths.ANALYZE,
      Paths.MONITOR,
      Paths.DEMO_MONITOR,
      Paths.DEMO_ANALYZE,
      Paths.INVESTIGATE,
    ],
  },
  {
    value: TimePresetKeys.LAST_FOUR_HOURS,
    label: Preset.LAST_FOUR_HOURS,
    paths: [
      Paths.ANALYZE,
      Paths.MONITOR,
      Paths.DEMO_MONITOR,
      Paths.DEMO_ANALYZE,
      Paths.INVESTIGATE,
    ],
  },
  {
    value: TimePresetKeys.LAST_TWELVE_HOURS,
    label: Preset.LAST_TWELVE_HOURS,
    paths: [
      Paths.ANALYZE,
      Paths.MONITOR,
      Paths.DEMO_MONITOR,
      Paths.DEMO_ANALYZE,
      Paths.INVESTIGATE,
    ],
  },
  {
    value: TimePresetKeys.LAST_DAY,
    label: Preset.LAST_DAY,
    paths: [
      Paths.ANALYZE,
      Paths.MONITOR,
      Paths.DEMO_MONITOR,
      Paths.DEMO_ANALYZE,
      Paths.INVESTIGATE,
    ],
  },
  {
    value: TimePresetKeys.LAST_WEEK,
    label: Preset.LAST_WEEK,
    paths: [
      Paths.ANALYZE,
      Paths.MONITOR,
      Paths.DEMO_MONITOR,
      Paths.DEMO_ANALYZE,
      Paths.INVESTIGATE,
    ],
  },
  {
    value: TimePresetKeys.LAST_THIRTY_DAYS,
    label: Preset.LAST_THIRTY_DAYS,
    paths: [
      Paths.ANALYZE,
      Paths.MONITOR,
      Paths.DEMO_MONITOR,
      Paths.DEMO_ANALYZE,
      Paths.INVESTIGATE,
    ],
  },
];

export const ShiftReportPresetOptions: ShiftReportOption[] = [
  {
    value: TimePresetKeys.MORNING_REPORT,
    label: 'Morning Report',
    timeLabel: '18:00 - 06:00',
    paths: [Paths.ANALYZE, Paths.MONITOR, Paths.DEMO_MONITOR, Paths.DEMO_ANALYZE],
  },
  {
    value: TimePresetKeys.EVENING_REPORT,
    label: 'Evening Report',
    timeLabel: '06:00 - 18:00',
    paths: [Paths.ANALYZE, Paths.MONITOR, Paths.DEMO_MONITOR, Paths.DEMO_ANALYZE],
  },
];

export const GroupOptions: GroupOption[] = [
  {
    label: 'Presets',
    options: PresetOptions,
  },
];

export const ShiftReportGroupOptions: ShiftReportGroupOption[] = [
  {
    label: 'Preset Timeframe',
    options: ShiftReportPresetOptions,
  },
];
