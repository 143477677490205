import dayjs from 'dayjs';

import {
  AnomalyRange,
  CRAPITagData,
  CRTagAnomaly,
  CRTagMinMaxTimeSeries,
  CRTagMode,
  TimeValue,
} from '@controlrooms/models';

const interpolate = (
  matchingPoints: TimeValue[],
  start: number,
  end: number,
  timeseries: TimeValue[],
) => {
  const _startIdx = timeseries.indexOf(matchingPoints[0]);
  const _endIdx = _startIdx + (matchingPoints.length - 1);

  let _matchingPoints = [...matchingPoints];

  // Interpolate using first point outside range left for continuity
  if (timeseries[_startIdx - 1]) {
    const _begin = timeseries[_startIdx - 1].time;
    const _val = start;
    const _end = timeseries[_startIdx].time;
    if (_val !== _begin) {
      const ratio = (_end - _val) / (_end - _begin);
      const pointDiff = timeseries[_startIdx - 1].value - timeseries[_startIdx].value;
      _matchingPoints = [
        {
          time: start,
          timestamp: 'n/a',
          value: timeseries[_startIdx].value + ratio * pointDiff,
        },
        ..._matchingPoints,
      ];
    }
  }

  // Interpolate using first point outside range right for continuity
  if (timeseries[_endIdx + 1]) {
    const _begin = timeseries[_endIdx].time;
    const _val = end;
    const _end = timeseries[_endIdx + 1].time;
    if (_val !== _begin) {
      const ratio = (_begin - _val) / (_end - _begin);
      const pointDiff = timeseries[_endIdx].value - timeseries[_endIdx + 1].value;
      _matchingPoints = [
        ..._matchingPoints,
        {
          time: end,
          timestamp: 'n/a',
          value: timeseries[_endIdx].value + ratio * pointDiff,
        },
      ];
    }
  }
  return _matchingPoints;
};

export const mapAnomalies = (
  d: CRAPITagData<CRTagMinMaxTimeSeries, CRTagMode, CRTagAnomaly>,
  interval: number,
  timeseries: TimeValue[],
): AnomalyRange[] =>
  Object.values(
    (d.anomalies ?? [])
      .filter((a) => a.value !== 0)
      .map((a) => {
        const { value: severity, time } = a;
        const start = dayjs(time).valueOf();
        const end = dayjs(time)
          // Minimum anomaly length is 60
          .add(Math.max(interval, 60), 'seconds')
          .valueOf();

        let matchingPoints = timeseries.filter(
          (d: TimeValue) => d.time >= start && d.time <= end,
        ) as TimeValue[];

        if (matchingPoints.length > 0) {
          matchingPoints = interpolate(matchingPoints, start, end, timeseries);
        }

        return {
          severity,
          start,
          end,
          yScale: matchingPoints,
        };
      }),
  );
